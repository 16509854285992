"use strict";

import React from "react";
import PropTypes from "prop-types";
import Choice from "./Choice.react";
import FluxLoader from "../FluxLoader";

/**
 * Entity component
 */
export default class Entity extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        className: PropTypes.string.isRequired,
        storeHash: PropTypes.string,
        filter: PropTypes.func,
    };

    /**
     * React: state
     */
    state = {
        choices: null,
        recordClass: null,
        actions: null,
        store: null,
    };

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        var className = this.props.className;
        if (this.props.className) {
            this.state.recordClass = FluxLoader.getRecordClass(className);
            this.state.actions = FluxLoader.getActions(className);
            this.state.store = FluxLoader.getStore(className);
        }

        setTimeout(this._getChoices, 1);
    }

    componentWillUnmount() {
        var EntityStore = this.state.store;

        EntityStore.removeListener(EntityStore.ENTITY_COLLECTION_UPDATED, this._setChoices);
    }

    /**
     * React: render
     */
    render() {
        return (
            <div className="ui-entity">
                <Choice
                    valueReference={"id"}
                    {...this.props}
                    disabled={this.state.choices === null || this.props.disabled}
                    choices={this.state.choices ? this.state.choices : []}
                    updatePending={this.state.choices === null || this.props.updatePending}
                />
            </div>
        );
    }

    /**
     * Get choices
     *
     * @private
     */
    _getChoices = () => {
        var EntityStore = this.state.store;
        var EntityActions = this.state.actions;
        var className = this.props.className;
        var getCollectionActionName = "get" + className + "Collection";

        if (typeof EntityActions[getCollectionActionName] !== "undefined") {
            EntityStore.addListener(EntityStore.ENTITY_COLLECTION_UPDATED, this._setChoices);
            EntityActions[getCollectionActionName]();
        }
    };

    /**
     * Set choices
     * @private
     */
    _setChoices = () => {
        var className = this.props.className;
        var storeHashName = className.charAt(0).toLowerCase() + className.slice(1) + "Collection";
        var EntityStore = this.state.store;

        var choices = [];
        if (typeof EntityStore[storeHashName] !== "undefined") {
            EntityStore[storeHashName].forEach((record) => {
                choices.push(record);
            });
        }

        this.setState({
            choices: choices,
        });
    };
}
