"use strict";

import BaseForm from "../ui/BaseForm";

export default class LoginForm extends BaseForm {
    /**
     * @inheritdoc
     */
    buildForm(builder) {
        builder.add("username", "text", {
            label: "Login",
            autoFocus: true,
        });
        builder.add("password", "password", {
            label: "Passwort",
        });
        //builder.add('stayLoggedIn', 'checkbox', {
        //    label: 'Eingelogt bleiben'
        //});
    }
}
