"use strict";

import * as Request from "./Request";
import ApiEndpoints from "../constants/ApiEndpoints";

export default {
    /**
     * Get schedule for period
     * @param {Object} params
     * @param {string} params.startDate
     * @param {string} params.endDate
     * @return {*}
     */
    getSchedule: (params) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.GET_SCHEDULE(params.startDate, params.endDate),
            method: "GET",
        });
    },

    /**
     * Create schedule for period
     *
     * @param {Object} params
     * @param {string} params.startDate
     * @param {string} params.endDate
     * @param {boolean} params.useDriverGroup
     * @param {boolean} params.useRotation
     * @param {boolean} params.useReservations
     * @return {*}
     */
    createSchedule: (params) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.CREATE_SCHEDULE,
            method: "POST",
            body: {
                schedule: {
                    startDate: params.startDate,
                    endDate: params.endDate,
                    useDriverGroup: typeof params.useDriverGroup !== "undefined" ? params.useDriverGroup : false,
                    useRotation: typeof params.useRotation !== "undefined" ? params.useRotation : false,
                    useReservations: typeof params.useReservations !== "undefined" ? params.useReservations : false,
                },
            },
        });
    },

    /**
     * Remove schedule for period
     * @param {Object} params
     * @param {string} params.startDate
     * @param {string} params.endDate
     * @return {*}
     */
    removeSchedule: (params) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.REMOVE_SCHEDULE(params.startDate, params.endDate),
            method: "DELETE",
        });
    },

    /**
     * Create tourDate
     *
     * @param {Object} params
     * @param {number} params.tourId
     * @param {string} params.calendarDate
     * @param {number} params.driverId
     * @param {number} params.coDriverId
     * @param {number} params.packerId
     * @param {string} params.status
     * @return {*}
     */
    createTourDate: (params) => {
        var tourDate = {
            tour: params.tourId,
            calendarDate: params.calendarDate,
            driver: typeof params.driverId !== "undefined" ? params.driverId : null,
            coDriver: typeof params.coDriverId !== "undefined" ? params.coDriverId : null,
            packer: typeof params.packerId !== "undefined" ? params.packerId : null,
        };

        if (typeof params.status !== "undefined") {
            tourDate = {
                ...tourDate,
                status: params.status,
            };
        }

        return Request.getAuthRequestPromise({
            url: ApiEndpoints.CREATE_TOUR_DATE,
            method: "POST",
            body: {
                tourDate: tourDate,
            },
        });
    },

    /**
     * Get tourDate
     * @param {Object} params
     * @param {number} params.tourDateId
     * @return {*}
     */
    getTourDate: (params = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.GET_TOUR_DATE(params.tourDateId),
            method: "GET",
        });
    },

    /**
     * Update tourDate
     *
     * @param {Object} params
     * @param {number} params.tourDateId
     * @param {number} params.driverId
     * @param {number} params.coDriverId
     * @param {number} params.packerId
     * @param {string} params.status
     * @return {*}
     */
    updateTourDate: (params) => {
        var tourDate = {
            driver: typeof params.driverId !== "undefined" ? params.driverId : null,
            coDriver: typeof params.coDriverId !== "undefined" ? params.coDriverId : null,
            packer: typeof params.packerId !== "undefined" ? params.packerId : null,
        };

        if (typeof params.status !== "undefined") {
            tourDate = {
                ...tourDate,
                status: params.status,
            };
        }

        return Request.getAuthRequestPromise({
            url: ApiEndpoints.UPDATE_TOUR_DATE(params.tourDateId),
            method: "PUT",
            body: {
                tourDate: tourDate,
            },
        });
    },

    /**
     * Update tourDate driver
     *
     * @param {Object} params
     * @param {number} params.tourDateId
     * @param {string} params.driverAbsenceType
     * @param {string} params.coDriverAbsenceType
     * @return {*}
     */
    updateTourDateDriver: (params) => {
        var tourDateDriver = {};

        if (typeof params.driverAbsenceType !== "undefined") {
            tourDateDriver = {
                driverAbsenceType: typeof params.driverAbsenceType !== "undefined" ? params.driverAbsenceType : null,
            };
        } else if (typeof params.coDriverAbsenceType !== "undefined") {
            tourDateDriver = {
                coDriverAbsenceType:
                    typeof params.coDriverAbsenceType !== "undefined" ? params.coDriverAbsenceType : null,
            };
        }

        return Request.getAuthRequestPromise({
            url: ApiEndpoints.UPDATE_TOUR_DATE_DRIVER(params.tourDateId),
            method: "PUT",
            body: {
                tourDateDriver: tourDateDriver,
            },
        });
    },
};
