"use strict";

import moment from "moment";
import "moment/locale/de";
import ActionTypes from "../constants/ActionTypes";
import BaseStore from "./BaseStore";

/**
 * Flux: AppStore
 */
class AppStore extends BaseStore {
    /**
     * Constructor
     */
    constructor() {
        super();
        moment.locale("de");

        this.subscribe(() => this._registerToActions.bind(this));

        this._scheduleDates = {
            startDate: moment().weekday(0).startOf("day"),
            endDate: moment().weekday(6).endOf("day"),
        };
        this._error = null;
    }

    /**
     * Flux: register store to actions
     *
     * @param action
     * @private
     */
    _registerToActions(action) {
        this._error = null;

        switch (action.type) {
            case ActionTypes.STORE_SCHEDULE_DATE_SELECTION:
                this._scheduleDates = action.dates;
                break;

            default:
                break;
        }
    }

    /**
     * Get scheduleDates
     *
     * @return {Object}
     */
    get scheduleDates() {
        return this._scheduleDates;
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new AppStore();
