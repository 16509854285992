"use strict";

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { extendMoment } from "moment-range";

/**
 * ScheduleTable component
 */
export default class ScheduleTable extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        startDate: PropTypes.object.isRequired,
        endDate: PropTypes.object.isRequired,
        heading: PropTypes.string,
        additionalHeading1: PropTypes.string,
        additionalHeading2: PropTypes.string,
        displayDate: PropTypes.bool,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        displayDate: true,
    };

    /**
     * React: render
     */
    render() {
        let week = extendMoment(moment).range(this.props.startDate, this.props.endDate);
        let weekDays = [];
        for (const weekDay of week.by("days")) {
            weekDays.push(
                <th key={weekDay.format("dd")} className="day">
                    <strong>{weekDay.format("dd")}</strong>
                    {!this.props.displayDate ? null : <span>{weekDay.format("D.M")}</span>}
                </th>
            );
        }

        return (
            <table className="schedule-table">
                <thead>
                    <tr>
                        <th className="title">{this.props.heading}</th>
                        <th className="additional">{this.props.additionalHeading1}</th>
                        <th className="additional">{this.props.additionalHeading2}</th>
                        {weekDays}
                    </tr>
                </thead>
                <tbody>{this.props.children}</tbody>
            </table>
        );
    }
}
