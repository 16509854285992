"use strict";

import * as AppDispatcher from "../dispatchers/AppDispatcher";
import ActionTypes from "../constants/ActionTypes";
import ApiService from "../server/ApiService";

export default {
    getSchedule: (params = {}) => {
        let promise = ApiService.getSchedule(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_GET_SCHEDULE,
                success: ActionTypes.REQUEST_GET_SCHEDULE_SUCCESS,
                failure: ActionTypes.REQUEST_GET_SCHEDULE_ERROR,
            },
            params
        );
    },

    createSchedule: (params = {}) => {
        let promise = ApiService.createSchedule(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_CREATE_SCHEDULE,
                success: ActionTypes.REQUEST_CREATE_SCHEDULE_SUCCESS,
                failure: ActionTypes.REQUEST_CREATE_SCHEDULE_ERROR,
            },
            params
        );
    },

    removeSchedule: (params = {}) => {
        let promise = ApiService.removeSchedule(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_REMOVE_SCHEDULE,
                success: ActionTypes.REQUEST_REMOVE_SCHEDULE_SUCCESS,
                failure: ActionTypes.REQUEST_REMOVE_SCHEDULE_ERROR,
            },
            params
        );
    },

    createTourDate: (params = {}) => {
        let promise = ApiService.createTourDate(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_CREATE_TOUR_DATE,
                success: ActionTypes.REQUEST_CREATE_TOUR_DATE_SUCCESS,
                failure: ActionTypes.REQUEST_CREATE_TOUR_DATE_ERROR,
            },
            params
        );
    },

    getTourDate: (params = {}) => {
        let promise = ApiService.getTourDate(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_GET_TOUR_DATE,
                success: ActionTypes.REQUEST_GET_TOUR_DATE_SUCCESS,
                failure: ActionTypes.REQUEST_GET_TOUR_DATE_ERROR,
            },
            params
        );
    },

    updateTourDate: (params = {}) => {
        let promise = ApiService.updateTourDate(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_UPDATE_TOUR_DATE,
                success: ActionTypes.REQUEST_UPDATE_TOUR_DATE_SUCCESS,
                failure: ActionTypes.REQUEST_UPDATE_TOUR_DATE_ERROR,
            },
            params
        );
    },

    updateTourDateDriver: (params = {}) => {
        let promise = ApiService.updateTourDateDriver(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_UPDATE_TOUR_DATE_DRIVER,
                success: ActionTypes.REQUEST_UPDATE_TOUR_DATE_DRIVER_SUCCESS,
                failure: ActionTypes.REQUEST_UPDATE_TOUR_DATE_DRIVER_ERROR,
            },
            params
        );
    },
};
