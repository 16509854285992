"use strict";

import moment from "moment";
import { extendMoment } from "moment-range";
import React from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox.react";

/**
 * WeekDayChoice component
 */
export default class WeekDayChoice extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        value: PropTypes.objectOf(PropTypes.bool),
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        width: 6,
    };

    /**
     * React: state
     */
    state = {
        value: this.props.value,
    };

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
        });
    }

    /**
     * React: render
     */
    render() {
        var weekDays = [];
        var weekDayChoices = [];
        var week = extendMoment(moment).range(moment().weekday(0), moment().weekday(6));
        for (const weekDay of week.by("day")) {
            let key = weekDay.isoWeekday();
            weekDayChoices.push(
                <td key={key}>
                    <Checkbox
                        value={this.props.value[key]}
                        disabled={this.props.disabled}
                        onChange={this._onChange.bind(this, key)}
                    />
                </td>
            );

            // day names
            weekDays.push(<th key={key}>{weekDay.format("dd")}</th>);
        }

        var className = "ui-week-day-choice" + (this.props.width ? " ui-col-" + this.props.width : "");
        return (
            <div className={className}>
                <table>
                    <thead>
                        <tr>{weekDays}</tr>
                    </thead>
                    <tbody>
                        <tr>{weekDayChoices}</tr>
                    </tbody>
                </table>
            </div>
        );
    }

    /**
     * On change
     */
    _onChange = (changedKey, isSelected, event) => {
        var newValue = Object.assign({}, this.state.value);
        newValue[changedKey] = isSelected;

        this.setState(
            {
                value: newValue,
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value, event);
                }
            }
        );
    };
}
