"use strict";

import React from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import Text from "./Text.react";
import Button from "./Button.react";

/**
 * TagInput component
 */
export default class TagInput extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        value: PropTypes.array,
        valueReference: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        dataClass: PropTypes.func,
        type: PropTypes.oneOf(["text", "number"]),
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool,
        updatePending: PropTypes.bool,
        onChange: PropTypes.func,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        type: "text",
        value: [],
        disabled: false,
        updatePending: false,
    };

    /**
     * React: state
     */
    state = {
        value: [],
        entryValues: {},
        newTag: null,
    };

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this.state.value = this.props.value;
        this.state.entryValues = this._parseEntryValues(this.props.value);
    }

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
            entryValues: this._parseEntryValues(nextProps.value),
        });
    }

    /**
     * React: render
     */
    render() {
        var tags = [];
        Object.keys(this.state.entryValues).forEach((key) => {
            let entryValue = this.state.entryValues[key];
            tags.push(
                <div className="tag" key={key}>
                    {entryValue}
                    <i className="ui-icon icon-cancel" onClick={this._removeEntry.bind(null, key)} />
                </div>
            );
        });

        let className =
            "ui-tag-input " + (typeof this.props.width !== "undefined" ? " ui-col-" + this.props.width : "");
        return (
            <div className={className}>
                <Text
                    type={this.props.type}
                    value={this.state.newTag}
                    onChange={this._onTagInput}
                    disabled={this.props.disabled}
                    width="2"
                />
                <Button onClick={this._addEntry} disabled={this.props.disabled}>
                    Hinzufügen
                </Button>
                <div className="tags">{tags}</div>
            </div>
        );
    }

    /**
     * Parse entry values from element value
     * @param value
     * @private
     */
    _parseEntryValues = (value) => {
        var entryValues = {};
        var valueReference = this.props.valueReference;

        value.forEach((entryData) => {
            let key = uuid();
            let entryValue = null;

            if (typeof valueReference === "function") {
                entryValue = valueReference(entryData);
            } else if (typeof valueReference === "string") {
                entryValue = entryData[valueReference];
            } else {
                entryValue = entryData;
            }

            entryValues[key] = entryValue;
        });

        return entryValues;
    };

    /**
     * On tag input
     * @param tag
     * @private
     */
    _onTagInput = (tag) => {
        this.setState({
            newTag: tag || null,
        });
    };

    /**
     * Add entry
     * @private
     */
    _addEntry = () => {
        // return if tag value is empty
        if (!this.state.newTag) return;

        var entryValues = Object.assign({}, this.state.entryValues);
        var key = uuid();
        entryValues[key] = this.state.newTag;

        this.setState(
            {
                value: this._mapValue(entryValues),
                entryValues: entryValues,
                newTag: null,
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            }
        );
    };

    /**
     * Remove entry
     * @param {string} key
     * @private
     */
    _removeEntry = (key) => {
        var entryValues = Object.assign({}, this.state.entryValues);
        delete entryValues[key];

        this.setState(
            {
                value: this._mapValue(entryValues),
                entryValues: entryValues,
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            }
        );
    };

    /**
     * Map value array from entryValues
     * @param {Object} entryValues
     * @private
     */
    _mapValue = (entryValues) => {
        var collectionValue = Object.assign({}, entryValues);
        return Object.keys(collectionValue).map((valueKey) => {
            if (typeof this.props.dataClass !== "undefined" && typeof this.props.valueReference === "string") {
                let value = {};
                value[this.props.valueReference] = collectionValue[valueKey];
                return new this.props.dataClass(value);
            } else {
                return collectionValue[valueKey];
            }
        });
    };
}
