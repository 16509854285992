"use strict";

import Immutable from "immutable";
import DriverGroup from "../types/DriverGroup";
import ActionTypes from "../constants/ActionTypes";
import EntityStore from "./EntityStore";

/**
 * Flux: DriverGroupStore
 */
class DriverGroupStore extends EntityStore {
    /**
     * driverGroupCollection hash
     *
     * @type {Immutable.OrderedMap<string, DriverGroup>}
     * @private
     */
    _driverGroupCollection = Immutable.OrderedMap();

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));

        this._error = null;
    }

    /**
     * Flux: register store to actions
     *
     * @param {*} action
     * @private
     */
    _registerToActions(action) {
        switch (action.type) {
            // get driverGroupCollection
            case ActionTypes.REQUEST_GET_DRIVER_GROUP_COLLECTION:
                this._error = null;
                break;

            case ActionTypes.REQUEST_GET_DRIVER_GROUP_COLLECTION_SUCCESS:
                this._setDriverGroupCollection(action.body.driverGroups);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_GET_DRIVER_GROUP_COLLECTION_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            // create driverGroup
            case ActionTypes.REQUEST_CREATE_DRIVER_GROUP:
                this._error = null;
                break;

            case ActionTypes.REQUEST_CREATE_DRIVER_GROUP_SUCCESS:
                this._setDriverGroup(action.body.driverGroup);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_CREATE_DRIVER_GROUP_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            // get driverGroup
            case ActionTypes.REQUEST_GET_DRIVER_GROUP:
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.driverGroupId));
                break;

            case ActionTypes.REQUEST_GET_DRIVER_GROUP_SUCCESS:
                this._setDriverGroup(action.body.driverGroup);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.body.driverGroup.id));
                break;

            case ActionTypes.REQUEST_GET_DRIVER_GROUP_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.driverGroupId));
                break;

            // update driverGroup
            case ActionTypes.REQUEST_UPDATE_DRIVER_GROUP:
                this._toggleUpdatePending(action.driverGroup.id);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.driverGroup.id));
                break;

            case ActionTypes.REQUEST_UPDATE_DRIVER_GROUP_SUCCESS:
                this._setDriverGroup(action.body.driverGroup);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.body.driverGroup.id));
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_UPDATE_DRIVER_GROUP_ERROR:
                this._toggleUpdatePending(action.driverGroup.id);
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.driverGroup.id));
                break;

            // remove driverGroup
            case ActionTypes.REQUEST_REMOVE_DRIVER_GROUP:
                this._toggleUpdatePending(action.driverGroupId);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.driverGroupId));
                break;

            case ActionTypes.REQUEST_REMOVE_DRIVER_GROUP_SUCCESS:
                this._toggleUpdatePending(action.driverGroupId);
                this._removeDriverGroup(action.driverGroupId);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_REMOVE_DRIVER_GROUP_ERROR:
                this._toggleUpdatePending(action.driverGroupId);
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.driverGroupId));
                break;

            default:
                break;
        }
    }

    /**
     * Set _driverGroupCollection
     *
     * @param {Array.<DriverGroup>} responseDriverGroups
     * @private
     */
    _setDriverGroupCollection(responseDriverGroups) {
        responseDriverGroups.map((responseDriverGroup) => {
            this._setDriverGroup(responseDriverGroup);
        });
    }

    /**
     * Set _driverGroupCollection[key]
     *
     * @params {DriverGroup} responseDriverGroup
     * @private
     */
    _setDriverGroup(responseDriverGroup) {
        this._driverGroupCollection = this._driverGroupCollection.set(
            responseDriverGroup.id,
            new DriverGroup(responseDriverGroup)
        );
    }

    /**
     * Remove _driverGroupCollection[key]
     *
     * @params {number} driverGroupId
     * @private
     */
    _removeDriverGroup(driverGroupId) {
        this._driverGroupCollection = this._driverGroupCollection.remove(driverGroupId);
    }

    /**
     * Set updatePending flag on driverGroup
     *
     * @param {number} driverGroupId
     * @private
     */
    _toggleUpdatePending(driverGroupId) {
        var driverGroup = this._driverGroupCollection.get(driverGroupId);
        driverGroup = driverGroup.set("updatePending", !driverGroup.get("updatePending"));
        this._driverGroupCollection = this._driverGroupCollection.set(driverGroupId, driverGroup);
    }

    /**
     * Get _driverGroupCollection
     *
     * @returns {Immutable.OrderedMap<string, DriverGroup>}
     */
    get driverGroupCollection() {
        return this._driverGroupCollection;
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new DriverGroupStore();
