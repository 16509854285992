"use strict";

import React from "react";
import PropTypes from "prop-types";

/**
 * FlashBar component
 */
export default class FlashBar extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        isVisible: PropTypes.bool,
        type: PropTypes.oneOf(["info", "success", "warning", "error"]),
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        isVisible: false,
        type: "default",
    };

    /**
     * React: render
     */
    render() {
        if (this.props.isVisible) {
            var className = "ui-flash-bar" + (this.props.type ? " " + this.props.type : "");
            var iconClassName = "ui-icon " + (this.props.type ? " icon-" + this.props.type : "");
            return (
                <div className={className}>
                    <i className={iconClassName} />
                    {this.props.children}
                </div>
            );
        } else {
            return null;
        }
    }
}
