"use strict";

import React from "react";
import { Route, Routes, Navigate } from "react-router";
import { BrowserRouter } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import AuthStore from "../stores/AuthStore";
import PageContainer from "../components/PageContainer.react";
import LoginPage from "../components/LoginPage.react";
import SchedulePage from "../components/SchedulePage.react";
import DriversPage from "../components/DriversPage.react";
import ToursPage from "../components/ToursPage.react";
import CalendarPage from "../components/CalendarPage.react";
import AccountsPage from "../components/AccountsPage.react";
import FormPage from "../components/FormPage.react";

/**
 * React router
 */
export const SecuredRouter = () => {
    const [user, setUser] = React.useState(AuthStore.user);
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PageContainer onUserChange={(u) => setUser(u)} />}>
                    <Route name="login" path="login" element={<LoginPage />} />
                    <Route element={<ProtectedRoute user={user} />}>
                        <Route name="schedule" path="schedule" element={<SchedulePage />} />
                        <Route name="drivers" path="drivers" element={<DriversPage />} />
                        <Route name="tours" path="tours" element={<ToursPage />} />
                        <Route name="calendar" path="calendar" element={<CalendarPage />} />
                        <Route name="accounts" path="accounts" element={<AccountsPage />} />
                        <Route name="form" path="form" element={<FormPage />} />
                    </Route>
                    <Route path="/" element={<Navigate to="schedule" replace />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
