"use strict";

import AuthApi from "./AuthApi";
import CalendarEventApi from "./CalendarEventApi";
import DriverApi from "./DriverApi";
import DriverAbsenceApi from "./DriverAbsenceApi";
import DriverGroupApi from "./DriverGroupApi";
import TourApi from "./TourApi";
import TourDateApi from "./TourDateApi";

export default Object.assign(
    {},
    AuthApi,
    CalendarEventApi,
    DriverApi,
    DriverAbsenceApi,
    DriverGroupApi,
    TourApi,
    TourDateApi
);
