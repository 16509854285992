"use strict";

import moment from "moment";
import { extendMoment } from "moment-range";
import Immutable from "immutable";
import DriverAbsence from "../types/DriverAbsence";
import ActionTypes from "../constants/ActionTypes";
import EntityStore from "./EntityStore";

/**
 * Flux: DriverAbsenceStore
 */
class DriverAbsenceStore extends EntityStore {
    /**
     * driverAbsenceCollection hash
     *
     * @type {Immutable.OrderedMap<string, DriverAbsence>}
     * @private
     */
    _driverAbsenceCollection = Immutable.OrderedMap();

    /**
     * driverAbsenceByDriver hash
     *
     * @type {Immutable.Map<string, DriverAbsence>}
     * @private
     */
    _driverAbsenceByDriver = Immutable.Map();

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));

        this._error = null;
    }

    /**
     * Flux: register store to actions
     *
     * @param {*} action
     * @private
     */
    _registerToActions(action) {
        switch (action.type) {
            // get driverAbsenceCollection
            case ActionTypes.REQUEST_GET_DRIVER_ABSENCE_COLLECTION:
                this._error = null;
                break;

            case ActionTypes.REQUEST_GET_DRIVER_ABSENCE_COLLECTION_SUCCESS:
                this._setDriverAbsenceCollection(action.body.driverAbsenceCollection);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_GET_DRIVER_ABSENCE_COLLECTION_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            // create driverAbsence
            case ActionTypes.REQUEST_CREATE_DRIVER_ABSENCE:
                this._error = null;
                break;

            case ActionTypes.REQUEST_CREATE_DRIVER_ABSENCE_SUCCESS:
                this._updateDriverAbsenceCollectionForDriver(
                    action.driverAbsence.driver,
                    action.body.driverAbsenceCollection
                );
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_CREATE_DRIVER_ABSENCE_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            // get driverAbsence
            case ActionTypes.REQUEST_GET_DRIVER_ABSENCE:
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.driverAbsenceId));
                break;

            case ActionTypes.REQUEST_GET_DRIVER_ABSENCE_SUCCESS:
                this._setDriverAbsence(action.body.driverAbsence);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.body.driverAbsence.id));
                break;

            case ActionTypes.REQUEST_GET_DRIVER_ABSENCE_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.driverAbsenceId));
                break;

            // update driverAbsence
            case ActionTypes.REQUEST_UPDATE_DRIVER_ABSENCE:
                this._toggleUpdatePending(action.driverAbsence.id);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.driverAbsence.id));
                break;

            case ActionTypes.REQUEST_UPDATE_DRIVER_ABSENCE_SUCCESS:
                this._updateDriverAbsenceCollectionForDriver(
                    action.driverAbsence.driver,
                    action.body.driverAbsenceCollection
                );
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.driverAbsence.id));
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_UPDATE_DRIVER_ABSENCE_ERROR:
                this._toggleUpdatePending(action.driverAbsence.id);
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.driverAbsence.id));
                break;

            // remove driverAbsence
            case ActionTypes.REQUEST_REMOVE_DRIVER_ABSENCE:
                this._toggleUpdatePending(action.driverAbsenceId);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.driverAbsenceId));
                break;

            case ActionTypes.REQUEST_REMOVE_DRIVER_ABSENCE_SUCCESS:
                this._toggleUpdatePending(action.driverAbsenceId);
                this._removeDriverAbsence(action.driverAbsenceId);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_REMOVE_DRIVER_ABSENCE_ERROR:
                this._toggleUpdatePending(action.driverAbsenceId);
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.driverAbsenceId));
                break;

            default:
                break;
        }
    }

    /**
     * Set _driverAbsenceCollection
     *
     * @param {Array.<DriverAbsence>} responseDriverAbsenceCollection
     * @private
     */
    _setDriverAbsenceCollection(responseDriverAbsenceCollection) {
        responseDriverAbsenceCollection.map((responseDriverAbsence) => {
            this._setDriverAbsence(responseDriverAbsence, false);
        });
        this._rebuildDriverAbsenceCollectionHash();
        this._sortDriverAbsenceCollectionBy("startDate", false);
    }

    /**
     * Set _driverAbsenceCollection[key]
     *
     * @params {DriverAbsence} responseDriverAbsence
     * @params {bool} rebuildHash
     * @private
     */
    _setDriverAbsence(responseDriverAbsence, rebuildHash = true) {
        var oldDriverAbsence = this._driverAbsenceCollection.get(responseDriverAbsence.id);
        var driverAbsence = new DriverAbsence({
            ...responseDriverAbsence,
            startDate: moment(responseDriverAbsence.startDate),
            endDate: moment(responseDriverAbsence.endDate),
        });

        this._driverAbsenceCollection = this._driverAbsenceCollection.set(responseDriverAbsence.id, driverAbsence);
        if (rebuildHash) {
            this._rebuildDriverAbsenceHash(driverAbsence, oldDriverAbsence);
        }
    }

    /**
     * Update _driverAbsenceCollection for driver
     *
     * @param {Array.<DriverAbsence>} responseDriverAbsenceCollection
     * @param {Driver} driver
     * @private
     */
    _updateDriverAbsenceCollectionForDriver(driver, responseDriverAbsenceCollection) {
        this._driverAbsenceCollection = this._driverAbsenceCollection.filter((driverAbsence) => {
            return driverAbsence.driver.id !== driver.id;
        });

        this._setDriverAbsenceCollection(responseDriverAbsenceCollection);
    }

    /**
     * SortBy _driverAbsenceCollection
     * @param {string} key
     * @param {bool} ascending
     * @private
     */
    _sortDriverAbsenceCollectionBy(key, ascending = true) {
        this._driverAbsenceCollection = this._driverAbsenceCollection.sort((itemA, itemB) => {
            return itemA[key] === itemB[key]
                ? 0
                : ascending
                ? itemA[key] > itemB[key]
                    ? 1
                    : -1
                : itemA[key] < itemB[key]
                ? 1
                : -1;
        });
    }

    /**
     * Remove _driverAbsenceCollection[key]
     *
     * @params {number} driverAbsenceId
     * @private
     */
    _removeDriverAbsence(driverAbsenceId) {
        // clean up hashes
        var driverAbsence = this._driverAbsenceCollection.get(driverAbsenceId);
        var absencePeriod = extendMoment(moment).range(driverAbsence.startDate, driverAbsence.endDate);
        for (const absenceDate of absencePeriod.by("days")) {
            let dateHash = absenceDate.format("YYYY-MM-DD");
            this._driverAbsenceByDriver = this._driverAbsenceByDriver.remove(driverAbsence.driver.id + "-" + dateHash);
        }

        this._driverAbsenceCollection = this._driverAbsenceCollection.remove(driverAbsenceId);
    }

    /**
     * Rebuilds _driverAbsence collection hashes
     *
     * @private
     */
    _rebuildDriverAbsenceCollectionHash() {
        this._driverAbsenceByDriver = Immutable.Map();

        this._driverAbsenceCollection.forEach((driverAbsence) => {
            var absencePeriod = extendMoment(moment).range(driverAbsence.startDate, driverAbsence.endDate);
            for (const absenceDate of absencePeriod.by("days")) {
                let dateHash = absenceDate.format("YYYY-MM-DD");
                this._driverAbsenceByDriver = this._driverAbsenceByDriver.set(
                    driverAbsence.driver.id + "-" + dateHash,
                    driverAbsence
                );
            }
        });
    }

    /**
     * Rebuilds _driverAbsenceCollection[key] hash
     *
     * @param {DriverAbsence} driverAbsence
     * @param {DriverAbsence} oldDriverAbsence
     * @private
     */
    _rebuildDriverAbsenceHash(driverAbsence, oldDriverAbsence) {
        // clean up old hashes
        if (oldDriverAbsence) {
            var absencePeriod = extendMoment(moment).range(oldDriverAbsence.startDate, oldDriverAbsence.endDate);
            for (const absenceDate of absencePeriod.by("days")) {
                let dateHash = absenceDate.format("YYYY-MM-DD");
                this._driverAbsenceByDriver = this._driverAbsenceByDriver.remove(
                    oldDriverAbsence.driver.id + "-" + dateHash
                );
            }
        }

        // set new hashes
        absencePeriod = extendMoment(moment).range(driverAbsence.startDate, driverAbsence.endDate);
        for (const absenceDate of absencePeriod.by("days")) {
            let dateHash = absenceDate.format("YYYY-MM-DD");
            this._driverAbsenceByDriver = this._driverAbsenceByDriver.set(
                driverAbsence.driver.id + "-" + dateHash,
                driverAbsence
            );
        }
    }

    /**
     * Set updatePending flag on driverAbsence
     *
     * @param {number} driverAbsenceId
     * @private
     */
    _toggleUpdatePending(driverAbsenceId) {
        var driverAbsence = this._driverAbsenceCollection.get(driverAbsenceId);
        driverAbsence = driverAbsence.set("updatePending", !driverAbsence.get("updatePending"));
        this._driverAbsenceCollection = this._driverAbsenceCollection.set(driverAbsenceId, driverAbsence);
    }

    /**
     * Get driverAbsence for period
     *
     * @param {Object} startDate
     * @param {Object} endDate
     * @param {string} hashName
     * @return {Immutable.Map<string, DriverAbsence>}
     */
    getDriverAbsenceForPeriod(startDate, endDate, hashName) {
        // filter driverAbsence for period
        return this[hashName].filter((driverAbsence) => {
            return (
                (driverAbsence.endDate.isAfter(startDate, "day") || driverAbsence.endDate.isSame(startDate, "day")) &&
                (driverAbsence.startDate.isBefore(endDate, "day") || driverAbsence.startDate.isSame(endDate, "day"))
            );
        });
    }

    /**
     * Get driverAbsence for driver
     *
     * @param {Driver} driver
     * @param {string} hashName
     * @return {Immutable.Map<string, DriverAbsence>}
     */
    getDriverAbsenceForDriver(driver, hashName) {
        // filter driverAbsence for driver
        return this[hashName].filter((driverAbsence) => {
            return driverAbsence.driver.id === driver.id;
        });
    }

    /**
     * Get _driverAbsenceCollection
     *
     * @returns {Immutable.OrderedMap<string, DriverAbsence>}
     */
    get driverAbsenceCollection() {
        return this._driverAbsenceCollection;
    }

    /**
     * Get _driverAbsenceByDriver
     *
     * @returns {Immutable.OrderedMap<string, DriverAbsence>}
     */
    get driverAbsenceByDriver() {
        return this._driverAbsenceByDriver;
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new DriverAbsenceStore();
