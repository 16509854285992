"use strict";

import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import * as InternalPropTypes from "../types/PropTypes";
import Driver from "../types/Driver";
import TourDate from "../types/TourDate";
import CalendarEvent from "../types/CalendarEvent";
import DriverAbsence from "../types/DriverAbsence";
import DriverAbsenceTypes from "../constants/DriverAbsenceTypes";
import { translate } from "../i18n/Translate";
import LoadingAnimation from "../ui/elements/LoadingAnimation.react";

/**
 * TourDateDriverCell component
 */
export default class TourDateDriverCell extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        driver: PropTypes.instanceOf(Driver),
        week: PropTypes.object,
        weekDay: InternalPropTypes.moment,
        tourDate: PropTypes.instanceOf(TourDate),
        tourDatesForPeriod: ImmutablePropTypes.mapOf(PropTypes.instanceOf(TourDate)),
        tourDatesForPeriodCoDrivers: ImmutablePropTypes.mapOf(PropTypes.instanceOf(TourDate)),
        driverAbsenceForPeriod: ImmutablePropTypes.mapOf(PropTypes.instanceOf(DriverAbsence)),
        calendarEventsForPeriod: ImmutablePropTypes.mapOf(PropTypes.instanceOf(CalendarEvent)),
        toggleDriverAbsenceModal: PropTypes.func.isRequired,
        updatePending: PropTypes.bool,
    };

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.driver !== this.props.driver ||
            nextProps.driverAbsenceForPeriod !== this.props.driverAbsenceForPeriod ||
            nextProps.updatePending !== this.props.updatePending
        );
    }

    /**
     * React: render
     */
    render() {
        // is update pending for date?
        if (this.props.updatePending) {
            return (
                <td className="driver-date update-pending">
                    <LoadingAnimation type="bars-3" />
                </td>
            );

            // date is static
        } else {
            var driver = this.props.driver;
            var weekDay = this.props.weekDay;
            var key = driver.id + "-" + weekDay.format("YYYY-MM-DD");
            var tourDate = this.props.tourDatesForPeriod.get(key) || this.props.tourDatesForPeriodCoDrivers.get(key);
            var driverAbsence = this.props.driverAbsenceForPeriod.get(key);
            var calendarEvent = this.props.calendarEventsForPeriod.get(weekDay.format("YYYY-MM-DD"));
            var scheduleEmpty = this.props.tourDatesForPeriod.size === 0;
            var className = "driver-date ";
            var cellContent = null;
            var driverIsCoDriver = tourDate && tourDate.coDriver && tourDate.coDriver.id === driver.id ? true : false;

            // driver is former employee
            if (driver.employmentEnd && weekDay.isAfter(driver.employmentEnd)) {
                className += "free";
                cellContent = translate("driver.absence.former_employee");

                // schedule IS NOT empty, driver IS driving on date, IS NOT absent, and IS NOT assigned
            } else if (!scheduleEmpty && driver.weekDays[weekDay.isoWeekday()] && !driverAbsence && !tourDate) {
                className += "reserve";
                cellContent = translate("driver.reserve");

                // driver IS NOT absent, IS assigned, and date IS Sunday OR CalendarEvent (holiday)
            } else if (
                !driverAbsence &&
                tourDate &&
                (weekDay.isoWeekday() === 7 || typeof calendarEvent !== "undefined")
            ) {
                className += "holiday";
                cellContent = (driverIsCoDriver ? "Mitfahrer: " : "") + tourDate.tour.tourName;

                // driver IS NOT absent, IS NOT flexible, IS assigned, and IS NOT driving on date (allowances)
            } else if (!driverAbsence && !driver.isFlexible && !driver.weekDays[weekDay.isoWeekday()] && tourDate) {
                className += "allowances";
                cellContent = (driverIsCoDriver ? "Mitfahrer: " : "") + tourDate.tour.tourName;

                // driver IS NOT absent, IS flexible, IS assigned, and IS NOT driving on date (allowances)
            } else if (!driverAbsence && driver.isFlexible && !driver.weekDays[weekDay.isoWeekday()] && tourDate) {
                var freeDays = 0;
                this.props.week.by("days", (otherWeekDay) => {
                    let otherTourDate = this.props.tourDatesForPeriod.get(
                        driver.id + "-" + otherWeekDay.format("YYYY-MM-DD")
                    );
                    let otherDriverAbsence = this.props.driverAbsenceForPeriod.get(
                        driver.id + "-" + otherWeekDay.format("YYYY-MM-DD")
                    );

                    // increment if free day
                    freeDays +=
                        !driver.weekDays[otherWeekDay.isoWeekday()] && !otherDriverAbsence && !otherTourDate ? 1 : 0;
                    // increment if flex free day
                    freeDays +=
                        otherDriverAbsence &&
                        otherDriverAbsence.absenceType === DriverAbsenceTypes.FLEX_FREE &&
                        !otherTourDate
                            ? 1
                            : 0;
                });
                className += freeDays >= 2 ? "" : "allowances";
                cellContent = (driverIsCoDriver ? "Mitfahrer: " : "") + tourDate.tour.tourName;

                // driver IS NOT absent and IS assigned
            } else if (!driverAbsence && tourDate) {
                cellContent = (driverIsCoDriver ? "Mitfahrer: " : "") + tourDate.tour.tourName;

                // driver IS NOT driving on date
            } else if (!driver.weekDays[weekDay.isoWeekday()]) {
                className += "free";
                cellContent = translate("driver.free");

                // driver IS absent
            } else if (driverAbsence) {
                className += driverAbsence.absenceType.replace("_", "-");
                cellContent = translate("driver.absence." + driverAbsence.absenceType);

                // schedule IS empty
            } else if (scheduleEmpty) {
                cellContent = "Ungeplant";
            }

            // tour IS mountain and cell IS NOT colored
            if (tourDate && tourDate.tour.isMountainTour && className === "driver-date ") {
                className += "mountain-tour";
            }

            return (
                <td
                    className={className}
                    title={cellContent}
                    onClick={this.props.toggleDriverAbsenceModal.bind(
                        null,
                        driver,
                        driverAbsence ? driverAbsence : null,
                        this.props.weekDay
                    )}
                >
                    {cellContent}
                </td>
            );
        }
    }
}
