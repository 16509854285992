"use strict";

import LangDE from "./de/Lang";

/**
 * Translate key into language string
 *
 * @param {string} key
 * @param {Object} params
 * @param {boolean} keyFromString
 * @return {string}
 */
export function translate(key, params = {}, keyFromString = false) {
    var lang = LangDE;

    if (keyFromString) {
        key = key.toLowerCase();
        key = key.replace(/[.,'"/#!$%^&*;:{}=\-_`~()]/g, "");
        key = key.replace(/\s{2,}/g, " ");
        key = key.replace(/\s/g, ".");
    }

    // if key doesn't exists return error string
    if (typeof lang[key] === "undefined") return key;

    if (typeof lang[key] === "function") {
        return lang[key](params);
    } else {
        return lang[key];
    }
}
