"use strict";

import moment from "moment";
import "moment-duration-format";
import React from "react";
import PropTypes from "prop-types";
import { translate } from "../../i18n/Translate";
import Choice from "./Choice.react";

/**
 * DeadlineTime component
 */
export default class DeadlineTime extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        value: PropTypes.objectOf(
            PropTypes.shape({
                daysBefore: PropTypes.number,
                time: PropTypes.string,
            })
        ),
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
    };

    static DEFAULT_DEADLINE = {
        daysBefore: 1,
        time: moment.duration(17, "hours"),
    };

    /**
     * React: state
     */
    state = {
        value: [],
    };

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this.state.value = this.props.value ? this.props.value : self.DEFAULT_DEADLINE;
    }

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
        });
    }

    /**
     * React: render
     */
    render() {
        var dayChoices = [0, 1, 2, 3, 4, 5, 6];
        var hourChoices = [];
        for (var hour = 0; hour <= 23; hour++) {
            hourChoices.push(moment.duration(hour, "hours"));
            hourChoices.push(moment.duration({ hours: hour, minutes: 30 }));
        }
        var formatHourChoice = (choice) => {
            if (choice.asHours() === 0) {
                return "00:00";
            } else if (choice.asMinutes() === 30) {
                return "00:30";
            }
            return choice.format("hh:mm");
        };

        var daysBeforeChoices = [];
        var timeChoices = [];
        [1, 2, 3, 4, 5, 6, 7].forEach((weekDay) => {
            daysBeforeChoices.push(
                <td key={"days-before-" + weekDay} className="days-before-choice">
                    <Choice
                        choices={dayChoices}
                        choiceLabel={(choices, weekDay) => {
                            return translate("deadline.days_before." + choices[weekDay]);
                        }}
                        onChange={this._onDeadlineDaysBeforeChange.bind(this, weekDay)}
                        value={this.state.value[weekDay].daysBefore}
                        width="2"
                        disabled={this.props.disabled}
                    />
                </td>
            );

            timeChoices.push(
                <td key={"time-" + weekDay} className="time-choice">
                    <Choice
                        choices={hourChoices}
                        choiceLabel={(choices, weekDay) => formatHourChoice(choices[weekDay])}
                        valueReference={(choiceValue) => formatHourChoice(choiceValue)}
                        onChange={this._onDeadlineTimeChange.bind(this, weekDay)}
                        value={this.state.value[weekDay].time}
                        width="2"
                        disabled={this.props.disabled}
                    />
                </td>
            );
        });

        return (
            <div className="ui-deadline">
                <table>
                    <tbody>
                        <tr>{daysBeforeChoices}</tr>
                        <tr>{timeChoices}</tr>
                    </tbody>
                </table>
            </div>
        );
    }

    /**
     * On deadline days before change
     *
     * @param {number} weekDay the weekday for which the daysBefore value changed
     * @param {string} daysBeforeValue
     * @private
     */
    _onDeadlineDaysBeforeChange = (weekDay, daysBeforeValue, event) => {
        var newValue = Object.assign({}, this.state.value);
        if (typeof newValue[weekDay] !== "object") {
            newValue[weekDay] = self.DEFAULT_DEADLINE;
        }
        newValue[weekDay].daysBefore = daysBeforeValue;

        this.setState(
            {
                value: newValue,
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value, event);
                }
            }
        );
    };

    /**
     * On deadline time change
     *
     * @param {number} weekDay the weekday for which the daysBefore value changed
     * @param {Object} timeValue
     * @private
     */
    _onDeadlineTimeChange = (weekDay, timeValue, event) => {
        var newValue = Object.assign({}, this.state.value);
        if (typeof newValue[weekDay] !== "object") {
            newValue[weekDay] = self.DEFAULT_DEADLINE;
        }
        newValue[weekDay].time = timeValue;

        this.setState(
            {
                value: newValue,
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value, event);
                }
            }
        );
    };
}
