"use strict";

import Immutable from "immutable";

/**
 * Driver type
 *
 * @typedef {(Immutable.Record|Object)} Driver
 * @property {number} id
 * @property {string} nickname
 * @property {string} firstName
 * @property {string} lastName
 * @property {Object} employmentStart
 * @property {?Object} employmentEnd
 * @property {bool} isDriver
 * @property {bool} isCoDriver
 * @property {bool} isPacker
 * @property {?DriverGroup} driverGroup
 * @property {!Object.<number, bool>} weekDays
 * @property {bool} isFlexible
 * @property {Array.<Tour>} knownTours
 * @property {Array.<DriverAbsence>} absence
 * @property {?Tour} reservedTour
 * @property {bool} updatePending
 */

/**
 * Driver record
 *
 * @type {Driver}
 */
var Driver = Immutable.Record({
    id: null,
    lastName: "",
    firstName: "",
    nickname: "",
    employmentStart: null,
    employmentEnd: null,
    isDriver: false,
    isCoDriver: false,
    isPacker: false,
    driverGroup: null,
    weekDays: { 1: true, 2: true, 3: true, 4: true, 5: true, 6: false, 7: false },
    isFlexible: false,
    knownTours: [],
    absence: [],
    reservedTour: null,
    updatePending: false,
});

export default Driver;
