"use strict";

import keyMirror from "keymirror";

/**
 * Flux: action types
 */
export default keyMirror({
    // App
    STORE_SCHEDULE_DATE_SELECTION: null,

    // Router
    STORE_ROUTER_TRANSITION_PATH: null,

    // Auth
    REQUEST_LOGIN_USER: null,
    REQUEST_LOGIN_USER_SUCCESS: null,
    REQUEST_LOGIN_USER_ERROR: null,

    LOGOUT_USER: null,

    // Calendar
    REQUEST_GET_CALENDAR_EVENT_COLLECTION: null,
    REQUEST_GET_CALENDAR_EVENT_COLLECTION_SUCCESS: null,
    REQUEST_GET_CALENDAR_EVENT_COLLECTION_ERROR: null,

    REQUEST_CREATE_CALENDAR_EVENT: null,
    REQUEST_CREATE_CALENDAR_EVENT_SUCCESS: null,
    REQUEST_CREATE_CALENDAR_EVENT_ERROR: null,

    REQUEST_GET_CALENDAR_EVENT: null,
    REQUEST_GET_CALENDAR_EVENT_SUCCESS: null,
    REQUEST_GET_CALENDAR_EVENT_ERROR: null,

    REQUEST_UPDATE_CALENDAR_EVENT: null,
    REQUEST_UPDATE_CALENDAR_EVENT_SUCCESS: null,
    REQUEST_UPDATE_CALENDAR_EVENT_ERROR: null,

    REQUEST_REMOVE_CALENDAR_EVENT: null,
    REQUEST_REMOVE_CALENDAR_EVENT_SUCCESS: null,
    REQUEST_REMOVE_CALENDAR_EVENT_ERROR: null,

    // Driver
    REQUEST_GET_DRIVER_COLLECTION: null,
    REQUEST_GET_DRIVER_COLLECTION_SUCCESS: null,
    REQUEST_GET_DRIVER_COLLECTION_ERROR: null,

    REQUEST_CREATE_DRIVER: null,
    REQUEST_CREATE_DRIVER_SUCCESS: null,
    REQUEST_CREATE_DRIVER_ERROR: null,

    REQUEST_GET_DRIVER: null,
    REQUEST_GET_DRIVER_SUCCESS: null,
    REQUEST_GET_DRIVER_ERROR: null,

    REQUEST_UPDATE_DRIVER: null,
    REQUEST_UPDATE_DRIVER_SUCCESS: null,
    REQUEST_UPDATE_DRIVER_ERROR: null,

    REQUEST_REMOVE_DRIVER: null,
    REQUEST_REMOVE_DRIVER_SUCCESS: null,
    REQUEST_REMOVE_DRIVER_ERROR: null,

    // Driver absence
    REQUEST_GET_DRIVER_ABSENCE_COLLECTION: null,
    REQUEST_GET_DRIVER_ABSENCE_COLLECTION_SUCCESS: null,
    REQUEST_GET_DRIVER_ABSENCE_COLLECTION_ERROR: null,

    REQUEST_CREATE_DRIVER_ABSENCE: null,
    REQUEST_CREATE_DRIVER_ABSENCE_SUCCESS: null,
    REQUEST_CREATE_DRIVER_ABSENCE_ERROR: null,

    REQUEST_GET_DRIVER_ABSENCE: null,
    REQUEST_GET_DRIVER_ABSENCE_SUCCESS: null,
    REQUEST_GET_DRIVER_ABSENCE_ERROR: null,

    REQUEST_UPDATE_DRIVER_ABSENCE: null,
    REQUEST_UPDATE_DRIVER_ABSENCE_SUCCESS: null,
    REQUEST_UPDATE_DRIVER_ABSENCE_ERROR: null,

    REQUEST_REMOVE_DRIVER_ABSENCE: null,
    REQUEST_REMOVE_DRIVER_ABSENCE_SUCCESS: null,
    REQUEST_REMOVE_DRIVER_ABSENCE_ERROR: null,

    // Driver group
    REQUEST_GET_DRIVER_GROUP_COLLECTION: null,
    REQUEST_GET_DRIVER_GROUP_COLLECTION_SUCCESS: null,
    REQUEST_GET_DRIVER_GROUP_COLLECTION_ERROR: null,

    REQUEST_CREATE_DRIVER_GROUP: null,
    REQUEST_CREATE_DRIVER_GROUP_SUCCESS: null,
    REQUEST_CREATE_DRIVER_GROUP_ERROR: null,

    REQUEST_GET_DRIVER_GROUP: null,
    REQUEST_GET_DRIVER_GROUP_SUCCESS: null,
    REQUEST_GET_DRIVER_GROUP_ERROR: null,

    REQUEST_UPDATE_DRIVER_GROUP: null,
    REQUEST_UPDATE_DRIVER_GROUP_SUCCESS: null,
    REQUEST_UPDATE_DRIVER_GROUP_ERROR: null,

    REQUEST_REMOVE_DRIVER_GROUP: null,
    REQUEST_REMOVE_DRIVER_GROUP_SUCCESS: null,
    REQUEST_REMOVE_DRIVER_GROUP_ERROR: null,

    // Tour
    REQUEST_GET_TOUR_COLLECTION: null,
    REQUEST_GET_TOUR_COLLECTION_SUCCESS: null,
    REQUEST_GET_TOUR_COLLECTION_ERROR: null,

    REQUEST_CREATE_TOUR: null,
    REQUEST_CREATE_TOUR_SUCCESS: null,
    REQUEST_CREATE_TOUR_ERROR: null,

    REQUEST_GET_TOUR: null,
    REQUEST_GET_TOUR_SUCCESS: null,
    REQUEST_GET_TOUR_ERROR: null,

    REQUEST_UPDATE_TOUR: null,
    REQUEST_UPDATE_TOUR_SUCCESS: null,
    REQUEST_UPDATE_TOUR_ERROR: null,

    REQUEST_REMOVE_TOUR: null,
    REQUEST_REMOVE_TOUR_SUCCESS: null,
    REQUEST_REMOVE_TOUR_ERROR: null,

    // TourDate
    REQUEST_GET_SCHEDULE: null,
    REQUEST_GET_SCHEDULE_SUCCESS: null,
    REQUEST_GET_SCHEDULE_ERROR: null,

    REQUEST_CREATE_SCHEDULE: null,
    REQUEST_CREATE_SCHEDULE_SUCCESS: null,
    REQUEST_CREATE_SCHEDULE_ERROR: null,

    REQUEST_REMOVE_SCHEDULE: null,
    REQUEST_REMOVE_SCHEDULE_SUCCESS: null,
    REQUEST_REMOVE_SCHEDULE_ERROR: null,

    REQUEST_GET_TOUR_DATE: null,
    REQUEST_GET_TOUR_DATE_SUCCESS: null,
    REQUEST_GET_TOUR_DATE_ERROR: null,

    REQUEST_CREATE_TOUR_DATE: null,
    REQUEST_CREATE_TOUR_DATE_SUCCESS: null,
    REQUEST_CREATE_TOUR_DATE_ERROR: null,

    REQUEST_UPDATE_TOUR_DATE: null,
    REQUEST_UPDATE_TOUR_DATE_SUCCESS: null,
    REQUEST_UPDATE_TOUR_DATE_ERROR: null,

    REQUEST_UPDATE_TOUR_DATE_DRIVER: null,
    REQUEST_UPDATE_TOUR_DATE_DRIVER_SUCCESS: null,
    REQUEST_UPDATE_TOUR_DATE_DRIVER_ERROR: null,
});
