"use strict";

import React from "react";
import BaseForm from "../ui/BaseForm";

/**
 * CalendarEventForm class
 */
export default class CalendarEventForm extends BaseForm {
    /**
     * @inheritdoc
     */
    buildForm(builder) {
        builder.add("eventName", "text", {
            label: "Name",
        });
        builder.add("eventDate", "hidden", {});
        builder.add("canceledTours", "entity", {
            className: "Tour",
            choiceLabel: (tours, key) => {
                let tour = tours[key];
                return (
                    <span>
                        {tour.tourName}
                        {!tour.hasShifts ? null : <sup>&nbsp;am/pm</sup>}
                    </span>
                );
            },
            label: "Touren",
            multiple: true,
            expanded: true,
            autoSelect: true,
            width: 6,
        });
    }
}
