"use strict";

var DriverAbsenceTypes = {
    UNKNOWN: "unknown",
    SICK_LEAVE: "sick_leave",
    ACCIDENT: "accident",
    UNPAID: "unpaid",
    PAID: "paid",
    VACATION: "vacation",
    VACATION_PAID: "paid_vacation",
    COMPENSATION: "compensation",
    FLEX_FREE: "flex_free",

    // transitions
    SET_DRIVER_SICK: "set_driver_sick",
    SET_DRIVER_ACCIDENT: "set_driver_accident",
    SET_CO_DRIVER_SICK: "set_co_driver_sick",
    SET_CO_DRIVER_ACCIDENT: "set_co_driver_accident",
};

export function getOptions() {
    var options = {};
    Object.keys(DriverAbsenceTypes).forEach((key) => {
        if (key.indexOf("SET_") === -1) {
            options[key] = DriverAbsenceTypes[key];
        }
    });
    return options;
}

export default DriverAbsenceTypes;
