"use strict";

import React from "react";
import PropTypes from "prop-types";
import DriverGroup from "../types/DriverGroup";
import DriverGroupActions from "../actions/DriverGroupActions";
import DriverGroupStore from "../stores/DriverGroupStore";
import DriverGroupForm from "../forms/DriverGroupForm";
import Form from "../ui/elements/Form.react";
import Field from "../ui/elements/Field.react";
import Modal from "../ui/elements/Modal.react";
import Button from "../ui/elements/Button.react";
import ElementUpdatePending from "../ui/elements/ElementUpdatePending.react";
import LoadingAnimation from "../ui/elements/LoadingAnimation.react";

/**
 * DriverGroupsModal component
 */
export default class DriverGroupsModal extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        isVisible: PropTypes.bool,
        driverGroup: PropTypes.instanceOf(DriverGroup),
        toggleDriverGroupsModal: PropTypes.func.isRequired,
    };

    /**
     * React: state
     */
    state = {
        driverGroup: null,
        driverGroupCollection: null,
        updatePending: false,
        removePending: false,
    };

    /**
     * Form ref
     * @type {Object|null}
     * @private
     */
    _form = null;

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        // modal will be visible..
        if (nextProps.isVisible && this.state.driverGroup === null) {
            // add store listeners
            DriverGroupStore.addListener(DriverGroupStore.ENTITY_COLLECTION_UPDATED, this._onDriverGroupCollectionLoad);

            // set driver from initial prop and driverGroup if there, otherwise create new driverGroup
            this.setState({
                driverGroup: nextProps.driverGroup != null ? nextProps.driverGroup : new DriverGroup({}),
            });

            // get all driver groups
            this._getDriverGroupCollection();
        }

        // modal will be hidden..
        if (!nextProps.isVisible) {
            // remove store listeners
            if (this.state.driverGroup !== null) {
                DriverGroupStore.addListener(
                    DriverGroupStore.ENTITY_UPDATED(this.state.driverGroup.id),
                    this._onDriverGroupUpdate
                );
                DriverGroupStore.addListener(
                    DriverGroupStore.ENTITY_UPDATED(this.state.driverGroup.id),
                    this._onDriverGroupRemove
                );
            }
            DriverGroupStore.removeListener(
                DriverGroupStore.ENTITY_COLLECTION_UPDATED,
                this._onDriverGroupCollectionLoad
            );
            DriverGroupStore.removeListener(DriverGroupStore.ENTITY_COLLECTION_UPDATED, this._onDriverGroupCreate);
            DriverGroupStore.removeListener(DriverGroupStore.ENTITY_COLLECTION_UPDATED, this._onDriverGroupRemove);

            // reset state
            this.setState({
                driverGroup: null,
                driverGroupCollection: null,
                updatePending: false,
                removePending: false,
            });
            this._form = null;
        }
    }

    /**
     * React: render
     */
    render() {
        var tabularContent = null;

        if (this.state.driverGroupCollection !== null) {
            let tableRows = [];
            this.state.driverGroupCollection.forEach((driverGroup) => {
                var drivers = driverGroup.drivers.map((driver) => {
                    return driver.nickname;
                });
                var tours = driverGroup.tours.map((tour) => {
                    return tour.tourName;
                });

                tableRows.push(
                    <tr key={driverGroup.id}>
                        <td onClick={this._setFormDriverGroup.bind(null, driverGroup)}>{driverGroup.groupName}</td>
                        <td onClick={this._setFormDriverGroup.bind(null, driverGroup)}>{drivers.join(", ")}</td>
                        <td onClick={this._setFormDriverGroup.bind(null, driverGroup)}>{tours.join(", ")}</td>
                        <td className="actions">
                            {driverGroup.updatePending ? (
                                <ElementUpdatePending isVisible={true} type="bars-3" />
                            ) : (
                                <i
                                    className="ui-icon icon-cancel"
                                    onClick={this._removeDriverGroup.bind(null, driverGroup)}
                                />
                            )}
                        </td>
                    </tr>
                );
            });

            tabularContent = (
                <div className="ui-collection-output ui-col-10">
                    <table className="ui-row-4">
                        <thead>
                            <tr>
                                <th>Gruppe</th>
                                <th>Fahrer</th>
                                <th>Tour</th>
                                <th className="actions" />
                            </tr>
                        </thead>
                        <tbody>{tableRows}</tbody>
                    </table>
                </div>
            );
        }

        return (
            <Modal isVisible={this.props.isVisible} title="Gruppen" subtitle="Gruppenliste Editieren">
                <Modal.Content width="12">
                    {this.state.driverGroup === null || this.state.driverGroupCollection === null ? (
                        <div className="content-loading">
                            <LoadingAnimation />
                        </div>
                    ) : (
                        <div className="collection-editor">
                            <Form
                                type={new DriverGroupForm()}
                                data={this.state.driverGroup}
                                formRef={(form) => {
                                    this._form = form;
                                }}
                                action={this._saveDriverGroup}
                                showLabels={false}
                                inline={true}
                            >
                                <label className="ui-col-2">Neue Gruppe</label>
                                <Field name="groupName" />
                                <Button
                                    actionType="positive"
                                    onClick={() => {
                                        this._form.submit();
                                    }}
                                    disabled={this.state.updatePending || this.state.removePending}
                                    updatePending={this.state.updatePending}
                                >
                                    Speichern
                                </Button>
                            </Form>
                            <div
                                className={
                                    "collection-table" +
                                    (this.state.updatePending || this.state.removePending ? " disabled" : "")
                                }
                            >
                                <label className="ui-col-2">Gruppenliste</label>
                                {tabularContent}
                            </div>
                        </div>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Modal.ActionButton onClick={this.props.toggleDriverGroupsModal}>Schliessen</Modal.ActionButton>
                </Modal.Actions>
            </Modal>
        );
    }

    /**
     * Get driverGroupCollection
     * @private
     */
    _getDriverGroupCollection = () => {
        DriverGroupActions.getDriverGroupCollection({});
    };

    /**
     * On driverGroup collection load
     * @private
     */
    _onDriverGroupCollectionLoad = () => {
        this.setState(
            {
                driverGroupCollection: DriverGroupStore.driverGroupCollection,
            },
            () => {
                if (this.state.driverGroupCollection) {
                    DriverGroupStore.removeListener(
                        DriverGroupStore.ENTITY_COLLECTION_UPDATED,
                        this._onDriverGroupCollectionLoad
                    );
                }
            }
        );
    };

    /**
     * Set form driverGroup
     * @param {DriverGroup} driverGroup
     * @private
     */
    _setFormDriverGroup = (driverGroup) => {
        if (this.state.updatePending || this.state.removePending) return;

        this.setState({
            driverGroup: driverGroup || new DriverGroup({}),
        });
    };

    /**
     * Save driverGroup
     * @param {DriverGroup} driverGroup
     * @private
     */
    _saveDriverGroup = (driverGroup) => {
        this._form.setDisabled(true);
        this.setState(
            {
                driverGroup: driverGroup,
                updatePending: true,
            },
            () => {
                // call the action
                if (!driverGroup.id) {
                    DriverGroupStore.addListener(DriverGroupStore.ENTITY_COLLECTION_UPDATED, this._onDriverGroupCreate);
                    DriverGroupActions.createDriverGroup({ driverGroup: driverGroup });
                } else {
                    DriverGroupStore.addListener(
                        DriverGroupStore.ENTITY_UPDATED(this.state.driverGroup.id),
                        this._onDriverGroupUpdate
                    );
                    DriverGroupActions.updateDriverGroup({ driverGroup: driverGroup });
                }
            }
        );
    };

    /**
     * On driverGroup create
     * @private
     */
    _onDriverGroupCreate = () => {
        // create completed..
        if (this.state.updatePending) {
            // remove listener
            DriverGroupStore.removeListener(DriverGroupStore.ENTITY_COLLECTION_UPDATED, this._onDriverGroupCreate);

            // ..with error
            if (DriverGroupStore.error !== null) {
                this._form.setError(DriverGroupStore.error);

                // ..successfully
            } else {
                // no op
            }

            this._form.setDisabled(false);
            this.setState({
                driverGroup: DriverGroupStore.error !== null ? this.state.driverGroup : new DriverGroup({}),
                driverGroupCollection: DriverGroupStore.driverGroupCollection,
                updatePending: false,
            });
        }
    };

    /**
     * On driverGroup update
     * @private
     */
    _onDriverGroupUpdate = () => {
        var driverGroup = DriverGroupStore.driverGroupCollection.get(this.state.driverGroup.id);

        // update completed..
        if (this.state.updatePending && !driverGroup.updatePending) {
            // remove listener
            DriverGroupStore.removeListener(DriverGroupStore.ENTITY_UPDATED(driverGroup.id), this._onDriverGroupUpdate);

            // ..with error
            if (DriverGroupStore.error !== null) {
                this._form.setError(DriverGroupStore.error);

                // ..successfully
            } else {
                // no op
            }

            this._form.setDisabled(false);
            this.setState({
                driverGroup: DriverGroupStore.error !== null ? this.state.driverGroup : new DriverGroup({}),
                driverGroupCollection: DriverGroupStore.driverGroupCollection,
                updatePending: false,
            });

            // update running..
        } else if (this.state.updatePending && driverGroup.updatePending) {
            // ..refresh list for indication
            this.setState({
                driverGroupCollection: DriverGroupStore.driverGroupCollection,
            });
        }
    };

    /**
     * Remove driverGroup
     * @param {DriverGroup} driverGroup
     * @private
     */
    _removeDriverGroup = (driverGroup) => {
        if (this.state.updatePending || this.state.removePending) return;

        this._form.setDisabled(true);
        this.setState(
            {
                driverGroup: driverGroup, // new DriverGroup({}),
                removePending: true,
            },
            () => {
                DriverGroupStore.addListener(
                    DriverGroupStore.ENTITY_UPDATED(driverGroup.id),
                    this._onDriverGroupRemove
                );
                DriverGroupStore.addListener(DriverGroupStore.ENTITY_COLLECTION_UPDATED, this._onDriverGroupRemove);
                DriverGroupActions.removeDriverGroup({ driverGroupId: driverGroup.id });
            }
        );
    };

    /**
     * On driverGroup remove
     * @private
     */
    _onDriverGroupRemove = () => {
        var driverGroup =
            DriverGroupStore.driverGroupCollection.get(this.state.driverGroup.id) || this.state.driverGroup;

        // remove completed..
        if (this.state.removePending && !driverGroup.updatePending) {
            // remove listener
            DriverGroupStore.removeListener(DriverGroupStore.ENTITY_UPDATED(driverGroup.id), this._onDriverGroupRemove);
            DriverGroupStore.removeListener(DriverGroupStore.ENTITY_COLLECTION_UPDATED, this._onDriverGroupRemove);

            // ..with error
            if (DriverGroupStore.error !== null) {
                this._form.setError(DriverGroupStore.error);

                // ..successfully
            } else {
                // no op
            }

            this._form.setDisabled(false);
            this.setState({
                driverGroup: DriverGroupStore.error !== null ? this.state.driverGroup : new DriverGroup({}),
                driverGroupCollection: DriverGroupStore.driverGroupCollection,
                removePending: false,
            });

            // update running..
        } else if (this.state.removePending && driverGroup.updatePending) {
            // ..refresh list for indication
            this.setState({
                driverGroupCollection: DriverGroupStore.driverGroupCollection,
            });
        }
    };
}
