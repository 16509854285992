"use strict";

import React from "react";
import PropTypes from "prop-types";
import ElementUpdatePending from "./ElementUpdatePending.react";

/**
 * Button component
 */
export default class Button extends React.Component {
    /**
     * React: displayName
     * @type {string}
     */
    static displayName = "Button";

    /**
     * React: propTypes
     */
    static propTypes = {
        onClick: PropTypes.func,
        type: PropTypes.oneOf(["button", "submit", "reset"]),
        actionType: PropTypes.oneOf(["positive", "neutral", "warning", "negative", "info"]),
        size: PropTypes.oneOf([
            "small",
            "regular",
            "large",
            "x-large",
            "icon-small",
            "icon",
            "icon-regular",
            "icon-large",
            "icon-x-large",
        ]),
        shape: PropTypes.oneOf(["default", "rounded", "no-borders", "link"]),
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isSecondary: PropTypes.bool,
        updatePending: PropTypes.bool,
        disabled: PropTypes.bool,
        askForConfirmation: PropTypes.bool,
        confirmationMessage: PropTypes.string,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        type: "button",
        shape: "default",
        isSecondary: false,
        updatePending: false,
        disabled: false,
        askForConfirmation: false,
        confirmationMessage: "Are you sure?",
    };

    /**
     * React: state
     */
    state = {
        showConfirmation: false,
    };

    /**
     * React: render
     */
    render() {
        var buttonClassName =
            "ui-button" +
            (this.props.isSecondary ? " btn-secondary" : "") +
            (typeof this.props.actionType !== "undefined" ? " btn-" + this.props.actionType : " btn-neutral") +
            (typeof this.props.size !== "undefined" ? " btn-" + this.props.size : "") +
            (this.props.shape !== "default" ? " btn-" + this.props.shape : "") +
            (typeof this.props.width !== "undefined" ? " ui-col-" + this.props.width : "");

        var confirmationButtonClassName = buttonClassName.replace(" btn-secondary", "");

        var rejectButtonClassName =
            "ui-button btn-no-borders" +
            (typeof this.props.size !== "undefined" ? " btn-icon-" + this.props.size : " btn-icon");

        return this.props.askForConfirmation && this.state.showConfirmation ? (
            <div className="confirmation-button">
                <button
                    type={this.props.type}
                    className={confirmationButtonClassName}
                    disabled={this.props.disabled}
                    onClick={this._onClick}
                >
                    {this.props.confirmationMessage}
                    <i className="ui-icon icon-success" />
                </button>
                <button type={this.props.type} className={rejectButtonClassName}>
                    <i className="ui-icon icon-cancel" onClick={this._toggleConfirmation} />
                </button>
            </div>
        ) : (
            <button
                type={this.props.type}
                className={buttonClassName}
                disabled={this.props.disabled}
                onClick={this.props.askForConfirmation ? this._toggleConfirmation : this._onClick}
            >
                <ElementUpdatePending isVisible={this.props.updatePending} type="bars-3" />
                {this.props.children}
            </button>
        );
    }

    /**
     * On click
     */
    _onClick = (event) => {
        this.setState({
            showConfirmation: false,
        });

        if (typeof this.props.onClick === "function") {
            this.props.onClick(event);
        }
        if (this.props.type === "button") {
            event.preventDefault();
        }
    };

    /**
     * Show confirmation
     */
    _toggleConfirmation = () => {
        this.setState({
            showConfirmation: !this.state.showConfirmation,
        });
    };
}
