"use strict";

import BaseForm from "../ui/BaseForm";
import ErpReference from "../types/ErpReference";

/**
 * TourForm class
 */
export default class TourForm extends BaseForm {
    /**
     * @inheritdoc
     */
    buildForm(builder) {
        builder.add("tourName", "text", {
            label: "Name",
        });
        builder.add("hasShifts", "checkbox", {
            label: "Schichtflag",
            alignLabel: true,
        });
        builder.add("weekDays", "weekDayChoice", {
            label: "Wochentage",
            width: 6,
        });
        builder.add("orderDeadline", "deadline", {
            label: "Bestellschluss",
        });
        builder.add("reservedDriver", "entity", {
            className: "Driver",
            placeholder: "Keiner",
            choiceLabel: "nickname",
            label: "Priorität Fahrer",
        });
        builder.add("driverGroup", "entity", {
            className: "DriverGroup",
            placeholder: "Keine Gruppe",
            choiceLabel: "groupName",
            label: "Gruppe",
        });
        builder.add("isMountainTour", "checkbox", {
            label: "Bergtour",
            alignLabel: true,
        });
        builder.add("drivers", "collectionOutput", {
            outputType: "list",
            listLabel: "nickname",
            label: "Fahrer",
            width: 6,
        });
        // alternative erpReferences input
        //builder.add('erpReferences', 'collection', {
        //    entryType: ErpReferenceForm,
        //    allowAdd: true,
        //    allowDelete: true,
        //    label: 'Tour-Nr. ERP'
        //});
        builder.add("erpReferences", "tagInput", {
            type: "number",
            valueReference: "reference",
            dataClass: ErpReference,
            label: "Tour-Nr. ERP",
        });
    }
}
