"use strict";

import "moment-duration-format";
import * as Request from "./Request";
import ApiEndpoints from "../constants/ApiEndpoints";

function convertOrderDeadline(orderDeadline) {
    var converted = {};
    Object.keys(orderDeadline).forEach((key) => {
        converted[key] = {
            daysBefore: orderDeadline[key].daysBefore,
            time: orderDeadline[key].time.asHours() === 24 ? "00:00:00" : orderDeadline[key].time.format("hh:mm:ss"),
        };
    });
    return converted;
}

export default {
    /**
     * Get tour collection
     * @param {Object} params
     * @param {Object} queryParams
     * @return {*}
     */
    getTourCollection: (params = {}, queryParams = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.SEARCH_TOURS,
            method: "GET",
            qs: queryParams,
        });
    },

    /**
     * Create tour
     * @param {Object} params
     * @param {Tour} params.tour
     * @return {*}
     */
    createTour: (params = {}) => {
        var tour = params.tour;
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.CREATE_TOUR,
            method: "POST",
            body: {
                tour: {
                    tourName: tour.tourName,
                    hasShifts: tour.hasShifts,
                    weekDays: tour.weekDays,
                    orderDeadline: convertOrderDeadline(tour.orderDeadline),
                    driverGroup: tour.driverGroup ? tour.driverGroup.id : null,
                    isMountainTour: tour.isMountainTour,
                    erpReference: tour.erpReference,
                    erpReferences: tour.erpReferences.map((erpReference) => {
                        return { reference: erpReference.reference };
                    }),
                    reservedDriver: tour.reservedDriver ? tour.reservedDriver.id : null,
                },
            },
        });
    },

    /**
     * Get tour
     * @param {Object} params
     * @param {Object} queryParams
     * @return {*}
     */
    getTour: (params = {}, queryParams = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.GET_TOUR(params.tourId),
            method: "GET",
            qs: queryParams,
        });
    },

    /**
     * Update tour
     * @param {Object} params
     * @param {Tour} params.tour
     * @return {*}
     */
    updateTour: (params = {}) => {
        var tour = params.tour;
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.UPDATE_TOUR(tour.id),
            method: "PUT",
            body: {
                tour: {
                    tourName: tour.tourName,
                    hasShifts: tour.hasShifts,
                    weekDays: tour.weekDays,
                    orderDeadline: convertOrderDeadline(tour.orderDeadline),
                    driverGroup: tour.driverGroup ? tour.driverGroup.id : null,
                    isMountainTour: tour.isMountainTour,
                    erpReferences: tour.erpReferences.map((erpReference) => {
                        return { reference: erpReference.reference };
                    }),
                    reservedDriver: tour.reservedDriver ? tour.reservedDriver.id : null,
                },
            },
        });
    },

    /**
     * Remove tour
     * @param {Object} params
     * @param {number} params.tourId
     * @return {*}
     */
    removeTour: (params = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.REMOVE_TOUR(params.tourId),
            method: "DELETE",
        });
    },
};
