"use strict";

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ImmutablePropTypes from "react-immutable-proptypes";
import TourDate from "../types/TourDate";
import Driver from "../types/Driver";
import DriverAbsence from "../types/DriverAbsence";
import TourDateActions from "../actions/TourDateActions";
import DriverActions from "../actions/DriverActions";
import DriverAbsenceActions from "../actions/DriverAbsenceActions";
import TourDateStore from "../stores/TourDateStore";
import DriverStore from "../stores/DriverStore";
import DriverAbsenceStore from "../stores/DriverAbsenceStore";
import AssignmentTypes from "../constants/AssignmentTypes";
import TourDateStatus from "../constants/TourDateStatus";
import { translate } from "../i18n/Translate";
import Modal from "../ui/elements/Modal.react";
import Button from "../ui/elements/Button.react";
import LoadingAnimation from "../ui/elements/LoadingAnimation.react";
import FlashBar from "../ui/elements/FlashBar.react";

/**
 * TourDateModal component
 */
export default class TourDateModal extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        isVisible: PropTypes.bool,
        tourDate: PropTypes.instanceOf(TourDate),
        toggleTourDateModal: PropTypes.func.isRequired,
    };

    /**
     * React: state
     */
    state = {
        tourDate: null,
        updatingDriver: null,
        drivers: null,
        driverAbsenceForPeriod: null,
    };

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        // modal will be visible
        if (nextProps.isVisible && nextProps.tourDate != null && this.state.tourDate === null) {
            // set tourDate from initial prop
            this.setState({ tourDate: nextProps.tourDate });

            // add store listeners
            TourDateStore.addListener(TourDateStore.ENTITY_UPDATED(nextProps.tourDate.id), this._setTourDate);
            DriverStore.addListener(DriverStore.ENTITY_COLLECTION_UPDATED, this._setDrivers);
            DriverAbsenceStore.addListener(
                DriverAbsenceStore.ENTITY_COLLECTION_UPDATED,
                this._setDriverAbsenceForPeriod
            );

            // get driver and driver absence
            this._getDrivers();
            this._getDriverAbsenceForPeriod(nextProps.tourDate);
        }

        // modal will be hidden
        if (!nextProps.isVisible && this.state.tourDate !== null) {
            // remove store listeners
            TourDateStore.removeListener(TourDateStore.ENTITY_UPDATED(this.state.tourDate.id), this._setTourDate);
            DriverStore.removeListener(DriverStore.ENTITY_COLLECTION_UPDATED, this._setDrivers);
            DriverAbsenceStore.removeListener(
                DriverAbsenceStore.ENTITY_COLLECTION_UPDATED,
                this._setDriverAbsenceForPeriod
            );

            // reset state
            this.setState({ tourDate: null, driverAbsenceForPeriod: null, drivers: null });
        }
    }

    /**
     * React: render
     */
    render() {
        var tourDate = this.state.tourDate;
        var tourDateDriverSelectProps = {
            tourDate: this.state.tourDate,
            updatingDriver: this.state.updatingDriver,
            drivers: this.state.drivers,
            reserveDrivers:
                this.state.tourDate !== null &&
                this.state.drivers !== null &&
                this.state.driverAbsenceForPeriod !== null
                    ? this.state.drivers.filter((driver) => {
                          // filter out all assigned drivers/co-drivers, absent drivers and drivers that don't work on that day
                          let drivingTourDate =
                              TourDateStore.tourDatesByDriver.get(
                                  driver.id + "-" + tourDate.calendarDate.format("YYYY-MM-DD")
                              ) || false;
                          let coDrivingTourDate =
                              TourDateStore.tourDatesByCoDriver.get(
                                  driver.id + "-" + tourDate.calendarDate.format("YYYY-MM-DD")
                              ) || false;
                          let driverAbsence =
                              this.state.driverAbsenceForPeriod.get(
                                  driver.id + "-" + tourDate.calendarDate.format("YYYY-MM-DD")
                              ) || false;

                          return (
                              !drivingTourDate &&
                              !coDrivingTourDate &&
                              !driverAbsence &&
                              driver.weekDays[moment(tourDate.calendarDate).isoWeekday()] &&
                              (driver.employmentEnd
                                  ? tourDate.calendarDate.isBefore(driver.employmentEnd.endOf("day"))
                                  : true)
                          );
                      })
                    : null,
            driverAbsenceForPeriod: this.state.driverAbsenceForPeriod,
            assignTourDateDriver: this._assignTourDateDriver,
            reassignTourDateDriver: this._reassignTourDateDriver,
            removeTourDateDriver: this._removeTourDateDriver,
        };

        return (
            <Modal
                isVisible={this.props.isVisible}
                title={tourDate ? tourDate.tour.tourName : ""}
                subtitle={tourDate ? "am " + tourDate.calendarDate.format("dddd, DD.M.YYYY.") : ""}
            >
                <Modal.Content width="12">
                    {this.state.tourDate === null ||
                    this.state.drivers === null ||
                    this.state.driverAbsenceForPeriod === null ? (
                        <div className="content-loading">
                            <LoadingAnimation />
                        </div>
                    ) : (
                        <div>
                            <TourDateDriverSelect
                                {...tourDateDriverSelectProps}
                                assignmentType={AssignmentTypes.DRIVER}
                            />
                            <TourDateDriverSelect
                                {...tourDateDriverSelectProps}
                                assignmentType={AssignmentTypes.CO_DRIVER}
                            />
                            <ReserveDriversList reserveDrivers={tourDateDriverSelectProps.reserveDrivers} />
                        </div>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Modal.ActionButton onClick={this.props.toggleTourDateModal.bind(this, null)}>
                        Schliessen
                    </Modal.ActionButton>
                </Modal.Actions>
            </Modal>
        );
    }

    /**
     * Get drivers
     */
    _getDrivers = () => {
        DriverActions.getDriverCollection();
    };

    /**
     * Set tours
     */
    _setDrivers = () => {
        this.setState({
            drivers: DriverStore.driverCollection,
        });
    };

    /**
     * Get driverAbsence for time period
     * @param {TourDate} tourDate
     */
    _getDriverAbsenceForPeriod = (tourDate) => {
        DriverAbsenceActions.getDriverAbsenceCollection({
            startDate: tourDate.calendarDate.format("YYYY-MM-DD"),
            endDate: tourDate.calendarDate.format("YYYY-MM-DD"),
        });
    };

    /**
     * Load driverAbsence from store for time period
     */
    _setDriverAbsenceForPeriod = () => {
        var driverAbsenceForPeriod = DriverAbsenceStore.getDriverAbsenceForPeriod(
            this.state.tourDate.calendarDate,
            this.state.tourDate.calendarDate,
            "_driverAbsenceByDriver"
        );

        this.setState({
            driverAbsenceForPeriod: driverAbsenceForPeriod,
        });
    };

    /**
     * Set tourDate
     */
    _setTourDate = () => {
        this.setState({
            tourDate: TourDateStore.tourDateCollection.get(this.state.tourDate.id),
            updatingDriver: null,
        });
    };

    /**
     * Assign tourDate driver
     */
    _assignTourDateDriver = (driver, assignmentType) => {
        var tourDate = this.state.tourDate;
        TourDateActions.updateTourDate({
            tourDateId: tourDate.id,
            driverId:
                assignmentType === AssignmentTypes.DRIVER ? driver.id : tourDate.driver ? tourDate.driver.id : null,
            coDriverId:
                assignmentType === AssignmentTypes.DRIVER
                    ? tourDate.coDriver
                        ? tourDate.coDriver.id
                        : null
                    : driver.id,
            status: TourDateStatus.ACTIVE,
        });

        this.setState({
            updatingDriver: driver,
        });
    };

    /**
     * Reassign tourDate driver
     */
    _reassignTourDateDriver = (driver, assignmentType, driverTourDate, driverAssignmentType) => {
        // remove driver from already assigned tourDate
        TourDateActions.updateTourDate({
            tourDateId: driverTourDate.id,
            driverId:
                driverAssignmentType === AssignmentTypes.DRIVER
                    ? null
                    : driverTourDate.driver
                    ? driverTourDate.driver.id
                    : null,
            coDriverId: null,
            status: driverAssignmentType === AssignmentTypes.DRIVER ? TourDateStatus.OPEN : TourDateStatus.ACTIVE,
        });

        // assign to current tourDate
        this._assignTourDateDriver(driver, assignmentType);
    };

    /**
     * Remove tourDate driver
     */
    _removeTourDateDriver = (assignmentType) => {
        var tourDate = this.state.tourDate;
        TourDateActions.updateTourDate({
            tourDateId: tourDate.id,
            driverId: assignmentType === AssignmentTypes.DRIVER ? null : tourDate.driver ? tourDate.driver.id : null,
            coDriverId: null,
            status: assignmentType === AssignmentTypes.DRIVER ? TourDateStatus.OPEN : TourDateStatus.ACTIVE,
        });

        this.setState({
            updatingDriver: assignmentType === AssignmentTypes.DRIVER ? tourDate.driver : tourDate.coDriver,
        });
    };
}

/**
 * TourDateDriverSelect component
 */
class TourDateDriverSelect extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        tourDate: PropTypes.instanceOf(TourDate),
        drivers: ImmutablePropTypes.mapOf(PropTypes.instanceOf(Driver)),
        reserveDrivers: ImmutablePropTypes.mapOf(PropTypes.instanceOf(Driver)),
        driverAbsenceForPeriod: ImmutablePropTypes.mapOf(PropTypes.instanceOf(DriverAbsence)),
        assignmentType: PropTypes.string,
        assignTourDateDriver: PropTypes.func.isRequired,
        reassignTourDateDriver: PropTypes.func.isRequired,
        removeTourDateDriver: PropTypes.func.isRequired,
    };

    /**
     * React: state
     */
    state = {
        isDriverSelectionVisible: this.props.assignmentType === AssignmentTypes.DRIVER,
    };

    /**
     * React: render
     */
    render() {
        var tourDate = this.props.tourDate;
        var tour = this.props.tourDate.tour;
        var assignmentType = this.props.assignmentType;
        var drivers = this.props.drivers.filter((driver) => {
            if (assignmentType === AssignmentTypes.DRIVER) {
                return (
                    driver.isDriver &&
                    (driver.employmentEnd ? tourDate.calendarDate.isBefore(driver.employmentEnd.endOf("day")) : true) &&
                    driver.knownTours.reduce((knowsTour, knownTour) => {
                        return knowsTour || knownTour.id === tour.id;
                    }, false)
                );
            } else {
                return driver.isCoDriver;
            }
        });

        var assignmentDisabled =
            (assignmentType === AssignmentTypes.CO_DRIVER && !tourDate.driver) || tourDate.updatePending;

        var driverOptions = [];
        drivers.forEach((driver) => {
            let drivingTourDate = TourDateStore.tourDatesByDriver.get(
                driver.id + "-" + tourDate.calendarDate.format("YYYY-MM-DD")
            );
            let coDrivingTourDate = TourDateStore.tourDatesByCoDriver.get(
                driver.id + "-" + tourDate.calendarDate.format("YYYY-MM-DD")
            );
            let driverAbsence = this.props.driverAbsenceForPeriod.get(
                driver.id + "-" + tourDate.calendarDate.format("YYYY-MM-DD")
            );
            let reserveDriverAvailable =
                drivingTourDate || coDrivingTourDate
                    ? this.props.reserveDrivers.reduce((reserveDriverAvailable, reserveDriver) => {
                          // in case we replace driver with one form iteration, is reserve driver going to be available to fill in?
                          let affectedTourDate = drivingTourDate || coDrivingTourDate;
                          return (
                              reserveDriverAvailable ||
                              (reserveDriver.isDriver &&
                                  !reserveDriver.isCoDriver &&
                                  reserveDriver.knownTours.reduce((knowsTour, knownTour) => {
                                      return knowsTour || knownTour.id === affectedTourDate.tour.id;
                                  }, false))
                          );
                      }, false)
                    : null;
            let assignButton = null;
            let updatePending =
                tourDate.updatePending && this.props.updatingDriver && this.props.updatingDriver.id === driver.id;

            // driver is assigned to this tourDate
            if (
                (drivingTourDate && tourDate.driver && drivingTourDate.driver.id === tourDate.driver.id) ||
                (coDrivingTourDate && tourDate.coDriver && coDrivingTourDate.coDriver.id === tourDate.coDriver.id)
            ) {
                assignButton = (
                    <Button width="3" shape="rounded" disabled={true} updatePending={updatePending}>
                        Aktuell&nbsp;
                        {coDrivingTourDate ? " Mitfahrer" : ""}
                    </Button>
                );

                // driver is absent
            } else if (driverAbsence) {
                assignButton = (
                    <Button width="3" shape="rounded" disabled={true} onClick={null}>
                        Abwesend
                    </Button>
                );

                // driver is not employed on date
            } else if (driver.employmentEnd && tourDate.calendarDate.isAfter(driver.employmentEnd.endOf("day"))) {
                assignButton = (
                    <Button width="3" shape="rounded" disabled={true} onClick={null}>
                        {translate("driver.absence.former_employee")}
                    </Button>
                );

                // driver is assigned to other tourDate
            } else if (drivingTourDate) {
                assignButton = (
                    <Button
                        actionType="warning"
                        width="3"
                        shape="rounded"
                        disabled={assignmentDisabled}
                        updatePending={updatePending}
                        onClick={this.props.reassignTourDateDriver.bind(
                            this,
                            driver,
                            assignmentType,
                            drivingTourDate,
                            AssignmentTypes.DRIVER
                        )}
                    >
                        Verschieben &nbsp;{!reserveDriverAvailable ? <i className="ui-icon icon-warning" /> : null}
                    </Button>
                );

                // driver is assigned to other tourDate as co-driver
            } else if (coDrivingTourDate) {
                assignButton = (
                    <Button
                        actionType="warning"
                        width="3"
                        shape="rounded"
                        disabled={assignmentDisabled}
                        updatePending={updatePending}
                        onClick={this.props.reassignTourDateDriver.bind(
                            this,
                            driver,
                            assignmentType,
                            coDrivingTourDate,
                            AssignmentTypes.CO_DRIVER
                        )}
                    >
                        Verschieben&nbsp;
                        {coDrivingTourDate ? " Mitfahrer" : ""}
                        &nbsp;{!reserveDriverAvailable ? <i className="ui-icon icon-warning" /> : null}
                    </Button>
                );

                // driver has a free day
            } else if (!driver.weekDays[moment(tourDate.calendarDate).isoWeekday()]) {
                assignButton = (
                    <Button
                        actionType="negative"
                        width="3"
                        shape="rounded"
                        disabled={assignmentDisabled}
                        updatePending={updatePending}
                        onClick={this.props.assignTourDateDriver.bind(this, driver, assignmentType)}
                    >
                        Verschieben&nbsp;
                    </Button>
                );

                // driver is available
            } else {
                assignButton = (
                    <Button
                        actionType="positive"
                        width="3"
                        shape="rounded"
                        disabled={assignmentDisabled}
                        updatePending={updatePending}
                        onClick={this.props.assignTourDateDriver.bind(this, driver, assignmentType)}
                    >
                        Zuteilen
                    </Button>
                );
            }

            let driverTags = [];
            if (driverAbsence) {
                driverTags.push(
                    <span
                        key={driverAbsence.absenceType}
                        className={"driver-tag " + driverAbsence.absenceType.replace("_", "-")}
                    >
                        {translate("driver.absence." + driverAbsence.absenceType)}
                    </span>
                );
            }
            if (driver.isFlexible) {
                driverTags.push(
                    <span key="flexible" className="driver-tag flexible">
                        {translate("driver.flexible")}
                    </span>
                );
            }
            if (!driver.weekDays[moment(tourDate.calendarDate).isoWeekday()]) {
                driverTags.push(
                    <span key="free" className="driver-tag free">
                        {translate("driver.free")}
                    </span>
                );
            }

            driverOptions.push(
                <tr key={driver.id}>
                    <td className="name">
                        {driver.nickname}
                        {driverTags}
                    </td>
                    <td>
                        {drivingTourDate ? drivingTourDate.tour.tourName : ""}
                        {coDrivingTourDate ? coDrivingTourDate.tour.tourName : ""}
                    </td>
                    <td className="action">{assignButton}</td>
                </tr>
            );
        });

        var removeAssignmentDisabled =
            (assignmentType === AssignmentTypes.DRIVER && !tourDate.driver) ||
            (assignmentType === AssignmentTypes.CO_DRIVER && !tourDate.coDriver) ||
            assignmentDisabled;
        var assignedDriver = assignmentType === AssignmentTypes.DRIVER ? tourDate.driver : tourDate.coDriver;

        return (
            <div className="tour-date-driver-select">
                <label>{assignmentType === AssignmentTypes.DRIVER ? "Fahrer" : "Mitfahrer"}</label>
                <span className="name">{assignedDriver ? assignedDriver.nickname : "-"}</span>
                <Button onClick={this._toggleDriverSelection} shape="rounded" size="icon-large">
                    {!this.state.isDriverSelectionVisible ? (
                        <i className="ui-icon icon-edit" />
                    ) : (
                        <i className="ui-icon icon-hide" />
                    )}
                </Button>

                <div
                    className="driver-selection"
                    style={this.state.isDriverSelectionVisible ? null : { display: "none" }}
                >
                    {driverOptions.length === 0 ? (
                        <FlashBar isVisible={true} type="warning">
                            Keine Fahrer verfügbar
                        </FlashBar>
                    ) : (
                        <div>
                            <FlashBar
                                isVisible={assignmentType === AssignmentTypes.CO_DRIVER && !tourDate.driver}
                                type="warning"
                            >
                                Bitte, den Fahrer zuerst zuteilen
                            </FlashBar>
                            <table className="driver-table">
                                <thead>
                                    <tr>
                                        <th>Fahrer</th>
                                        <th className="scheduled-tour">Geplante Tour</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>{driverOptions}</tbody>
                            </table>
                        </div>
                    )}
                    <div className="clear-selection">
                        <Button
                            size="small"
                            isSecondary={true}
                            disabled={removeAssignmentDisabled}
                            onClick={this.props.removeTourDateDriver.bind(null, assignmentType)}
                        >
                            Zuteilung löschen
                            <i className="ui-icon icon-remove" />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Toggle driver selection
     */
    _toggleDriverSelection = () => {
        this.setState({
            isDriverSelectionVisible: !this.state.isDriverSelectionVisible,
        });
    };
}

/**
 * ReserveDriversList component
 */
class ReserveDriversList extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        reserveDrivers: ImmutablePropTypes.mapOf(PropTypes.instanceOf(Driver)),
    };

    /**
     * React: render
     */
    render() {
        var driverOptions = [];
        this.props.reserveDrivers.forEach((driver) => {
            var knownTours = driver.knownTours.map((tour) => {
                return tour.tourName;
            });

            driverOptions.push(
                <tr key={driver.id}>
                    <td className="name">{driver.nickname}</td>
                    <td>{knownTours.join(", ")}</td>
                </tr>
            );
        });

        return (
            <div className="tour-date-driver-select">
                <label>Fahrer in Reserve</label>
                <div className="driver-selection">
                    {driverOptions.length === 0 ? (
                        <FlashBar isVisible={true} type="warning">
                            Keine Fahrer verfügbar
                        </FlashBar>
                    ) : (
                        <table className="driver-table">
                            <thead>
                                <tr>
                                    <th>Fahrer</th>
                                    <th className="scheduled-tour">Touren</th>
                                </tr>
                            </thead>
                            <tbody>{driverOptions}</tbody>
                        </table>
                    )}
                </div>
            </div>
        );
    }
}
