"use strict";

/**
 * Location names
 *
 * @providesModule ReactPropTypeLocationNames
 */
export var ReactPropTypeLocationNames = {
    prop: "prop",
    context: "context",
    childContext: "child context",
};

/**
 * Creates chainable type checker from validate function, allowing chaining with isRequired call
 *
 * @param validate
 * @returns {function(this:null)}
 */
export function createChainable(validate) {
    function checkType(isRequired, props, propName, componentName, location) {
        componentName = componentName || "ANONYMOUS";

        if (props[propName] == null) {
            var locationName = ReactPropTypeLocationNames[location];

            if (isRequired) {
                return new Error(
                    "Required " +
                        locationName +
                        " `" +
                        propName +
                        "` was not specified in " +
                        ("`" + componentName + "`.")
                );
            }

            return null;
        } else {
            return validate(props, propName, componentName, location);
        }
    }

    let chainedCheckType = checkType.bind(null, false);
    chainedCheckType.isRequired = checkType.bind(null, true);

    return chainedCheckType;
}
