"use strict";

import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import moment from "moment";
import * as InternalPropTypes from "../types/PropTypes";
import Tour from "../types/Tour";
import TourDate from "../types/TourDate";
import CalendarEvent from "../types/CalendarEvent";
import TourDateActions from "../actions/TourDateActions";
import TourDateStore from "../stores/TourDateStore";
import DriverAbsenceTypes from "../constants/DriverAbsenceTypes";
import TourDateStatus from "../constants/TourDateStatus";
import ElementUpdatePending from "../ui/elements/ElementUpdatePending.react";
import DropDown from "../ui/elements/DropDown.react";

/**
 * TourDateTourCell component
 */
export default class TourDateTourCell extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        tour: PropTypes.instanceOf(Tour),
        tourDate: PropTypes.instanceOf(TourDate),
        weekDay: InternalPropTypes.moment,
        calendarEventsForPeriod: ImmutablePropTypes.mapOf(PropTypes.instanceOf(CalendarEvent)),
        toggleTourDateModal: PropTypes.func.isRequired,
    };

    /**
     * React: state
     */
    state = {
        isDropDownVisible: false,
        tourDate: this.props.tourDate,
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        if (this.state.tourDate) {
            TourDateStore.addListener(TourDateStore.ENTITY_UPDATED(this.state.tourDate.id), this._setTourDate);
        } else {
            TourDateStore.addListener(
                TourDateStore.TOUR_DATE_CREATED(this.props.tour.id, this.props.weekDay.format("YYYY-MM-DD")),
                this._initTourDate
            );
        }
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        if (this.state.tourDate) {
            TourDateStore.removeListener(TourDateStore.ENTITY_UPDATED(this.state.tourDate.id), this._setTourDate);
        }
    }

    /**
     * React: componentWillUpdate
     *
     * @param nextProps
     * @param nextState
     */
    componentWillUpdate(nextProps, nextState) {
        // add regular tourDate listener if tour date is created
        if (
            typeof nextState.tourDate !== "undefined" &&
            nextState.tourDate.id !== null &&
            this.state.tourDate.id === null
        ) {
            TourDateStore.addListener(TourDateStore.ENTITY_UPDATED(nextState.tourDate.id), this._setTourDate);
            TourDateStore.removeListener(
                TourDateStore.TOUR_DATE_CREATED(this.props.tour.id, this.props.weekDay.format("YYYY-MM-DD")),
                this._initTourDate
            );
        }
    }

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.tourDate !== this.state.tourDate ||
            nextState.tourDate !== this.state.tourDate ||
            nextState.isDropDownVisible !== this.state.isDropDownVisible
        );
    }

    /**
     * React: render
     */
    render() {
        var tourDate = this.state.tourDate;

        // No tour date for day
        if (typeof tourDate === "undefined") {
            return (
                <td className="tour-date undefined" onClick={this._toggleDropDown}>
                    Unbekant
                    <DropDown
                        isVisible={this.state.isDropDownVisible}
                        onClose={this._closeDropDown}
                        closeOnMouseLeave={true}
                    >
                        <DropDown.Option onClick={this._createTourDate.bind(null, this.props.tour, this.props.weekDay)}>
                            Datum aktivieren
                        </DropDown.Option>
                    </DropDown>
                </td>
            );

            // Tour date exists but update is pending
        } else if (tourDate.updatePending) {
            return (
                <td className="tour-date update-pending">
                    <ElementUpdatePending isVisible={true} type="bars-3" />
                </td>
            );

            // Tour date exists and is stable
        } else {
            let tour = this.props.tour;
            let weekDay = this.props.weekDay;
            let calendarEvent = this.props.calendarEventsForPeriod.get(weekDay.format("YYYY-MM-DD"));
            let driverSet = tourDate.driver != null;
            let coDriverSet = tourDate.coDriver != null;
            let className = "tour-date " + tourDate.status;

            return (
                <td className={className} onClick={this._toggleDropDown}>
                    {tourDate.status === TourDateStatus.OPEN ? <i className="ui-icon icon-warning" /> : null}
                    {tourDate.status === TourDateStatus.OPEN ? "Offen" : null}
                    {tourDate.status === TourDateStatus.INACTIVE ? "Keine Fahrt" : null}
                    {tourDate.status === TourDateStatus.CANCELED ? "Deaktiviert" : null}

                    {!driverSet ? null : tourDate.driver.nickname}
                    <br />
                    {!coDriverSet ? null : tourDate.coDriver.nickname}

                    <DropDown
                        isVisible={this.state.isDropDownVisible}
                        onClose={this._closeDropDown}
                        closeOnMouseLeave={true}
                    >
                        <DropDown.Option onClick={this.props.toggleTourDateModal.bind(null, tourDate)}>
                            Fahrer Zuteilen
                        </DropDown.Option>
                        {tourDate.status === TourDateStatus.ACTIVE || tourDate.status === TourDateStatus.OPEN ? (
                            <DropDown.Option
                                onClick={this._updateTourDateStatus.bind(
                                    null,
                                    tourDate,
                                    !tour.weekDays[moment(tourDate.calendarDate).isoWeekday()] ||
                                        typeof calendarEvent !== "undefined"
                                        ? TourDateStatus.INACTIVE
                                        : TourDateStatus.CANCELED
                                )}
                            >
                                Tour deaktivieren
                            </DropDown.Option>
                        ) : null}
                        <DropDown.Break />
                        <DropDown.Option
                            onClick={this._updateTourDateDriverStatus.bind(
                                null,
                                tourDate,
                                DriverAbsenceTypes.SET_DRIVER_SICK
                            )}
                            disabled={!driverSet}
                        >
                            Fahrer Krank
                        </DropDown.Option>
                        <DropDown.Option
                            onClick={this._updateTourDateDriverStatus.bind(
                                null,
                                tourDate,
                                DriverAbsenceTypes.SET_DRIVER_ACCIDENT
                            )}
                            disabled={!driverSet}
                        >
                            Fahrer Unfall
                        </DropDown.Option>
                        <DropDown.Break />
                        <DropDown.Option
                            onClick={this._updateTourDateDriverStatus.bind(
                                null,
                                tourDate,
                                DriverAbsenceTypes.SET_CO_DRIVER_SICK
                            )}
                            disabled={!coDriverSet}
                        >
                            Mitfahrer Krank
                        </DropDown.Option>
                        <DropDown.Option
                            onClick={this._updateTourDateDriverStatus.bind(
                                null,
                                tourDate,
                                DriverAbsenceTypes.SET_CO_DRIVER_ACCIDENT
                            )}
                            disabled={!coDriverSet}
                        >
                            Mitfahrer Unfall
                        </DropDown.Option>
                    </DropDown>
                </td>
            );
        }
    }

    /**
     * Init tour date
     */
    _initTourDate = () => {
        this.setState({
            tourDate: TourDateStore.tourDatesByTour.get(
                this.props.tour.id + "-" + this.props.weekDay.format("YYYY-MM-DD")
            ),
        });
    };

    /**
     * Set tour date
     */
    _setTourDate = () => {
        this.setState({
            tourDate: TourDateStore.tourDateCollection.get(this.state.tourDate.id),
        });
    };

    /**
     * Create tour date
     *
     * @params {Tour} tour
     * @params {Object} weekDay
     */
    _createTourDate = (tour, weekDay) => {
        TourDateActions.createTourDate({
            tourId: tour.id,
            calendarDate: weekDay.format("YYYY-MM-DD"),
            status: TourDateStatus.OPEN,
        });
    };

    /**
     * Update tour date status
     *
     * @params {TourDate} tourDate
     * @params {string} status
     */
    _updateTourDateStatus = (tourDate, status) => {
        TourDateActions.updateTourDate({
            tourDateId: tourDate.id,
            status: status,
        });
    };

    /**
     * Update tour date driver status
     *
     * @params {TourDate} tourDate
     * @params {string} driverStatus
     */
    _updateTourDateDriverStatus = (tourDate, driverStatus) => {
        var params = {};
        switch (driverStatus) {
            case DriverAbsenceTypes.SET_DRIVER_SICK:
                params = { driverAbsenceType: DriverAbsenceTypes.SICK_LEAVE };
                break;

            case DriverAbsenceTypes.SET_DRIVER_ACCIDENT:
                params = { driverAbsenceType: DriverAbsenceTypes.ACCIDENT };
                break;

            case DriverAbsenceTypes.SET_CO_DRIVER_SICK:
                params = { coDriverAbsenceType: DriverAbsenceTypes.SICK_LEAVE };
                break;

            case DriverAbsenceTypes.SET_CO_DRIVER_ACCIDENT:
                params = { coDriverAbsenceType: DriverAbsenceTypes.ACCIDENT };
                break;
        }

        TourDateActions.updateTourDateDriver({
            ...params,
            tourDateId: tourDate.id,
        });
    };

    /**
     * Toggle drop-down
     */
    _toggleDropDown = (event) => {
        if (event.target.tagName !== "LI") {
            this.setState({
                isDropDownVisible: !this.state.isDropDownVisible,
            });
        }
    };

    /**
     * Close drop-down
     */
    _closeDropDown = () => {
        this.setState({
            isDropDownVisible: false,
        });
    };
}
