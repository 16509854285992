"use strict";

import ActionTypes from "../constants/ActionTypes";
import BaseStore from "./BaseStore";

/**
 * Flux: RouterStore
 */
class RouterStore extends BaseStore {
    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));
        this._nextRouterPath = null;
    }

    /**
     * Flux: register store to actions
     *
     * @param action
     * @private
     */
    _registerToActions(action) {
        switch (action.type) {
            case ActionTypes.STORE_ROUTER_TRANSITION_PATH:
                this._nextRouterPath = action.path;
                break;

            default:
                break;
        }
    }

    /**
     * Get nextTransitionPath
     * value can only be retrieved once
     *
     * @return {null|*}
     */
    get nextTransitionPath() {
        let nextPath = this._nextRouterPath;
        this._nextRouterPath = null;
        return nextPath;
    }
}

export default new RouterStore();
