"use strict";

import React from "react";
import BaseForm from "../ui/BaseForm";
import { translate } from "../i18n/Translate";

/**
 * DriverForm class
 */
export default class DriverForm extends BaseForm {
    /**
     * @inheritdoc
     */
    buildForm(builder) {
        builder.add("lastName", "text", {
            label: "Vorname",
        });
        builder.add("firstName", "text", {
            label: "Name",
        });
        builder.add("nickname", "text", {
            label: "Rufname",
        });
        builder.add("employmentStart", "dateTime", {
            label: "Eintritt",
            dateFormat: "DD.MM.YYYY",
        });
        builder.add("employmentEnd", "dateTime", {
            label: "Austritt",
            dateFormat: "DD.MM.YYYY",
        });
        builder.add("isDriver", "checkbox", {
            label: "Fahrer",
            alignLabel: true,
        });
        builder.add("isCoDriver", "checkbox", {
            label: "Mitfahrer",
            alignLabel: true,
        });
        builder.add("isPacker", "checkbox", {
            label: "Packer",
            alignLabel: true,
        });
        builder.add("reservedTour", "entity", {
            className: "Tour",
            placeholder: "Keine",
            choiceLabel: "tourName",
            label: "Priorität Tour",
        });
        builder.add("driverGroup", "entity", {
            className: "DriverGroup",
            placeholder: "Keine Gruppe",
            choiceLabel: "groupName",
            label: "Gruppe",
        });
        builder.add("weekDays", "weekDayChoice", {
            label: "Wochentage",
            width: 6,
        });
        builder.add("isFlexible", "checkbox", {
            label: "Flexfahrer",
            alignLabel: true,
        });
        builder.add("knownTours", "entity", {
            className: "Tour",
            choiceLabel: (tours, key) => {
                let tour = tours[key];
                return (
                    <span>
                        {tour.tourName}
                        {!tour.hasShifts ? null : <sup>&nbsp;am/pm</sup>}
                    </span>
                );
            },
            label: "Touren",
            multiple: true,
            expanded: true,
            autoSelect: true,
            width: 6,
        });
        builder.add("absence", "collectionOutput", {
            outputType: "table",
            tableColumns: [
                {
                    headerLabel: "Datum",
                    cellLabel: (driverAbsence) => {
                        return (
                            driverAbsence.startDate.format("DD.MM.YYYY") +
                            (!driverAbsence.startDate.isSame(driverAbsence.endDate, "day")
                                ? " - " + driverAbsence.endDate.format("DD.MM.YYYY")
                                : "")
                        );
                    },
                },
                {
                    headerLabel: "Absenztyp",
                    cellLabel: (driverAbsence) => {
                        return translate("driver.absence." + driverAbsence.absenceType);
                    },
                },
            ],
            label: "Absenzen",
            width: 6,
            height: 2,
        });
    }
}
