"use strict";

import React from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox.react";
import Radio from "./Radio.react";
import Button from "./Button.react";
import ElementUpdatePending from "./ElementUpdatePending.react";

/**
 * Choice component
 */
export default class Choice extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        choices: PropTypes.oneOfType([
            PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])),
            PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])),
        ]).isRequired,
        choiceLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        valueReference: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        placeholder: PropTypes.string,
        expanded: PropTypes.bool,
        multiple: PropTypes.bool,
        autoSelect: PropTypes.bool,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])),
        ]),
        inline: PropTypes.bool,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        updatePending: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        choices: [],
        expanded: false,
        multiple: false,
        value: null,
        updatePending: false,
        disabled: false,
        inline: false,
        width: 6,
        height: 3,
    };

    /**
     * React: state
     */
    state = {
        choices: null,
    };

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this.state.choices = this._parseChoices(this.props.choices, this.props.value);
    }

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        this.setState({
            choices: this._parseChoices(nextProps.choices, nextProps.value),
        });
    }

    /**
     * React: render
     */
    render() {
        var choices = this.state.choices;
        var choiceContent = null;
        var allChoicesSelected = true;

        // checkbox list
        if (this.props.expanded && this.props.multiple) {
            choiceContent = [];
            Object.keys(choices).forEach((key) => {
                let choice = choices[key];
                choiceContent.push(
                    <Checkbox
                        key={key}
                        value={choice.selected}
                        label={choice.label}
                        disabled={this.props.disabled}
                        inline={this.props.inline}
                        onChange={this._onChange.bind(null, key)}
                    />
                );

                if (!choice.selected) {
                    allChoicesSelected = false;
                }
            });

            // radio list
        } else if (this.props.expanded && !this.props.multiple) {
            choiceContent = [];
            Object.keys(choices).forEach((key) => {
                let choice = choices[key];
                choiceContent.push(
                    <Radio
                        key={key}
                        value={choice.selected}
                        label={choice.label}
                        disabled={this.props.disabled}
                        inline={this.props.inline}
                        onChange={this._onChange.bind(this, key)}
                    />
                );
            });

            // select
        } else if (!this.props.expanded && !this.props.multiple) {
            var selectOptions = [];
            var selectValue = null;

            Object.keys(choices).forEach((key) => {
                let choice = choices[key];
                selectOptions.push(
                    <option key={key} value={key}>
                        {choice.label}
                    </option>
                );

                selectValue = choice.selected ? key : selectValue;
            });

            let className = "" + (typeof this.props.width !== "undefined" ? " ui-col-" + this.props.width : "");
            choiceContent = (
                <select
                    className={className}
                    value={selectValue || ""}
                    disabled={this.props.disabled}
                    onChange={(event) => {
                        this._onChange(event.target.value, true);
                    }}
                >
                    {selectOptions}
                </select>
            );
        }

        // render expanded multiple choice with auto-select option
        if (this.props.expanded && this.props.multiple && this.props.autoSelect) {
            let className =
                "ui-choice auto-select" +
                (typeof this.props.width !== "undefined" ? " ui-col-" + this.props.width : "");
            let choicesClassName =
                "choices" + (typeof this.props.height !== "undefined" ? " ui-row-" + this.props.height : "");
            return (
                <div className={className}>
                    {this.props.updatePending ? null : (
                        <Button
                            shape="link"
                            disabled={this.props.disabled}
                            onClick={this._selectAllChoices.bind(this, !allChoicesSelected)}
                        >
                            {allChoicesSelected ? "Auswahl Löschen" : "Alle Auswählen"}
                        </Button>
                    )}
                    <ElementUpdatePending isVisible={this.props.updatePending} type="bars-3" />
                    <div className={choicesClassName}>{choiceContent}</div>
                </div>
            );

            // render all other choice types
        } else {
            return (
                <div className="ui-choice">
                    {choiceContent}
                    <ElementUpdatePending isVisible={this.props.updatePending} type="bars-3" />
                </div>
            );
        }
    }

    /**
     * Parse choices
     */
    _parseChoices = (choices, value) => {
        var parsedValue = [];
        var valueReference = this.props.valueReference;
        if (Array.isArray(value)) {
            value.forEach((value) => {
                if (typeof value === "object" && typeof valueReference === "function") {
                    parsedValue.push(valueReference(value));
                } else if (typeof value === "object" && typeof valueReference === "string") {
                    parsedValue.push(value[valueReference]);
                } else {
                    parsedValue.push(value);
                }
            });
        } else {
            if (typeof value === "object" && typeof valueReference === "function") {
                parsedValue = valueReference(value);
            } else if (typeof value === "object" && typeof valueReference === "string") {
                parsedValue = value ? value[valueReference] : null;
            } else {
                parsedValue = value;
            }
        }

        var returnChoices = {};
        if (this.props.placeholder) {
            returnChoices["nullPlaceholder"] = {
                label: this.props.placeholder,
                selected: parsedValue == null,
                value: null,
            };
        }
        Object.keys(choices).forEach((key) => {
            returnChoices[key] = {
                label: this._getChoiceLabel(choices, key),
                selected: this._isChoicePreSelected(choices, key, parsedValue),
                value: choices[key],
            };
        });

        return returnChoices;
    };

    /**
     * Is choice pre-selected?
     */
    _isChoicePreSelected = (choices, key, parsedValue) => {
        var choiceValue = null;
        var valueReference = this.props.valueReference;

        if (typeof valueReference === "function") {
            choiceValue = valueReference(choices[key]);
        } else if (typeof valueReference === "string") {
            choiceValue = choices[key][valueReference];
        } else {
            choiceValue = choices[key];
        }

        if (this.props.multiple && Array.isArray(parsedValue)) {
            return parsedValue.indexOf(choiceValue) > -1;
        } else {
            return parsedValue === choiceValue;
        }
    };

    /**
     * Get choice label
     */
    _getChoiceLabel = (choices, key) => {
        var choiceLabel = this.props.choiceLabel;
        if (typeof choiceLabel === "function") {
            return choiceLabel(choices, key);
        } else if (typeof choiceLabel === "string") {
            return choices[key][choiceLabel];
        } else if (typeof choices[key] === "string" || typeof choices[key] === "number") {
            return choices[key];
        } else {
            return key;
        }
    };

    /**
     * On change
     */
    _onChange = (changedKey, isSelected) => {
        this.setState(
            {
                choices: this._updateChoices(changedKey, isSelected),
                value: this._getValue(),
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this._getValue());
                }
            }
        );
    };

    /**
     * Update choices
     */
    _updateChoices = (changedKey, isSelected) => {
        var choices = {};
        Object.keys(this.state.choices).forEach((key) => {
            let selected = !this.props.multiple
                ? changedKey === key
                    ? isSelected
                    : false
                : changedKey === key
                ? isSelected
                : this.state.choices[key].selected;

            choices[key] = {
                label: this.state.choices[key].label,
                selected: selected,
                value: this.state.choices[key].value,
            };
        });

        return choices;
    };

    /**
     * Select all choices
     * @param {bool} selected
     */
    _selectAllChoices = (selected) => {
        var choices = {};
        Object.keys(this.state.choices).forEach((key) => {
            choices[key] = {
                label: this.state.choices[key].label,
                selected: selected,
                value: this.state.choices[key].value,
            };
        });

        this.setState(
            {
                choices: choices,
                value: this._getValue(),
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this._getValue());
                }
            }
        );
    };

    /**
     * Get value
     */
    _getValue = () => {
        let value = null;
        if (!this.props.multiple) {
            Object.keys(this.state.choices).forEach((key) => {
                if (this.state.choices[key].selected) {
                    value = this.state.choices[key].value;
                }
            });
        } else {
            value = [];
            Object.keys(this.state.choices).forEach((key) => {
                if (this.state.choices[key].selected) {
                    value.push(this.state.choices[key].value);
                }
            });
        }

        return value;
    };
}
