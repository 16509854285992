"use strict";

import moment from "moment";
import Immutable from "immutable";
import Tour from "../types/Tour";
import ErpReference from "../types/ErpReference";
import ActionTypes from "../constants/ActionTypes";
import EntityStore from "./EntityStore";

/**
 * Flux: TourStore
 */
class TourStore extends EntityStore {
    /**
     * tourCollection hash
     *
     * @type {Immutable.OrderedMap<string, Tour>}
     * @private
     */
    _tourCollection = Immutable.OrderedMap();

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));

        this._error = null;
    }

    /**
     * Flux: register store to actions
     *
     * @param {*} action
     * @private
     */
    _registerToActions(action) {
        switch (action.type) {
            // get tourCollection
            case ActionTypes.REQUEST_GET_TOUR_COLLECTION:
                this._error = null;
                break;

            case ActionTypes.REQUEST_GET_TOUR_COLLECTION_SUCCESS:
                this._setTourCollection(action.body.tours);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_GET_TOUR_COLLECTION_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            // create tour
            case ActionTypes.REQUEST_CREATE_TOUR:
                this._error = null;
                break;

            case ActionTypes.REQUEST_CREATE_TOUR_SUCCESS:
                this._setTour(action.body.tour);
                this._sortTourCollectionBy("erpReference");
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_CREATE_TOUR_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            // get tour
            case ActionTypes.REQUEST_GET_TOUR:
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.tourId));
                break;

            case ActionTypes.REQUEST_GET_TOUR_SUCCESS:
                this._setTour(action.body.tour);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.body.tour.id));
                break;

            case ActionTypes.REQUEST_GET_TOUR_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.tourId));
                break;

            // update tour
            case ActionTypes.REQUEST_UPDATE_TOUR:
                this._toggleUpdatePending(action.tour.id);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.tour.id));
                break;

            case ActionTypes.REQUEST_UPDATE_TOUR_SUCCESS:
                this._setTour(action.body.tour);
                this._sortTourCollectionBy("erpReference");
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.body.tour.id));
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_UPDATE_TOUR_ERROR:
                this._toggleUpdatePending(action.tour.id);
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.tour.id));
                break;

            // remove tour
            case ActionTypes.REQUEST_REMOVE_TOUR:
                this._toggleUpdatePending(action.tourId);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.tourId));
                break;

            case ActionTypes.REQUEST_REMOVE_TOUR_SUCCESS:
                this._toggleUpdatePending(action.tourId);
                this._removeTour(action.tourId);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_REMOVE_TOUR_ERROR:
                this._toggleUpdatePending(action.tourId);
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.tourId));
                break;

            default:
                break;
        }
    }

    /**
     * Set _tourCollection
     *
     * @param {Array.<Tour>} responseTours
     * @private
     */
    _setTourCollection(responseTours) {
        responseTours.map((responseTour) => {
            this._setTour(responseTour);
        });
        this._sortTourCollectionBy("erpReference");
    }

    /**
     * Set _tourCollection[key]
     *
     * @params {Tour} responseTour
     * @private
     */
    _setTour(responseTour) {
        var orderDeadline = null;
        if (typeof responseTour.orderDeadline === "object") {
            orderDeadline = {};
            Object.keys(responseTour.orderDeadline).forEach((weekDay) => {
                var deadline = responseTour.orderDeadline[weekDay];
                orderDeadline[weekDay] = {
                    daysBefore: deadline.daysBefore,
                    time: deadline.time === "00:00:00" ? moment.duration(24, "hours") : moment.duration(deadline.time),
                };
            });
        }
        var tour = new Tour({
            ...responseTour,
            orderDeadline: orderDeadline,
            erpReferences: responseTour.erpReferences
                ? responseTour.erpReferences.map((responseErpReference) => {
                      return new ErpReference(responseErpReference);
                  })
                : null,
        });
        this._tourCollection = this._tourCollection.set(responseTour.id, tour);
    }

    /**
     * SortBy _tourCollection
     * @param {string} key
     * @param {bool} ascending
     * @private
     */
    _sortTourCollectionBy(key, ascending = true) {
        this._tourCollection = this._tourCollection.sort((itemA, itemB) => {
            return itemA[key] === itemB[key]
                ? 0
                : ascending
                ? itemA[key] > itemB[key]
                    ? 1
                    : -1
                : itemA[key] < itemB[key]
                ? 1
                : -1;
        });
    }

    /**
     * Remove _tourCollection[key]
     *
     * @params {number} tourId
     * @private
     */
    _removeTour(tourId) {
        this._tourCollection = this._tourCollection.remove(tourId);
    }

    /**
     * Set updatePending flag on tour
     *
     * @param {number} tourId
     * @private
     */
    _toggleUpdatePending(tourId) {
        var tour = this._tourCollection.get(tourId);
        tour = tour.set("updatePending", !tour.get("updatePending"));
        this._tourCollection = this._tourCollection.set(tourId, tour);
    }

    /**
     * Get _tourCollection
     *
     * @returns {Immutable.OrderedMap<string, Tour>}
     */
    get tourCollection() {
        return this._tourCollection;
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new TourStore();
