"use strict";

import Immutable from "immutable";

/**
 * ErpReference type
 *
 * @typedef {(Immutable.Record|Object)} ErpReference
 * @property {number} reference
 * @property {bool} updatePending
 */

/**
 * Tour record
 *
 * @type {Tour}
 */
var ErpReference = Immutable.Record({
    id: null,
    reference: null,
    updatePending: false,
});

export default ErpReference;
