"use strict";

import Immutable from "immutable";

/**
 * CalendarEvent type
 *
 * @typedef {(Immutable.Record|Object)} CalendarEvent
 * @property {number} id
 * @property {string} eventName
 * @property {Object} eventDate
 * @property {Array.<Tour>} calendarTours
 * @property {bool} updatePending
 */

/**
 * CalendarEvent record
 *
 * @type {CalendarEvent}
 */
var CalendarEvent = Immutable.Record({
    id: null,
    eventName: "",
    eventDate: null,
    canceledTours: [],
    updatePending: false,
});

export default CalendarEvent;
