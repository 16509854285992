"use strict";

import BaseForm from "../ui/BaseForm";

/**
 * DriverGroupForm class
 */
export default class DriverGroupForm extends BaseForm {
    /**
     * @inheritdoc
     */
    buildForm(builder) {
        builder.add("groupName", "text", {
            width: 6,
        });
    }
}
