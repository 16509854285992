"use strict";

import BaseForm from "../ui/BaseForm";

/**
 * ScheduleForm class
 */
export default class ScheduleForm extends BaseForm {
    /**
     * @inheritdoc
     */
    buildForm(builder) {
        builder.add("startDate", "dateTime", {
            label: "Anfangsdatum",
            dateFormat: "DD.MM.YYYY",
        });
        builder.add("endDate", "dateTime", {
            label: "Enddatum",
            dateFormat: "DD.MM.YYYY",
        });
        builder.add("useDriverGroup", "checkbox", {
            label: "Gruppen",
            value: false,
            alignLabel: true,
        });
        builder.add("useRotation", "checkbox", {
            label: "Schalter",
            value: false,
            alignLabel: true,
        });
        builder.add("useReservations", "checkbox", {
            label: "Priorität",
            value: false,
            alignLabel: true,
        });
    }
}
