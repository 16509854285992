"use strict";

import React from "react";

/**
 * Header component
 */
export default class Header extends React.Component {
    /**
     * React: render
     */
    render() {
        const logo = new URL("../../assets/images/logo.png", import.meta.url);
        return (
            <header className="header">
                <div className="logo">
                    <strong>
                        Bianchi
                        <br />
                        Tourenplaner
                    </strong>
                    <img src={logo} alt="Bianchi Tourenplaner" />
                </div>
            </header>
        );
    }
}
