"use strict";

import React from "react";
import PropTypes from "prop-types";
import FieldRecord from "../FieldRecord";
import { createFactory } from "../../lib/Utils";
import Checkbox from "./Checkbox.react";
import Choice from "./Choice.react";
import Collection from "./Collection.react";
import CollectionOutput from "./CollectionOutput.react";
import DateTime from "./DateTime.react";
import Deadline from "./Deadline.react";
import Entity from "./Entity.react";
import Hidden from "./Hidden.react";
import Number from "./Number.react";
import Password from "./Password.react";
import Radio from "./Radio.react";
import TagInput from "./TagInput.react";
import Text from "./Text.react";
import ErrorMessage from "./ErrorMessage.react";
import WeekDayChoice from "./WeekDayChoice.react";

/**
 * Field component
 */
export default class Field extends React.Component {
    /**
     * React: displayName
     * @type {string}
     */
    static displayName = "Field";

    /**
     * React: propTypes
     */
    static propTypes = {
        name: PropTypes.string,
        type: PropTypes.string,
        field: PropTypes.instanceOf(FieldRecord),
        disabled: PropTypes.bool,
        errors: PropTypes.array,
        onChange: PropTypes.func,
        onInput: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyUp: PropTypes.func,
        onKeyPress: PropTypes.func,
        showLabel: PropTypes.bool,
        labelWidth: PropTypes.node,
        widgetWidth: PropTypes.node,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        type: "text",
        showLabel: true,
        errors: null,
    };

    /**
     * React: state
     */
    state = {
        isErrorMessageVisible: false,
    };

    /**
     * Filed types (widgets)
     */
    _fieldTypes = {};

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this._fieldTypes = {
            checkbox: createFactory(Checkbox),
            choice: createFactory(Choice),
            collection: createFactory(Collection),
            collectionOutput: createFactory(CollectionOutput),
            dateTime: createFactory(DateTime),
            deadline: createFactory(Deadline),
            entity: createFactory(Entity),
            hidden: createFactory(Hidden),
            number: createFactory(Number),
            password: createFactory(Password),
            radio: createFactory(Radio),
            tagInput: createFactory(TagInput),
            text: createFactory(Text),
            weekDayChoice: createFactory(WeekDayChoice),
        };
    }

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.field !== this.props.field ||
            nextProps.disabled !== this.props.disabled ||
            nextState.isErrorMessageVisible !== this.state.isErrorMessageVisible
        );
    }

    /**
     * React: render
     */
    render() {
        /** @type {FieldRecord} */
        var field = this.props.field;

        // don't render if there is no field record
        if (!field) {
            return null;
        }

        var fieldOptions = field.options;
        var fieldLabel =
            this.props.type !== "checkbox" && this.props.type !== "radio"
                ? fieldOptions.label
                : fieldOptions.alignLabel
                ? fieldOptions.label
                : null;

        var widget = this._fieldTypes[this.props.type]({
            ...fieldOptions,
            label: fieldLabel !== null ? null : fieldOptions.label,
            disabled: this.props.disabled || fieldOptions.disabled,
            width: fieldOptions.width ? fieldOptions.width : this.props.widgetWidth,
            onChange: this._onChange,
            onInput: this._onInput,
            onKeyPress: this._onKeyPress,
            onKeyDown: this._onKeyDown,
            onKeyUp: this._onKeyUp,
            onFocus: this._toggleErrorMessage,
            onBlur: this._toggleErrorMessage,
        });

        if (this.props.type === "hidden") {
            return widget;
        } else {
            var className = "ui-field" + (this.props.errors !== null ? " error" : "");
            var labelClassName = this.props.labelWidth ? " ui-col-" + this.props.labelWidth : "";

            return (
                <div className={className}>
                    {!this.props.showLabel ? null : <label className={labelClassName}>{fieldLabel}</label>}

                    <div className="widget">
                        {widget}
                        {field.options.description === "" ? null : (
                            <span className="description">{field.options.description}</span>
                        )}
                        {this.props.errors !== null && this.state.isErrorMessageVisible ? (
                            <ErrorMessage>
                                {this.props.errors.map((error, index) => {
                                    return <div key={index}>{error}</div>;
                                })}
                            </ErrorMessage>
                        ) : null}
                    </div>
                </div>
            );
        }
    }

    /**
     * On field change
     */
    _onChange = (value) => {
        var field = this.props.field;

        // call widget callback
        if (typeof field.options.onChange === "function") {
            field.options.onChange(value);
        }

        // call field callback
        if (typeof this.props.onChange === "function") {
            this.props.onChange(field, value);
        }
    };

    /**
     * On field input
     */
    _onInput = (value) => {
        var field = this.props.field;

        // call widget callback
        if (typeof field.options.onInput === "function") {
            field.options.onInput(value);
        }

        // call field callback
        if (typeof this.props.onInput === "function") {
            this.props.onInput(field, value);
        }
    };

    /**
     * On field key down
     */
    _onKeyDown = (value, event) => {
        var field = this.props.field;

        // call widget callback
        if (typeof field.options.onKeyDown === "function") {
            field.options.onKeyDown(value, event);
        }

        // call field callback
        if (typeof this.props.onKeyDown === "function") {
            this.props.onKeyDown(field, value, event);
        }
    };

    /**
     * On field key up
     */
    _onKeyUp = (value, event) => {
        var field = this.props.field;

        // call widget callback
        if (typeof field.options.onKeyUp === "function") {
            field.options.onKeyUp(value, event);
        }

        // call field callback
        if (typeof this.props.onKeyUp === "function") {
            this.props.onKeyUp(field, value, event);
        }
    };

    /**
     * On field key press
     */
    _onKeyPress = (value, event) => {
        var field = this.props.field;

        // call widget callback
        if (typeof field.options.onKeyPress === "function") {
            field.options.onKeyPress(value, event);
        }

        // call field callback
        if (typeof this.props.onKeyPress === "function") {
            this.props.onKeyPress(field, value, event);
        }
    };

    /**
     * Toggle error message
     */
    _toggleErrorMessage = () => {
        if (!this.props.errors) return;

        this.setState({
            isErrorMessageVisible: !this.state.isErrorMessageVisible,
        });
    };
}
