"use strict";

import React from "react";
import PropTypes from "prop-types";
import * as InternalPropTypes from "../types/PropTypes";
import CalendarEvent from "../types/CalendarEvent";
import CalendarEventActions from "../actions/CalendarEventActions";
import CalendarEventStore from "../stores/CalendarEventStore";
import CalendarEventForm from "../forms/CalendarEventForm";
import Form from "../ui/elements/Form.react";
import Modal from "../ui/elements/Modal.react";
import LoadingAnimation from "../ui/elements/LoadingAnimation.react";

/**
 * CalendarEventModal component
 */
export default class CalendarEventModal extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        isVisible: PropTypes.bool,
        weekDay: InternalPropTypes.moment,
        calendarEvent: PropTypes.instanceOf(CalendarEvent),
        toggleCalendarEventModal: PropTypes.func.isRequired,
    };

    /**
     * React: state
     */
    state = {
        calendarEvent: null,
        updatePending: false,
        removePending: false,
    };

    /**
     * Form ref
     * @type {Object|null}
     * @private
     */
    _form = null;

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        // modal will be visible..
        if (nextProps.isVisible && this.state.calendarEvent === null) {
            // ..and showing existing calendarEvent
            if (nextProps.calendarEvent != null) {
                CalendarEventStore.addListener(
                    CalendarEventStore.ENTITY_UPDATED(nextProps.calendarEvent.id),
                    this._onCalendarEventLoad
                );
                this._getCalendarEvent(nextProps.calendarEvent.id);

                // ..and showing new calendarEvent
            } else {
                this.setState({ calendarEvent: new CalendarEvent({ eventDate: nextProps.weekDay }) });
            }
        }

        // modal will be hidden..
        if (!nextProps.isVisible) {
            // ..for existing calendarEvent
            if (this.props.calendarEvent !== null) {
                CalendarEventStore.removeListener(
                    CalendarEventStore.ENTITY_UPDATED(this.props.calendarEvent.id),
                    this._onCalendarEventLoad
                );
                CalendarEventStore.removeListener(
                    CalendarEventStore.ENTITY_UPDATED(this.props.calendarEvent.id),
                    this._onCalendarEventUpdate
                );
                CalendarEventStore.removeListener(
                    CalendarEventStore.ENTITY_UPDATED(this.props.calendarEvent.id),
                    this._onCalendarEventRemove
                );
            }
            CalendarEventStore.removeListener(
                CalendarEventStore.ENTITY_COLLECTION_UPDATED,
                this._onCalendarEventCreate
            );
            CalendarEventStore.removeListener(
                CalendarEventStore.ENTITY_COLLECTION_UPDATED,
                this._onCalendarEventRemove
            );

            // reset state
            this.setState({ calendarEvent: null, updatePending: false, removePending: false });
            this._form = null;
        }
    }

    /**
     * React: render
     */
    render() {
        return (
            <Modal
                isVisible={this.props.isVisible}
                title="Nicht befahrene Touren"
                subtitle={this.props.weekDay ? this.props.weekDay.format("[am] dddd, D.M.YYYY") : null}
            >
                <Modal.Content width="9">
                    {this.state.calendarEvent === null ? (
                        <div className="content-loading">
                            <LoadingAnimation />
                        </div>
                    ) : (
                        <Form
                            type={new CalendarEventForm()}
                            data={this.state.calendarEvent}
                            formRef={(form) => {
                                this._form = form;
                            }}
                            action={this._saveCalendarEvent}
                        />
                    )}
                </Modal.Content>
                <Modal.Actions type="secondary">
                    {this.props.calendarEvent === null ? null : (
                        <Modal.ActionButton
                            actionType="negative"
                            isSecondary={true}
                            askForConfirmation={true}
                            confirmationMessage="Wirklich löschen?"
                            onClick={this._removeCalendarEvent}
                            disabled={
                                this.state.calendarEvent === null ||
                                this.state.updatePending ||
                                this.state.removePending
                            }
                            updatePending={this.state.removePending}
                        >
                            Löschen
                        </Modal.ActionButton>
                    )}
                </Modal.Actions>
                <Modal.Actions>
                    <Modal.ActionButton
                        isSecondary={true}
                        disabled={this.state.updatePending || this.state.removePending}
                        onClick={this.props.toggleCalendarEventModal.bind(this, null, null)}
                    >
                        Abbrechen
                    </Modal.ActionButton>
                    <Modal.ActionButton
                        actionType="positive"
                        onClick={() => {
                            this._form.submit();
                        }}
                        disabled={
                            this.state.calendarEvent === null || this.state.updatePending || this.state.removePending
                        }
                        updatePending={this.state.updatePending}
                    >
                        Speichern
                    </Modal.ActionButton>
                </Modal.Actions>
            </Modal>
        );
    }

    /**
     * Get calendarEvent
     */
    _getCalendarEvent = (calendarEventId) => {
        CalendarEventActions.getCalendarEvent({
            calendarEventId: calendarEventId,
        });
    };

    /**
     * On calendarEvent load
     * @private
     */
    _onCalendarEventLoad = () => {
        this.setState(
            {
                calendarEvent: this.props.calendarEvent
                    ? CalendarEventStore.calendarEventCollection.get(this.props.calendarEvent.id)
                    : null,
            },
            () => {
                if (this.state.calendarEvent) {
                    CalendarEventStore.removeListener(
                        CalendarEventStore.ENTITY_UPDATED(this.props.calendarEvent.id),
                        this._onCalendarEventLoad
                    );
                }
            }
        );
    };

    /**
     * Save calendarEvent
     * @param {CalendarEvent} calendarEvent
     * @private
     */
    _saveCalendarEvent = (calendarEvent) => {
        this._form.setDisabled(true);
        this.setState(
            {
                calendarEvent: calendarEvent,
                updatePending: true,
            },
            () => {
                // call the action
                if (this.props.calendarEvent === null) {
                    CalendarEventStore.addListener(
                        CalendarEventStore.ENTITY_COLLECTION_UPDATED,
                        this._onCalendarEventCreate
                    );
                    CalendarEventActions.createCalendarEvent({ calendarEvent: calendarEvent });
                } else {
                    CalendarEventStore.addListener(
                        CalendarEventStore.ENTITY_UPDATED(this.props.calendarEvent.id),
                        this._onCalendarEventUpdate
                    );
                    CalendarEventActions.updateCalendarEvent({ calendarEvent: calendarEvent });
                }
            }
        );
    };

    /**
     * On calendarEvent create
     * @private
     */
    _onCalendarEventCreate = () => {
        // create completed..
        if (this.state.updatePending) {
            // remove listener
            CalendarEventStore.removeListener(
                CalendarEventStore.ENTITY_COLLECTION_UPDATED,
                this._onCalendarEventCreate
            );

            // ..with error
            if (CalendarEventStore.error !== null) {
                this._form.setDisabled(false);
                this._form.setError(CalendarEventStore.error);
                this.setState({
                    updatePending: false,
                });

                // ..successfully
            } else {
                this.props.toggleCalendarEventModal(null);
            }
        }
    };

    /**
     * On calendarEvent update
     * @private
     */
    _onCalendarEventUpdate = () => {
        var calendarEvent = CalendarEventStore.calendarEventCollection.get(this.state.calendarEvent.id);

        // update completed..
        if (this.state.updatePending && !calendarEvent.updatePending) {
            // remove listener
            CalendarEventStore.removeListener(
                CalendarEventStore.ENTITY_UPDATED(calendarEvent.id),
                this._onCalendarEventUpdate
            );

            // ..with error
            if (CalendarEventStore.error !== null) {
                this._form.setDisabled(false);
                this._form.setError(CalendarEventStore.error);
                this.setState({
                    updatePending: false,
                });

                // ..successfully
            } else {
                this.props.toggleCalendarEventModal(null);
            }
        }
    };

    /**
     * Remove calendarEvent
     * @private
     */
    _removeCalendarEvent = () => {
        this._form.setDisabled(true);
        this.setState(
            {
                removePending: true,
                calendarEvent: this._form.getData(),
            },
            () => {
                CalendarEventStore.addListener(
                    CalendarEventStore.ENTITY_UPDATED(this.state.calendarEvent.id),
                    this._onCalendarEventRemove
                );
                CalendarEventStore.addListener(
                    CalendarEventStore.ENTITY_COLLECTION_UPDATED,
                    this._onCalendarEventRemove
                );
                CalendarEventActions.removeCalendarEvent({ calendarEventId: this.state.calendarEvent.id });
            }
        );
    };

    /**
     * On calendarEvent remove
     * @private
     */
    _onCalendarEventRemove = () => {
        var calendarEvent =
            CalendarEventStore.calendarEventCollection.get(this.state.calendarEvent.id) || this.state.calendarEvent;

        // remove completed..
        if (this.state.removePending && !calendarEvent.updatePending) {
            // remove listener
            CalendarEventStore.removeListener(
                CalendarEventStore.ENTITY_UPDATED(calendarEvent.id),
                this._onCalendarEventRemove
            );
            CalendarEventStore.removeListener(
                CalendarEventStore.ENTITY_COLLECTION_UPDATED,
                this._onCalendarEventRemove
            );

            // ..with error
            if (CalendarEventStore.error !== null) {
                this._form.setDisabled(false);
                this._form.setError(CalendarEventStore.error);
                this.setState({
                    removePending: false,
                });

                // ..successfully
            } else {
                this.props.toggleCalendarEventModal(null);
            }
        }
    };
}
