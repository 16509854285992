"use strict";

import React from "react";
import PropTypes from "prop-types";
import Button from "./Button.react";

/**
 * Modal component
 */
export default class Modal extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        isVisible: PropTypes.bool,
        onClose: PropTypes.func,
        closeOnOverlayClick: PropTypes.bool,
        onOverlayClick: PropTypes.func,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        isVisible: false,
        closeOnOverlayClick: false,
    };

    /**
     * React: state
     */
    state = {
        isVisible: this.props.isVisible,
    };

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        this.setState({
            isVisible: nextProps.isVisible,
        });
    }

    /**
     * React: render
     */
    render() {
        if (this.state.isVisible) {
            // inject closeModal method into children
            var Component = this;
            var componentChildren = React.Children.map(this.props.children, (child) => {
                if (child.type === React.createElement(Actions).type) {
                    return React.cloneElement(child, {
                        closeModal: Component._close,
                    });
                } else {
                    return child;
                }
            });

            return (
                <div className="ui-modal-overlay" onClick={this._onOverlayClick}>
                    <div className="ui-modal" onClick={this._onModalClick}>
                        <h3 className="modal-title">
                            <strong>{this.props.title}</strong> <span>{this.props.subtitle}</span>
                        </h3>
                        {componentChildren}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    /**
     * Close modal
     */
    _close = () => {
        this.setState({
            isVisible: false,
        });

        if (typeof this.props.onClose === "function") {
            this.props.onClose();
        }
    };

    /**
     * On overlay click
     */
    _onOverlayClick = (event) => {
        if (this._click_is_modal) {
            this._click_is_modal = false;
            return;
        }
        if (this.props.closeOnOverlayClick) {
            if (typeof this.props.onOverlayClick === "function") {
                this.props.onOverlayClick();
            }
            this._close();
        }
    };

    /**
     * On modal click
     */
    _onModalClick = (event) => {
        this._click_is_modal = true;
    };
}

/**
 * Modal.Content component
 */
class Content extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    /**
     * React: render
     */
    render() {
        var className =
            "modal-content" +
            (this.props.width ? " ui-col-" + this.props.width : "") +
            (this.props.height ? " ui-row-" + this.props.height : "");

        return <div className={className}>{this.props.children}</div>;
    }
}

/**
 * Modal.Actions component
 */
class Actions extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        closeModal: PropTypes.func,
        type: PropTypes.oneOf(["primary", "secondary"]),
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        type: "primary",
    };

    /**
     * React: render
     */
    render() {
        // inject closeModal method into children
        var Component = this;
        var children = React.Children.map(this.props.children, (child) => {
            if (child.type === React.createElement(ActionButton).type) {
                return React.cloneElement(child, {
                    closeModal: Component.props.closeModal,
                });
            } else {
                return child;
            }
        });

        var className = this.props.type + "-actions";
        return <div className={className}>{children}</div>;
    }
}

/**
 * Modal.ActionButton component
 */
class ActionButton extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = Object.assign({}, Button.propTypes, {
        closeModalOnClick: PropTypes.bool,
        closeModal: PropTypes.func,
    });

    /**
     * React: defaultProps
     */
    static defaultProps = Object.assign({}, Button.defaultProps, {
        closeModalOnClick: false,
    });

    /**
     * React: render
     */
    render() {
        return (
            <Button size="large" {...this.props} onClick={this._onClick}>
                {this.props.children}
            </Button>
        );
    }

    /**
     * On click
     */
    _onClick = (event) => {
        if (typeof this.props.onClick === "function" && this.props.closeModalOnClick) {
            this.props.onClick(event);
            this.props.closeModal();
        } else if (typeof this.props.onClick === "function") {
            this.props.onClick(event, this.props.closeModal);
        } else if (this.props.closeModalOnClick) {
            this.props.closeModal();
        }
    };
}

Object.assign(Modal, {
    Content: Content,
    Actions: Actions,
    ActionButton: ActionButton,
});
