"use strict";

import React from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import FormBuilder from "../FormBuilder";
import Field from "./Field.react";
import { translate } from "../../i18n/Translate";
import Button from "./Button.react";
import ActionBar from "./ActionBar.react";
import FlashBar from "./FlashBar.react";
import LoadingAnimation from "./LoadingAnimation.react";

/**
 * Form component
 */
export default class Form extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        type: PropTypes.object.isRequired,
        data: PropTypes.object,
        formRef: PropTypes.func,
        onChange: PropTypes.func,
        onInput: PropTypes.func,
        onReset: PropTypes.func,
        onSubmit: PropTypes.func,
        updatePending: PropTypes.bool,
        disabled: PropTypes.bool,
        inCollection: PropTypes.bool,
        submitOnEnter: PropTypes.bool,
        inline: PropTypes.bool,
        showLabels: PropTypes.bool,
        labelWidth: PropTypes.node,
        widgetWidth: PropTypes.node,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        inline: false,
        inCollection: false,
        showLabels: true,
        labelWidth: 2,
        widgetWidth: 4,
    };

    /**
     * React: state
     */
    state = {
        fields: Immutable.OrderedMap(),
        updatePending: false,
        disabled: false,
        error: null,
    };

    /**
     * Form builder
     * @type {FormBuilder|null}
     * @private
     */
    _formBuilder = null;

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this._formBuilder = new FormBuilder(this.props.type, this.props.data, this.state.error);
        this.state.fields = this._formBuilder.fields;

        this.state.updatePending = this.props.updatePending;
        this.state.disabled = this.props.updatePending || this.props.disabled;

        if (typeof this.props.formRef === "function") {
            this.props.formRef(this._formRef);
        }
    }

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps.type !== this.props.type || nextProps.data !== this.props.data) {
            this._formBuilder = new FormBuilder(nextProps.type, nextProps.data, this.state.error);
            this.setState({
                fields: this._formBuilder.fields,
            });
        }

        if (nextProps.updatePending !== this.props.updatePending || nextProps.disabled !== this.props.disabled) {
            this.setState({
                updatePending: nextProps.updatePending,
                disabled: nextProps.updatePending || nextProps.disabled,
            });
        }
    }

    /**
     * React: render
     */
    render() {
        var formFields = [];
        var formButtons = [];

        // form definition is expanded
        if (this.props.children) {
            React.Children.forEach(this.props.children, (ReactElement) => {
                switch (ReactElement.type.displayName) {
                    case "Field":
                        if (typeof this.state.fields.get(ReactElement.props.name) !== "undefined") {
                            formFields.push(this._getField(this.state.fields.get(ReactElement.props.name)));
                        } else {
                            formFields.push(ReactElement);
                        }
                        break;

                    case "Button":
                        if (typeof this.state.fields.get(ReactElement.props.name) !== "undefined") {
                            formButtons.push(this._getButton(this.state.fields.get(ReactElement.props.name)));
                        } else {
                            formFields.push(ReactElement);
                        }
                        break;

                    default:
                        formFields.push(ReactElement);
                }
            });

            // form definition is compact
        } else {
            this.state.fields.forEach((field) => {
                switch (field.type) {
                    case "reset":
                    case "submit":
                    case "button":
                        formButtons.push(this._getButton(field));
                        break;

                    default:
                        formFields.push(this._getField(field));
                        break;
                }
            });
        }

        var formContent = null;
        if (this.props.inCollection) {
            formContent = (
                <div>
                    {formFields}
                    {formButtons.length === 0 ? null : (
                        <ActionBar>
                            <ActionBar.Actions>{formButtons}</ActionBar.Actions>
                        </ActionBar>
                    )}
                </div>
            );
        } else {
            formContent = (
                <form
                    onChange={this._onChange}
                    onInput={this._onInput}
                    onReset={this._onReset}
                    onSubmit={this._onSubmit}
                >
                    {formFields}
                    {formButtons.length === 0 ? null : (
                        <ActionBar>
                            <ActionBar.Actions>{formButtons}</ActionBar.Actions>
                        </ActionBar>
                    )}
                </form>
            );
        }

        var errorMessages = [];
        if (this.state.error) {
            if (typeof this.state.error.message !== "undefined") {
                errorMessages.push(
                    <div key={errorMessages.length}>{translate(this.state.error.message, {}, true)}</div>
                );
            }

            if (
                typeof this.state.error.errors !== "undefined" &&
                typeof this.state.error.errors.errors !== "undefined"
            ) {
                this.state.error.errors.errors.forEach((error) => {
                    errorMessages.push(<div key={errorMessages.length}>{translate(error, {}, true)}</div>);
                });
            }
        }

        var className = "ui-form" + (this.props.inline ? " inline" : "");
        return (
            <div className={className}>
                <FlashBar isVisible={this.state.error !== null} type="error">
                    {errorMessages}
                </FlashBar>
                {formContent}
                {!this.state.updatePending ? null : (
                    <div className="update-pending">
                        <LoadingAnimation />
                    </div>
                )}
            </div>
        );
    }

    /**
     * Get field component
     * @param {FieldRecord} field
     * @private
     */
    _getField = (field) => {
        return (
            <Field
                key={field.name}
                type={field.type}
                field={field}
                disabled={this.state.disabled}
                errors={field.errors}
                onChange={this._updateFieldValue}
                onInput={this._onFieldInput}
                onKeyDown={this._submitOnEnter}
                showLabel={this.props.showLabels}
                labelWidth={this.props.labelWidth}
                widgetWidth={this.props.widgetWidth}
            />
        );
    };

    /**
     * Get button component
     * @param {FieldRecord} field
     * @private
     */
    _getButton = (field) => {
        return (
            <Button
                {...field.options}
                key={field.name}
                type={field.type}
                disabled={this.state.disabled || field.options.disabled}
            >
                {field.options.label ? field.options.label : field.type}
            </Button>
        );
    };

    /**
     * Update field value
     * @param {FieldRecord} field
     * @param {(number|string|bool|Object)} value
     * @private
     */
    _updateFieldValue = (field, value) => {
        this.setState(
            {
                fields: this._formBuilder.setFieldValue(field, value),
            },
            () => {
                // if form is in collection call form's onChange event
                if (this.props.inCollection && typeof this.props.onChange === "function") {
                    this.props.onChange(field, value);
                }
            }
        );
    };

    /**
     * On field input (used when form is in  collection)
     * @param field
     * @param value
     * @private
     */
    _onFieldInput = (field, value) => {
        if (!this.props.inCollection) return;

        if (typeof this.props.onInput === "function") {
            this.props.onInput(field, value);
        }
    };

    /**
     * Reset form
     */
    _reset = () => {
        this.setState(
            {
                fields: this._formBuilder.resetForm(),
            },
            () => {
                // onReset callback
                if (typeof this.props.onReset === "function") {
                    this.props.onReset(null);
                }
            }
        );
    };

    /**
     * Form submit
     */
    _submit = () => {
        // reset error message
        this.setState({ error: null });

        // submit form by calling form action
        if (typeof this.props.action === "function") {
            this.props.action(this._formBuilder.getData());
        }

        // onSubmit callback
        if (typeof this.props.onSubmit === "function") {
            this.props.onSubmit(null);
        }
    };

    /**
     * Submit on enter or prevent it
     *
     * @param {FieldRecord} field
     * @param {(number|string|bool)} value
     * @param {Object} event
     * @private
     */
    _submitOnEnter = (field, value, event) => {
        if (!this.props.submitOnEnter && event.keyCode === 13) {
            event.preventDefault();
        } else if (this.props.submitOnEnter && event.keyCode === 13) {
            this._submit();
            event.preventDefault();
        }
    };

    /**
     * Set update pending status
     * @param {bool} status
     */
    _setUpdatePending = (status) => {
        this.setState({
            updatePending: status,
        });
    };

    /**
     * Set disabled status
     * @param {bool} status
     */
    _setDisabled = (status) => {
        this.setState({
            disabled: status,
        });
    };

    /**
     * Set error
     * @param {Object} error
     */
    _setError = (error) => {
        this.setState({
            error: error,
        });
    };

    /**
     * On form change
     * @param {Object} event
     * @private
     */
    _onChange = (event) => {
        if (typeof this.props.onChange === "function") {
            this.props.onChange(null, event.target.value);
        }
    };

    /**
     * On form input
     * @param {Object} event
     * @private
     */
    _onInput = (event) => {
        if (typeof this.props.onInput === "function") {
            this.props.onInput(null, event.target.value);
        }
    };

    /**
     * On form reset
     * Stops default reset and calls custom
     * @param {Object} event
     * @private
     */
    _onReset = (event) => {
        this._reset();
        if (typeof this.props.onReset === "function") {
            this.props.onReset(event);
        }
        event.preventDefault();
    };

    /**
     * On form submit
     * Stops default submit and calls custom
     * @param {Object} event
     * @private
     */
    _onSubmit = (event) => {
        this._submit();
        if (typeof this.props.onSubmit === "function") {
            this.props.onSubmit(event);
        }
        event.preventDefault();
    };

    /**
     * Get form data wrapper
     * @return {Object}
     * @private
     */
    _getData = () => {
        return this._formBuilder.getData();
    };

    /**
     * Get form data class wrapper
     * @return {*}
     * @private
     */
    _getDataClass = () => {
        return this._formBuilder.getDataClass();
    };

    /**
     * Form controller
     * @type {Object}
     * @private
     */
    _formRef = {
        reset: this._reset,
        submit: this._submit,
        getData: this._getData,
        getDataClass: this._getDataClass,
        isUpdatePending: () => {
            return this.state.updatePending;
        },
        isDisabled: () => {
            return this.state.disabled;
        },
        setUpdatePending: this._setUpdatePending,
        setDisabled: this._setDisabled,
        setError: this._setError,
    };
}
