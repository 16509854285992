"use strict";

import moment from "moment";
import Immutable from "immutable";
import Driver from "../types/Driver";
import DriverAbsence from "../types/DriverAbsence";
import ActionTypes from "../constants/ActionTypes";
import EntityStore from "./EntityStore";

/**
 * Flux: DriverStore
 */
class DriverStore extends EntityStore {
    /**
     * driverCollection hash
     *
     * @type {Immutable.OrderedMap<string, Driver>}
     * @private
     */
    _driverCollection = Immutable.OrderedMap();

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));

        this._error = null;
    }

    /**
     * Flux: register store to actions
     *
     * @param action
     * @private
     */
    _registerToActions(action) {
        switch (action.type) {
            // get driverCollection
            case ActionTypes.REQUEST_GET_DRIVER_COLLECTION:
                this._error = null;
                break;

            case ActionTypes.REQUEST_GET_DRIVER_COLLECTION_SUCCESS:
                this._setDriverCollection(action.body.drivers);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_GET_DRIVER_COLLECTION_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            // create driver
            case ActionTypes.REQUEST_CREATE_DRIVER:
                this._error = null;
                break;

            case ActionTypes.REQUEST_CREATE_DRIVER_SUCCESS:
                this._setDriver(action.body.driver);
                this._sortDriverCollectionBy("nickname");
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_CREATE_DRIVER_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            // get driver
            case ActionTypes.REQUEST_GET_DRIVER:
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.driverId));
                break;

            case ActionTypes.REQUEST_GET_DRIVER_SUCCESS:
                this._setDriver(action.body.driver);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.body.driver.id));
                break;

            case ActionTypes.REQUEST_GET_DRIVER_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.driverId));
                break;

            // update driver
            case ActionTypes.REQUEST_UPDATE_DRIVER:
                this._toggleUpdatePending(action.driver.id);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.driver.id));
                break;

            case ActionTypes.REQUEST_UPDATE_DRIVER_SUCCESS:
                this._setDriver(action.body.driver);
                this._sortDriverCollectionBy("nickname");
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.body.driver.id));
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_UPDATE_DRIVER_ERROR:
                this._toggleUpdatePending(action.driver.id);
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.driver.id));
                break;

            // remove driver
            case ActionTypes.REQUEST_REMOVE_DRIVER:
                this._toggleUpdatePending(action.driverId);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.driverId));
                break;

            case ActionTypes.REQUEST_REMOVE_DRIVER_SUCCESS:
                this._toggleUpdatePending(action.driverId);
                this._removeDriver(action.driverId);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_REMOVE_DRIVER_ERROR:
                this._toggleUpdatePending(action.driverId);
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.driverId));
                break;

            default:
                break;
        }
    }

    /**
     * Set _driverCollection
     *
     * @param {Array.<Driver>} responseDrivers
     * @private
     */
    _setDriverCollection(responseDrivers) {
        responseDrivers.map((responseDriver) => {
            this._setDriver(responseDriver);
        });
        this._sortDriverCollectionBy("nickname");
    }

    /**
     * Set _driverCollection[key]
     *
     * @params {Driver} responseDriver
     * @private
     */
    _setDriver(responseDriver) {
        this._driverCollection = this._driverCollection.set(
            responseDriver.id,
            new Driver({
                ...responseDriver,
                employmentStart: moment(responseDriver.employmentStart),
                employmentEnd: responseDriver.employmentEnd ? moment(responseDriver.employmentEnd) : null,
                absence: responseDriver.absence
                    ? responseDriver.absence.map((responseDriverAbsence) => {
                          return new DriverAbsence({
                              ...responseDriverAbsence,
                              startDate: moment(responseDriverAbsence.startDate),
                              endDate: moment(responseDriverAbsence.endDate),
                          });
                      })
                    : null,
            })
        );
    }

    /**
     * SortBy _driverCollection
     * @param {string} key
     * @param {bool} ascending
     * @private
     */
    _sortDriverCollectionBy(key, ascending = true) {
        this._driverCollection = this._driverCollection.sort((itemA, itemB) => {
            return itemA[key] === itemB[key]
                ? 0
                : ascending
                ? itemA[key] > itemB[key]
                    ? 1
                    : -1
                : itemA[key] < itemB[key]
                ? 1
                : -1;
        });
    }

    /**
     * Remove _driverCollection[key]
     *
     * @params {number} driverId
     * @private
     */
    _removeDriver(driverId) {
        this._driverCollection = this._driverCollection.remove(driverId);
    }

    /**
     * Set updatePending flag on driver
     *
     * @param {number} driverId
     * @private
     */
    _toggleUpdatePending(driverId) {
        var driver = this._driverCollection.get(driverId);
        driver = driver.set("updatePending", !driver.get("updatePending"));
        this._driverCollection = this._driverCollection.set(driverId, driver);
    }

    /**
     * Get _driverCollection
     *
     * @returns {Immutable.OrderedMap<string, Driver>}
     */
    get driverCollection() {
        return this._driverCollection;
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new DriverStore();
