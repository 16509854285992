"use strict";

import React from "react";
import PropTypes from "prop-types";
import DriverGroupActions from "../actions/DriverGroupActions";
import DriverGroupStore from "../stores/DriverGroupStore";
import Modal from "../ui/elements/Modal.react";
import LoadingAnimation from "../ui/elements/LoadingAnimation.react";

/**
 * DriverGroupOverviewModal component
 */
export default class DriverGroupOverviewModal extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        isVisible: PropTypes.bool,
        driverGroup: PropTypes.object,
        toggleDriverGroupOverviewModal: PropTypes.func.isRequired,
    };

    /**
     * React: state
     */
    state = {
        driverGroup: null,
    };

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        // modal will be visible..
        if (nextProps.isVisible && this.state.driverGroup === null && nextProps.driverGroup != null) {
            // add store listeners
            DriverGroupStore.addListener(
                DriverGroupStore.ENTITY_UPDATED(nextProps.driverGroup.id),
                this._onDriverGroupUpdate
            );

            this._getDriverGroup(nextProps.driverGroup.id);
        }

        // modal will be hidden..
        if (!nextProps.isVisible && this.props.driverGroup != null) {
            // remove store listeners
            DriverGroupStore.removeListener(
                DriverGroupStore.ENTITY_UPDATED(this.props.driverGroup.id),
                this._onDriverGroupUpdate
            );

            // reset state
            this.setState({ driverGroup: null });
        }
    }

    /**
     * React: render
     */
    render() {
        var tabularContent = null;

        if (this.state.driverGroup !== null) {
            let driverGroup = this.state.driverGroup;

            let tableHeader = [];
            driverGroup.drivers.forEach((driver) => {
                tableHeader.push(
                    <th key={driver.id} className="driver">
                        {driver.nickname}
                    </th>
                );
            });

            let tableRows = [];
            driverGroup.tours.forEach((tour) => {
                let rowCells = [];

                let tourDrivers = {};
                tour.drivers.forEach((driver) => {
                    tourDrivers[driver.id] = driver;
                });

                driverGroup.drivers.forEach((driver) => {
                    rowCells.push(
                        <td key={driver.id}>{tourDrivers[driver.id] ? <i className="ui-icon icon-yes" /> : null}</td>
                    );
                });

                tableRows.push(
                    <tr key={tour.id}>
                        <td>{tour.tourName}</td>
                        <td>
                            <strong>{tour.hasShifts ? "am/pm" : null}</strong>
                        </td>
                        {rowCells}
                    </tr>
                );
            });

            tabularContent = (
                <table className="schedule-table">
                    <thead>
                        <tr>
                            <th className="title"></th>
                            <th className="additional"></th>
                            {tableHeader}
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </table>
            );
        }

        return (
            <Modal
                isVisible={this.props.isVisible}
                title={this.props.driverGroup ? this.props.driverGroup.groupName : ""}
                subtitle="Fahrer & Tourenliste"
            >
                <Modal.Content width="14">
                    {this.state.driverGroup === null ? (
                        <div className="content-loading">
                            <LoadingAnimation />
                        </div>
                    ) : (
                        <div className="driver-group-overview-modal">{tabularContent}</div>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Modal.ActionButton onClick={this.props.toggleDriverGroupOverviewModal.bind(null, null)}>
                        Schliessen
                    </Modal.ActionButton>
                </Modal.Actions>
            </Modal>
        );
    }

    /**
     * Get driverGroup
     */
    _getDriverGroup = (driverGroupId) => {
        DriverGroupActions.getDriverGroup({
            driverGroupId: driverGroupId,
        });
    };

    /**
     * On driverGroup update
     * @private
     */
    _onDriverGroupUpdate = () => {
        var driverGroup = this.props.driverGroup
            ? DriverGroupStore.driverGroupCollection.get(this.props.driverGroup.id)
            : null;

        this.setState({
            driverGroup: driverGroup,
        });
    };
}
