"use strict";

import moment from "moment";
import Immutable from "immutable";

/**
 * Tour type
 *
 * @typedef {(Immutable.Record|Object)} Tour
 * @property {number} id
 * @property {string} tourName
 * @property {bool} hasShifts
 * @property {!Object.<number, bool>} weekDays
 * @property {?DriverGroup} driverGroup
 * @property {bool} isMountainTour
 * @property {Array.<Driver>} drivers
 * @property {Array.<ErpReference>} erpReferences
 * @property {?Driver} reservedDriver
 * @property {bool} updatePending
 */

/**
 * Tour record
 *
 * @type {Tour}
 */
var Tour = Immutable.Record({
    id: null,
    tourName: "",
    hasShifts: false,
    weekDays: { 1: true, 2: true, 3: true, 4: true, 5: true, 6: false, 7: false },
    orderDeadline: {
        1: { daysBefore: 1, time: moment.duration(17, "hours") },
        2: { daysBefore: 1, time: moment.duration(17, "hours") },
        3: { daysBefore: 1, time: moment.duration(17, "hours") },
        4: { daysBefore: 1, time: moment.duration(17, "hours") },
        5: { daysBefore: 1, time: moment.duration(17, "hours") },
        6: { daysBefore: 1, time: moment.duration(17, "hours") },
        7: { daysBefore: 1, time: moment.duration(17, "hours") },
    },
    driverGroup: null,
    isMountainTour: false,
    drivers: [],
    erpReferences: [],
    reservedDriver: null,
    updatePending: false,
});

export default Tour;
