"use strict";

import React from "react";
import PropTypes from "prop-types";
import Tour from "../types/Tour";
import TourActions from "../actions/TourActions";
import TourStore from "../stores/TourStore";
import TourForm from "../forms/TourForm";
import Form from "../ui/elements/Form.react";
import Modal from "../ui/elements/Modal.react";
import LoadingAnimation from "../ui/elements/LoadingAnimation.react";

/**
 * TourModal component
 */
export default class TourModal extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        isVisible: PropTypes.bool,
        tour: PropTypes.instanceOf(Tour),
        toggleTourModal: PropTypes.func.isRequired,
    };

    /**
     * React: state
     */
    state = {
        tour: null,
        updatePending: false,
        removePending: false,
    };

    /**
     * Form ref
     * @type {Object|null}
     * @private
     */
    _form = null;

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        // modal will be visible..
        if (nextProps.isVisible && this.state.tour === null) {
            // ..and showing existing tour
            if (nextProps.tour != null) {
                TourStore.addListener(TourStore.ENTITY_UPDATED(nextProps.tour.id), this._onTourLoad);
                this._getTour(nextProps.tour.id);

                // ..and showing new tour
            } else {
                this.setState({ tour: new Tour({}) });
            }
        }

        // modal will be hidden..
        if (!nextProps.isVisible) {
            // ..for existing tour
            if (this.props.tour !== null) {
                TourStore.removeListener(TourStore.ENTITY_UPDATED(this.props.tour.id), this._onTourLoad);
                TourStore.removeListener(TourStore.ENTITY_UPDATED(this.props.tour.id), this._onTourUpdate);
                TourStore.removeListener(TourStore.ENTITY_UPDATED(this.props.tour.id), this._onTourRemove);
            }
            TourStore.removeListener(TourStore.ENTITY_COLLECTION_UPDATED, this._onTourCreate);
            TourStore.removeListener(TourStore.ENTITY_COLLECTION_UPDATED, this._onTourRemove);

            // reset state
            this.setState({ tour: null, updatePending: false, removePending: false });
            this._form = null;
        }
    }

    /**
     * React: render
     */
    render() {
        return (
            <Modal
                isVisible={this.props.isVisible}
                title={this.props.tour ? this.props.tour.tourName : "Neue Tour"}
                subtitle={this.props.tour ? "Tourendetails" : ""}
            >
                <Modal.Content width="9">
                    {this.state.tour === null ? (
                        <div className="content-loading">
                            <LoadingAnimation />
                        </div>
                    ) : (
                        <Form
                            type={new TourForm()}
                            data={this.state.tour}
                            formRef={(form) => {
                                this._form = form;
                            }}
                            action={this._saveTour}
                        />
                    )}
                </Modal.Content>
                <Modal.Actions type="secondary">
                    {this.props.tour === null ? null : (
                        <Modal.ActionButton
                            actionType="negative"
                            askForConfirmation={true}
                            confirmationMessage="Wirklich löschen?"
                            onClick={this._removeTour}
                            disabled={this.state.tour === null || this.state.updatePending || this.state.removePending}
                            updatePending={this.state.removePending}
                        >
                            Löschen
                        </Modal.ActionButton>
                    )}
                </Modal.Actions>
                <Modal.Actions>
                    <Modal.ActionButton
                        isSecondary={true}
                        disabled={this.state.updatePending || this.state.removePending}
                        onClick={this.props.toggleTourModal.bind(this, null)}
                    >
                        Abbrechen
                    </Modal.ActionButton>
                    <Modal.ActionButton
                        actionType="positive"
                        onClick={() => {
                            this._form.submit();
                        }}
                        disabled={this.state.tour === null || this.state.updatePending || this.state.removePending}
                        updatePending={this.state.updatePending}
                    >
                        Speichern
                    </Modal.ActionButton>
                </Modal.Actions>
            </Modal>
        );
    }

    /**
     * Get tour
     */
    _getTour = (tourId) => {
        TourActions.getTour({
            tourId: tourId,
        });
    };

    /**
     * On tour load
     * @private
     */
    _onTourLoad = () => {
        this.setState(
            {
                tour: this.props.tour ? TourStore.tourCollection.get(this.props.tour.id) : null,
            },
            () => {
                if (this.state.tour) {
                    TourStore.removeListener(TourStore.ENTITY_UPDATED(this.props.tour.id), this._onTourLoad);
                }
            }
        );
    };

    /**
     * Save tour
     * @param {Tour} tour
     * @private
     */
    _saveTour = (tour) => {
        this._form.setDisabled(true);
        this.setState(
            {
                tour: tour,
                updatePending: true,
            },
            () => {
                // call the action
                if (this.props.tour === null) {
                    TourStore.addListener(TourStore.ENTITY_COLLECTION_UPDATED, this._onTourCreate);
                    TourActions.createTour({ tour: tour });
                } else {
                    TourStore.addListener(TourStore.ENTITY_UPDATED(this.props.tour.id), this._onTourUpdate);
                    TourActions.updateTour({ tour: tour });
                }
            }
        );
    };

    /**
     * On tour create
     * @private
     */
    _onTourCreate = () => {
        // create completed..
        if (this.state.updatePending) {
            // remove listener
            TourStore.removeListener(TourStore.ENTITY_COLLECTION_UPDATED, this._onTourCreate);

            // ..with error
            if (TourStore.error !== null) {
                this._form.setDisabled(false);
                this._form.setError(TourStore.error);
                this.setState({
                    updatePending: false,
                });

                // ..successfully
            } else {
                this.props.toggleTourModal(null);
            }
        }
    };

    /**
     * On tour update
     * @private
     */
    _onTourUpdate = () => {
        var tour = TourStore.tourCollection.get(this.state.tour.id);

        // update completed..
        if (this.state.updatePending && !tour.updatePending) {
            // remove listener
            TourStore.removeListener(TourStore.ENTITY_UPDATED(tour.id), this._onTourUpdate);

            // ..with error
            if (TourStore.error !== null) {
                this._form.setDisabled(false);
                this._form.setError(TourStore.error);
                this.setState({
                    updatePending: false,
                });

                // ..successfully
            } else {
                this.props.toggleTourModal(null);
            }
        }
    };

    /**
     * Remove tour
     * @private
     */
    _removeTour = () => {
        this._form.setDisabled(true);
        this.setState(
            {
                removePending: true,
                tour: this._form.getData(),
            },
            () => {
                TourStore.addListener(TourStore.ENTITY_UPDATED(this.state.tour.id), this._onTourRemove);
                TourStore.addListener(TourStore.ENTITY_COLLECTION_UPDATED, this._onTourRemove);
                TourActions.removeTour({ tourId: this.state.tour.id });
            }
        );
    };

    /**
     * On tour remove
     * @private
     */
    _onTourRemove = () => {
        var tour = TourStore.tourCollection.get(this.state.tour.id) || this.state.tour;

        // remove completed..
        if (this.state.removePending && !tour.updatePending) {
            // remove listener
            TourStore.removeListener(TourStore.ENTITY_UPDATED(tour.id), this._onTourRemove);
            TourStore.removeListener(TourStore.ENTITY_COLLECTION_UPDATED, this._onTourRemove);

            // ..with error
            if (TourStore.error !== null) {
                this._form.setDisabled(false);
                this._form.setError(TourStore.error);
                this.setState({
                    removePending: false,
                });

                // ..successfully
            } else {
                this.props.toggleTourModal(null);
            }
        }
    };
}
