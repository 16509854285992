"use strict";

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { extendMoment } from "moment-range";
import * as InternalPropTypes from "../../types/PropTypes";
import Text from "./Text.react";
import Button from "./Button.react";

/**
 * DateTime component
 */
export default class DateTime extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        value: PropTypes.oneOfType([InternalPropTypes.time, InternalPropTypes.moment]),
        inputDate: PropTypes.bool,
        inputTime: PropTypes.bool,
        dateFormat: PropTypes.string,
        timeFormat: PropTypes.string,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        disabled: PropTypes.bool,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        value: null,
        inputDate: true,
        inputTime: false,
        dateFormat: "YYYY-MM-DD",
        timeFormat: "HH:mm",
        disabled: false,
    };

    /**
     * React: state
     */
    state = {
        value: null,
        dateFieldValue: null,
        timeFieldValue: null,
        datePickerDate: null,
        datePickerVisible: false,
        timePickerVisible: false,
    };

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this.state.value = this._parseValue(this.props.value);
        this.state.dateFieldValue = this.state.value ? this.state.value.format(this.props.dateFormat) : null;
        this.state.datePickerDate = this.state.value
            ? moment(this.state.value).startOf("month")
            : moment().startOf("month");
        this.state.timeFieldValue = this.state.value ? this.state.value.format(this.props.timeFormat) : null;
        this._dateInputRef = React.createRef();
        this._timeInputRef = React.createRef();
    }

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        var value = this._parseValue(nextProps.value);
        this.setState({
            value: value,
            dateFieldValue: value ? value.format(this.props.dateFormat) : null,
            datePickerDate: value ? moment(value).startOf("month") : moment().startOf("month"),
            timeFieldValue: value ? value.format(this.props.timeFormat) : null,
        });
    }

    /**
     * React: componentWillUpdate
     */
    componentWillUpdate(nextProps, nextState) {
        // on datePicker visibility change
        if (!nextState.datePickerVisible && this.state.datePickerVisible) {
            // remove click event on document
            document.removeEventListener("click", this._closeDatePicker, false);
        }

        // on timePicker visibility change
        if (!nextState.timePickerVisible && this.state.timePickerVisible) {
            // remove click event on document
            document.removeEventListener("click", this._closeTimePicker, false);
        }
    }

    /**
     * React: componentDidUpdate
     */
    componentDidUpdate() {
        // on datePicker visibility change
        if (this.state.datePickerVisible) {
            // add click event on document
            document.addEventListener("click", this._closeDatePicker, false);
        }

        // on timePicker visibility change
        if (this.state.timePickerVisible) {
            // add click event on document
            document.addEventListener("click", this._closeTimePicker, false);
        }
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        // datePicker is visible therefor component has related listeners
        if (this.state.datePickerVisible) {
            // remove click event on document
            document.removeEventListener("click", this._closeDatePicker, false);
        }

        // timePicker is visible therefor component has related listeners
        if (this.state.timePickerVisible) {
            // remove click event on document
            document.removeEventListener("click", this._closeTimePicker, false);
        }
    }

    /**
     * React: render
     */
    render() {
        var weekStart = moment(this.state.datePickerDate).weekday(0);
        var weekEnd = moment(this.state.datePickerDate).weekday(6);
        var calendarRows = [];
        do {
            let week = extendMoment(moment).range(weekStart, weekEnd);
            let calendarCells = [];

            for (const weekDay of week.by("days")) {
                let className = null;
                if (!weekDay.isSame(this.state.datePickerDate, "month")) {
                    className = "not-in-month";
                } else if (this.state.value && weekDay.isSame(this.state.value, "day")) {
                    className = "selected";
                } else if (weekDay.isSame(moment(), "day")) {
                    className = "today";
                }

                calendarCells.push(
                    <td
                        key={weekDay.format("MM-DD")}
                        className={className}
                        onClick={this._selectDate.bind(null, weekDay)}
                    >
                        {!weekDay.isSame(this.state.datePickerDate, "month") ? null : weekDay.format("D")}
                    </td>
                );
            }

            calendarRows.push(<tr key={weekStart.format("W")}>{calendarCells}</tr>);

            weekStart.add(1, "weeks");
            weekEnd.add(1, "weeks");
        } while (weekStart.isSame(this.state.datePickerDate, "month"));

        // week day names
        var weekDays = [];
        for (const weekDay of extendMoment(moment).range(weekStart, weekEnd).by("days")) {
            weekDays.push(<th key={weekDay.format("dddd")}>{weekDay.format("dd")}</th>);
        }

        var dateInputClassName = "date-input" + (this.props.inputTime ? " with-time" : "");
        return (
            <div className="ui-date-time">
                <div
                    ref={this._dateInputRef}
                    className={dateInputClassName}
                    style={this.props.inputDate ? null : { display: "none" }}
                >
                    <Text
                        {...this.props}
                        value={this.state.dateFieldValue || ""}
                        disabled={this.props.disabled}
                        onChange={this._parseDate}
                        onFocus={this._onDatePickerFocus}
                        onBlur={this._onBlur}
                    />
                    <i
                        className="ui-icon icon-calendar"
                        onClick={this._setDatePickerVisibility.bind(this, !this.state.datePickerVisible)}
                    />
                    {!this.state.datePickerVisible ? null : (
                        <div className="date-picker">
                            <div className="previous-month">
                                <Button onClick={this._showPreviousMonth} size="icon-small">
                                    <i className="ui-icon icon-previous" />
                                </Button>
                            </div>
                            <div className="next-month">
                                <Button onClick={this._showNextMonth} size="icon-small">
                                    <i className="ui-icon icon-next" />
                                </Button>
                            </div>
                            <div className="month-label">
                                {this.state.datePickerDate.format(
                                    this.state.datePickerDate.isSame(moment(), "year") ? "MMMM" : "MMMM YYYY"
                                )}
                            </div>

                            <table>
                                <thead>
                                    <tr>{weekDays}</tr>
                                </thead>
                                <tbody>{calendarRows}</tbody>
                            </table>

                            <div className="bottom-controls">
                                <Button onClick={this._setCurrentDate}>Heute</Button>
                                <Button onClick={this._clearDate}>
                                    Löschen
                                    <i className="ui-icon icon-cancel" />
                                </Button>
                            </div>
                        </div>
                    )}
                </div>

                <div
                    ref={this._timeInputRef}
                    className="time-input"
                    style={this.props.inputTime ? null : { display: "none" }}
                >
                    <Text
                        value={this.state.timeFieldValue}
                        disabled={this.props.disabled}
                        onInput={this.props.onInput}
                        onChange={this._parseTime}
                        onKeyPress={this.props.onKeyPress}
                        onKeyDown={this.props.onKeyDown}
                        onKeyUp={this.props.onKeyUp}
                        onFocus={this._onTimePickerFocus}
                        onBlur={this._onBlur}
                    />
                    <i
                        className="ui-icon icon-calendar"
                        onClick={this._setTimePickerVisibility.bind(this, !this.state.timePickerVisible)}
                    />
                    {!this.state.timePickerVisible ? null : (
                        <div className="time-picker">
                            <div className="bottom-controls">
                                <Button onClick={this._setCurrentTime}>Jetz</Button>
                                <Button onClick={this._resetTime}>
                                    Rücksetzen
                                    <i className="ui-icon icon-refresh" />
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    /**
     * Parse value (from props)
     * @private
     */
    _parseValue = (value) => {
        var newValue = null;

        // value is moment object
        if (value && moment.isMoment(value)) {
            newValue = value;

            // value is time string
        } else if (value && typeof value === "string") {
            newValue = moment(value);

            // value is number (unix timestamp)
        } else if (value && typeof value === "number") {
            newValue = moment.unix(value);
        }

        return newValue;
    };

    /**
     * Select date in date picker
     * @param {Object} weekDay
     * @private
     */
    _selectDate = (weekDay) => {
        this.setState(
            {
                value: weekDay,
                dateFieldValue: weekDay.format(this.props.dateFormat),
                datePickerDate: moment(weekDay).startOf("month"),
            },
            () => {
                this._setDatePickerVisibility(false);
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            }
        );
    };

    /**
     * Set current date
     * @private
     */
    _setCurrentDate = () => {
        this.setState(
            {
                value: moment(),
                dateFieldValue: moment().format(this.props.dateFormat),
                datePickerDate: moment().startOf("month"),
            },
            () => {
                this._setDatePickerVisibility(false);
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            }
        );
    };

    /**
     * Clear date field
     */
    _clearDate = () => {
        this.setState(
            {
                value: null,
                dateFieldValue: null,
                datePickerDate: moment().startOf("month"),
            },
            () => {
                this._setDatePickerVisibility(false);
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            }
        );
    };

    /**
     * Parse date from date field
     * @private
     */
    _parseDate = (fieldValue) => {
        var fieldDate = moment(fieldValue, this.props.dateFormat);

        this._setDatePickerVisibility(false);
        this.setState(
            {
                value: moment.isMoment(fieldDate) ? fieldDate : null,
                dateFieldValue: fieldValue,
                datePickerDate: moment.isMoment(fieldDate)
                    ? moment(fieldDate).startOf("month")
                    : moment().startOf("month"),
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            }
        );
    };

    /**
     * Set date picker visibility
     * @private
     */
    _setDatePickerVisibility = (status) => {
        this.setState({
            datePickerVisible: status,
        });
    };

    /**
     * Close date picker
     * @private
     */
    _closeDatePicker = (event) => {
        const date_input = this._dateInputRef.current;

        if (date_input && !date_input.contains(event.target)) {
            this._setDatePickerVisibility(false);
        }
    };

    /**
     * On date picker focus
     */
    _onDatePickerFocus = (value, event) => {
        this._setDatePickerVisibility(true);

        if (typeof this.props.onFocus === "function") {
            this.props.onFocus(value, event);
        }
    };

    /**
     * Show previous month
     * @private
     */
    _showPreviousMonth = () => {
        this.setState({
            datePickerDate: moment(this.state.datePickerDate).subtract(1, "months"),
        });
    };

    /**
     * Show next month
     * @private
     */
    _showNextMonth = () => {
        this.setState({
            datePickerDate: moment(this.state.datePickerDate).add(1, "months"),
        });
    };

    /**
     * Select time in time picker
     * @private
     */
    _selectTime = () => {
        //if (typeof this.props.onChange === 'function') {
        //    this.props.onChange(this.state.value);
        //}
    };

    /**
     * Set current time
     * @private
     */
    _setCurrentTime = () => {
        //if (typeof this.props.onChange === 'function') {
        //    this.props.onChange(this.state.value);
        //}
    };

    /**
     * Reset time field
     */
    _resetTime = () => {
        //if (typeof this.props.onChange === 'function') {
        //    this.props.onChange(this.state.value);
        //}
    };

    /**
     * Parse time from time field
     * @private
     */
    _parseTime = (fieldValue) => {
        //if (typeof this.props.onChange === 'function') {
        //    this.props.onChange(this.state.value);
        //}
    };

    /**
     * Set time picker visibility
     * @private
     */
    _setTimePickerVisibility = (status) => {
        this.setState({
            timePickerVisible: status,
        });
    };

    /**
     * Close time picker
     * @private
     */
    _closeTimePicker = (event) => {
        const time_input = this._timeInputRef.current;

        if (time_input && !time_input.contains(event.target)) {
            this._setTimePickerVisibility(false);
        }
    };

    /**
     * On time picker focus
     */
    _onTimePickerFocus = (event) => {
        this._setTimePickerVisibility(true);

        if (typeof this.props.onFocus === "function") {
            this.props.onFocus(event.target.value, event);
        }
    };

    /**
     * On blur
     * @private
     */
    _onBlur = (value, event) => {
        if (typeof this.props.onBlur === "function") {
            this.props.onBlur(value, event);
        }
    };
}
