"use strict";

import moment from "moment";
import * as Request from "./Request";
import ApiEndpoints from "../constants/ApiEndpoints";

export default {
    /**
     * Get driver collection
     * @param {Object} params
     * @param {Object} queryParams
     * @return {*}
     */
    getDriverCollection: (params = {}, queryParams = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.SEARCH_DRIVERS,
            method: "GET",
            qs: queryParams,
        });
    },

    /**
     * Create driver
     * @param {Object} params
     * @param {Driver} params.driver
     * @return {*}
     */
    createDriver: (params = {}) => {
        var driver = params.driver;

        return Request.getAuthRequestPromise({
            url: ApiEndpoints.CREATE_DRIVER,
            method: "POST",
            body: {
                driver: {
                    lastName: driver.lastName,
                    firstName: driver.firstName,
                    nickname: driver.nickname,
                    employmentStart: driver.employmentStart
                        ? driver.employmentStart.format("YYYY-MM-DD")
                        : moment().format("YYYY-MM-DD"),
                    employmentEnd: driver.employmentEnd ? driver.employmentEnd.format("YYYY-MM-DD") : null,
                    isDriver: driver.isDriver,
                    isCoDriver: driver.isCoDriver,
                    isPacker: driver.isPacker,
                    driverGroup: driver.driverGroup ? driver.driverGroup.id : null,
                    weekDays: driver.weekDays,
                    isFlexible: driver.isFlexible,
                    knownTours: driver.knownTours.map((tour) => {
                        return tour.id;
                    }),
                    reservedTour: driver.reservedTour ? driver.reservedTour.id : null,
                },
            },
        });
    },

    /**
     * Get driver
     * @param {Object} params
     * @param {number} params.driverId
     * @return {*}
     */
    getDriver: (params = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.GET_DRIVER(params.driverId),
            method: "GET",
        });
    },

    /**
     * Update driver
     * @param {Object} params
     * @param {Driver} params.driver
     * @return {*}
     */
    updateDriver: (params = {}) => {
        var driver = params.driver;

        return Request.getAuthRequestPromise({
            url: ApiEndpoints.UPDATE_DRIVER(driver.id),
            method: "PUT",
            body: {
                driver: {
                    lastName: driver.lastName,
                    firstName: driver.firstName,
                    nickname: driver.nickname,
                    employmentStart: driver.employmentStart ? driver.employmentStart.format("YYYY-MM-DD") : null,
                    employmentEnd: driver.employmentEnd ? driver.employmentEnd.format("YYYY-MM-DD") : null,
                    isDriver: driver.isDriver,
                    isCoDriver: driver.isCoDriver,
                    isPacker: driver.isPacker,
                    driverGroup: driver.driverGroup ? driver.driverGroup.id : null,
                    weekDays: driver.weekDays,
                    isFlexible: driver.isFlexible,
                    knownTours: driver.knownTours.map((tour) => {
                        return tour.id;
                    }),
                    reservedTour: driver.reservedTour ? driver.reservedTour.id : null,
                },
            },
        });
    },

    /**
     * Remove driver
     * @param {Object} params
     * @param {number} params.driverId
     * @return {*}
     */
    removeDriver: (params = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.REMOVE_DRIVER(params.driverId),
            method: "DELETE",
        });
    },
};
