"use strict";

import Immutable from "immutable";

/**
 * Tour type
 *
 * @typedef {Immutable.Record} FieldRecord
 * @property {(object|string|number)} key
 * @property {string} name
 * @property {?string} type
 * @property {?Array} errors
 * @property {Object} options
 * @property {string} options.label
 * @property {bool} options.alignLabel
 * @property {string} options.description
 */

/**
 * Tour record
 *
 * @type {Tour}
 */
var FieldRecord = Immutable.Record({
    key: null,
    name: "",
    type: null,
    errors: null,
    options: {},
});

export default FieldRecord;
