"use strict";

import React from "react";
import AuthActionCreators from "../actions/AuthActions";
import AuthStore from "../stores/AuthStore";
import LoginForm from "../forms/LoginForm";
import Form from "../ui/elements/Form.react";
import ActionBar from "../ui/elements/ActionBar.react";
import Button from "../ui/elements/Button.react";

/**
 * LoginPage component
 */
export default class LoginPage extends React.Component {
    /**
     * React: state
     */
    state = {
        data: {},
        updatePending: false,
    };

    /**
     * Form ref
     * @type {Object|null}
     * @private
     */
    _form = null;

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AuthStore.addListener(AuthStore.STORE_UPDATED, this._setFormUpdateStatus);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AuthStore.removeListener(AuthStore.STORE_UPDATED, this._setFormUpdateStatus);
        this.setState({ updatePending: false });
    }

    /**
     * React: render
     */
    render() {
        return (
            <div className="login-page">
                <Form
                    type={new LoginForm()}
                    data={this.state.data}
                    formRef={(form) => {
                        this._form = form;
                    }}
                    action={this._loginUser}
                    submitOnEnter={true}
                />
                <ActionBar>
                    <ActionBar.Actions>
                        <Button
                            size="large"
                            onClick={() => {
                                this._form.submit();
                            }}
                            updatePending={this.state.updatePending}
                        >
                            Login
                        </Button>
                    </ActionBar.Actions>
                </ActionBar>
                {/* <a href="#">Passwort vergessen?</a> */}
            </div>
        );
    }

    /**
     * Set form's update status
     */
    _setFormUpdateStatus = () => {
        this._form.setDisabled(AuthStore.updatePending);
        if (AuthStore.error !== null) this._form.setError(AuthStore.error);
        this.setState({ updatePending: AuthStore.updatePending });
    };

    /**
     * Login user
     *
     * @param {Object} formData
     */
    _loginUser = (formData) => {
        if (!AuthStore.updatePending) {
            AuthActionCreators.loginUser(formData.username || "", formData.password || "");
        }
        this.setState({ data: formData });
    };
}
