"use strict";

import React from "react";
import { NavLink } from "react-router-dom";
import AuthActionCreators from "../actions/AuthActions";

/**
 * Menu component
 */
export default class Menu extends React.Component {
    /**
     * React: render
     */
    render() {
        return (
            <nav className="menu">
                <ul className="links">
                    <li>
                        <NavLink to="schedule">Planung</NavLink>
                    </li>
                    <li>
                        <NavLink to="drivers">Fahrer</NavLink>
                    </li>
                    <li>
                        <NavLink to="tours">Touren</NavLink>
                    </li>
                    <li>
                        <NavLink to="calendar">Kalender</NavLink>
                    </li>
                </ul>
                <div className="user">
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            AuthActionCreators.logoutUser();
                        }}
                    >
                        abmelden
                    </a>
                </div>
            </nav>
        );
    }
}
