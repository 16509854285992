"use strict";

import moment from "moment";
import Immutable from "immutable";
import TourDate from "../types/TourDate";
import ActionTypes from "../constants/ActionTypes";
import EntityStore from "./EntityStore";

/**
 * Flux: TourDateStore
 */
class TourDateStore extends EntityStore {
    /**
     * Store events
     */
    TOUR_DATE_CREATED = (tourId, calendarDate) => `tour_date_${tourId}_${calendarDate}_created`;

    /**
     * tourDateCollection hash
     *
     * @type {Immutable.Map<string, TourDate>}
     * @private
     */
    _tourDateCollection = Immutable.Map();

    /**
     * tourDates by tour hash
     *
     * @type {Immutable.Map<string, TourDate>}
     * @private
     */
    _tourDatesByTour = Immutable.Map();

    /**
     * tourDates by driver hash
     *
     * @type {Immutable.Map<string, TourDate>}
     * @private
     */
    _tourDatesByDriver = Immutable.Map();

    /**
     * tourDates by coDriver hash
     *
     * @type {Immutable.Map<string, TourDate>}
     * @private
     */
    _tourDatesByCoDriver = Immutable.Map();

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));

        this._error = null;
    }

    /**
     * Flux: register store to actions
     *
     * @param {*} action
     * @private
     */
    _registerToActions(action) {
        switch (action.type) {
            // get schedule
            case ActionTypes.REQUEST_GET_SCHEDULE:
                this._error = null;
                break;

            case ActionTypes.REQUEST_GET_SCHEDULE_SUCCESS:
                this._setTourDateCollection(action.body.schedule.tourDates);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_GET_SCHEDULE_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            // create schedule
            case ActionTypes.REQUEST_CREATE_SCHEDULE:
                this._error = null;
                break;

            case ActionTypes.REQUEST_CREATE_SCHEDULE_SUCCESS:
                this._setTourDateCollection(action.body.schedule.tourDates);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_CREATE_SCHEDULE_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            // remove schedule
            case ActionTypes.REQUEST_REMOVE_SCHEDULE:
                this._error = null;
                break;

            case ActionTypes.REQUEST_REMOVE_SCHEDULE_SUCCESS:
                this._removeTourDatesForPeriod(moment(action.startDate), moment(action.endDate));
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_REMOVE_SCHEDULE_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            // create tourDate
            case ActionTypes.REQUEST_CREATE_TOUR_DATE:
                var tourDate = new TourDate({ updatePending: true });
                this._tourDatesByTour = this._tourDatesByTour.set(action.tourId + "-" + action.calendarDate, tourDate);
                this._error = null;
                this.emit(this.TOUR_DATE_CREATED(action.tourId, action.calendarDate));
                break;

            case ActionTypes.REQUEST_CREATE_TOUR_DATE_SUCCESS:
                this._setTourDate(action.body.tourDate);
                this._error = null;
                this.emit(this.TOUR_DATE_CREATED(action.tourId, action.calendarDate));
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_CREATE_TOUR_DATE_ERROR:
                this._tourDatesByTour = this._tourDatesByTour.delete(action.tourId + "-" + action.calendarDate);
                this._error = action.error;
                this.emit(this.TOUR_DATE_CREATED(action.tourId, action.calendarDate));
                break;

            // get tourDate
            case ActionTypes.REQUEST_GET_TOUR_DATE:
                this._toggleUpdatePending(action.tourDateId);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.tourDateId));
                break;

            case ActionTypes.REQUEST_GET_TOUR_DATE_SUCCESS:
                this._setTourDate(action.body.tourDate);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.body.tourDate.id));
                break;

            case ActionTypes.REQUEST_GET_TOUR_DATE_ERROR:
                this._toggleUpdatePending(action.tourDateId);
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.tourDateId));
                break;

            // update tourDate
            case ActionTypes.REQUEST_UPDATE_TOUR_DATE:
                this._toggleUpdatePending(action.tourDateId);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.tourDateId));
                break;

            case ActionTypes.REQUEST_UPDATE_TOUR_DATE_SUCCESS:
                this._setTourDate(action.body.tourDate);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.body.tourDate.id));
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_UPDATE_TOUR_DATE_ERROR:
                this._toggleUpdatePending(action.tourDateId);
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.tourDateId));
                break;

            // update tourDate driver
            case ActionTypes.REQUEST_UPDATE_TOUR_DATE_DRIVER:
                this._toggleUpdatePending(action.tourDateId);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.tourDateId));
                break;

            case ActionTypes.REQUEST_UPDATE_TOUR_DATE_DRIVER_SUCCESS:
                this._setTourDate(action.body.tourDate);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.body.tourDate.id));
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_UPDATE_TOUR_DATE_DRIVER_ERROR:
                this._toggleUpdatePending(action.tourDateId);
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.tourDateId));
                break;

            default:
                break;
        }
    }

    /**
     * Set _tourDateCollection
     *
     * @param {Array.<TourDate>} responseTourDates
     * @private
     */
    _setTourDateCollection(responseTourDates) {
        this._tourDateCollection = Immutable.Map();

        responseTourDates.map((responseTourDate) => {
            this._setTourDate(responseTourDate, false);
        });

        this._rebuildTourDateCollectionHash();
    }

    /**
     * Set _tourDateCollection[key]
     *
     * @params {TourDate} responseTourDate
     * @params {bool} rebuildHash
     * @private
     */
    _setTourDate(responseTourDate, rebuildHash = true) {
        var tourDate = new TourDate({
            ...responseTourDate,
            calendarDate: moment(responseTourDate.calendarDate),
        });

        this._tourDateCollection = this._tourDateCollection.set(responseTourDate.id, tourDate);
        if (rebuildHash) {
            this._rebuildTourDateCollectionHash();
        }
    }

    /**
     * Rebuilds tourDate collection hashes
     *
     * @private
     */
    _rebuildTourDateCollectionHash() {
        this._tourDatesByTour = Immutable.Map();
        this._tourDatesByDriver = Immutable.Map();
        this._tourDatesByCoDriver = Immutable.Map();

        this._tourDateCollection.forEach((tourDate) => {
            let dateHash = tourDate.calendarDate.format("YYYY-MM-DD");

            this._tourDatesByTour = this._tourDatesByTour.set(tourDate.tour.id + "-" + dateHash, tourDate);
            if (tourDate.driver !== null) {
                this._tourDatesByDriver = this._tourDatesByDriver.set(tourDate.driver.id + "-" + dateHash, tourDate);
            }
            if (tourDate.coDriver !== null) {
                this._tourDatesByCoDriver = this._tourDatesByCoDriver.set(
                    tourDate.coDriver.id + "-" + dateHash,
                    tourDate
                );
            }
        });
    }

    /**
     * Remove _tourDateCollection for period
     *
     * @param {Object} startDate
     * @param {Object} endDate
     * @private
     */
    _removeTourDatesForPeriod(startDate, endDate) {
        this._tourDateCollection = this._tourDateCollection.filter((tourDate) => {
            return !(
                tourDate.calendarDate.isBetween(startDate, endDate, "day") ||
                tourDate.calendarDate.isSame(startDate, "day") ||
                tourDate.calendarDate.isSame(endDate, "day")
            );
        });

        this._rebuildTourDateCollectionHash();
    }

    /**
     * Set updatePending flag on tourDate
     *
     * @param {number} tourDateId
     * @private
     */
    _toggleUpdatePending(tourDateId) {
        var tourDate = this._tourDateCollection.get(tourDateId);
        tourDate = tourDate.set("updatePending", !tourDate.get("updatePending"));
        this._tourDateCollection = this._tourDateCollection.set(tourDateId, tourDate);
    }

    /**
     * Get tourDates for period
     *
     * @param {Object} startDate
     * @param {Object} endDate
     * @param {string} hashName
     * @return {Immutable.Map<string, TourDate>}
     */
    getTourDatesForPeriod(startDate, endDate, hashName) {
        // filter tourDates for period
        return this[hashName].filter((tourDate) => {
            return (
                tourDate.calendarDate.isBetween(startDate, endDate, "day") ||
                tourDate.calendarDate.isSame(startDate, "day") ||
                tourDate.calendarDate.isSame(endDate, "day")
            );
        });
    }

    /**
     * Get _tourDateCollection
     *
     * @returns {Immutable.Map<string, TourDate>}
     */
    get tourDateCollection() {
        return this._tourDateCollection;
    }

    /**
     * Get _tourDatesByTour
     *
     * @returns {Immutable.Map<string, TourDate>}
     */
    get tourDatesByTour() {
        return this._tourDatesByTour;
    }

    /**
     * Get _tourDatesByDriver
     *
     * @returns {Immutable.Map<string, TourDate>}
     */
    get tourDatesByDriver() {
        return this._tourDatesByDriver;
    }

    /**
     * Get _tourDatesByCoDriver
     *
     * @returns {Immutable.Map<string, TourDate>}
     */
    get tourDatesByCoDriver() {
        return this._tourDatesByCoDriver;
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new TourDateStore();
