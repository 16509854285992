"use strict";

/**
 * Translation strings
 * iTODO move all strings to dictionary
 * @type {*}
 */
var Lang = {
    // Tests
    "test.with_params": (params) => `Test string ${params["test"]}`,

    // UI
    "deadline.days_before.0": "gleicher Tag",
    "deadline.days_before.1": "Vortag",
    "deadline.days_before.2": "2 Tage vorher",
    "deadline.days_before.3": "3 Tage vorher",
    "deadline.days_before.4": "4 Tage vorher",
    "deadline.days_before.5": "5 Tage vorher",
    "deadline.days_before.6": "6 Tage vorher",
    "deadline.days_before.7": "7 Tage vorher",

    // Other driver tiles
    "driver.reserve": "99 Reserve",
    "driver.former_employee": "Ehemaliger Angestellter",
    "driver.flexible": "Flex",
    "driver.free": "Frei",

    // Driver absence tiles
    "driver.absence.unknown": "Unbekannt",
    "driver.absence.sick_leave": "Krank",
    "driver.absence.accident": "Unfall",
    "driver.absence.unpaid": "Unbezahlte Absenz",
    "driver.absence.paid": "Bezahlte Absenz",
    "driver.absence.vacation": "Ferien",
    "driver.absence.paid_vacation": "Bezahlte Ferien",
    "driver.absence.compensation": "Kompensation",
    "driver.absence.flex_free": "Flex Frei",

    // Form errors
    "bad.credentials": "Benutzername oder Passwort ungültig",
    "validation.failed": "Überprüfungsfehler",
    "invalid.dates.startdate.should.be.before.enddate": "Falsches Datum",
    "invalid.absence.type": "Ungültig Absenztyp",

    // General
    "login.server.error": "Ein Serverfehler ist aufgetreten",
    "invalid.credentials": "Benutzername oder Kennwort sind ungültig",
};

export default Lang;
