"use strict";

import React from "react";
import Text from "./Text.react";

/**
 * Hidden component
 */
export default class Hidden extends React.Component {
    /**
     * React: render
     */
    render() {
        return <Text {...this.props} type="hidden" />;
    }
}
