"use strict";

import React from "react";
import moment from "moment";
import { extendMoment } from "moment-range";
import { debounce } from "../lib/Utils";
import AuthStore from "../stores/AuthStore";
import ApiEndpoints from "../constants/ApiEndpoints";
import AppActions from "../actions/AppActions";
import DriverActions from "../actions/DriverActions";
import TourDateActions from "../actions/TourDateActions";
import DriverAbsenceActions from "../actions/DriverAbsenceActions";
import CalendarEventActions from "../actions/CalendarEventActions";
import AppStore from "../stores/AppStore";
import DriverStore from "../stores/DriverStore";
import TourDateStore from "../stores/TourDateStore";
import DriverAbsenceStore from "../stores/DriverAbsenceStore";
import CalendarEventStore from "../stores/CalendarEventStore";
import ActionBar from "../ui/elements/ActionBar.react";
import DateNavigation from "../ui/elements/DateNavigation.react";
import Button from "../ui/elements/Button.react";
import LoadingAnimation from "../ui/elements/LoadingAnimation.react";
import FlashBar from "../ui/elements/FlashBar.react";
import Text from "../ui/elements/Text.react";
import Checkbox from "../ui/elements/Checkbox.react";
import ScheduleTable from "./ScheduleTable.react";
import TourDateDriverCell from "./TourDateDriverCell.react";
import DriverModal from "./DriverModal.react";
import DriverAbsenceModal from "./DriverAbsenceModal.react";
import DriverGroupOverviewModal from "./DriverGroupOverviewModal.react";

/**
 * DriversPage component
 */
export default class DriversPage extends React.Component {
    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this._setFilterQueryString = debounce(this._setFilterQueryString, 350);
    }

    /**
     * React: state
     */
    state = {
        startDate: AppStore.scheduleDates.startDate,
        endDate: AppStore.scheduleDates.endDate,
        drivers: null,
        tourDatesForPeriod: null,
        tourDatesForPeriodCoDrivers: null,
        driverAbsenceForPeriod: null,
        calendarEventsForPeriod: null,
        filterReserveDays: false,
        filterQueryString: "",
        driverToUpdate: null,
        weekDayToUpdate: null,
        isDriverModalVisible: false,
        driverAbsenceToUpdate: null,
        isDriverAbsenceModalVisible: false,
        driverGroupToView: null,
        isDriverGroupOverviewModalVisible: false,
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        // add store listeners
        DriverStore.addListener(DriverStore.ENTITY_COLLECTION_UPDATED, this._setDrivers);
        TourDateStore.addListener(TourDateStore.ENTITY_COLLECTION_UPDATED, this._setSchedule);
        DriverAbsenceStore.addListener(DriverAbsenceStore.ENTITY_COLLECTION_UPDATED, this._setDriverAbsenceForPeriod);
        CalendarEventStore.addListener(CalendarEventStore.ENTITY_COLLECTION_UPDATED, this._setCalendarEvents);

        // get tours, tourDates, driverAbsence and calendarEvents for period
        this._getDrivers();
        this._getSchedule();
        this._getDriverAbsenceForPeriod();
        this._getCalendarEvents();
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        // remove store listeners
        DriverStore.removeListener(DriverStore.ENTITY_COLLECTION_UPDATED, this._setDrivers);
        TourDateStore.removeListener(TourDateStore.ENTITY_COLLECTION_UPDATED, this._setSchedule);
        DriverAbsenceStore.removeListener(
            DriverAbsenceStore.ENTITY_COLLECTION_UPDATED,
            this._setDriverAbsenceForPeriod
        );
        CalendarEventStore.removeListener(CalendarEventStore.ENTITY_COLLECTION_UPDATED, this._setCalendarEvents);
    }

    /**
     * React: render
     */
    render() {
        var tabularContent = null;
        var displayScheduleFilter = false;
        var week = extendMoment(moment).range(this.state.startDate, this.state.endDate);
        var reserveDays = [];

        // schedule loading
        if (
            this.state.drivers === null ||
            this.state.tourDatesForPeriod === null ||
            this.state.driverAbsenceForPeriod === null ||
            this.state.calendarEventsForPeriod === null
        ) {
            tabularContent = (
                <div className="content-loading">
                    <LoadingAnimation />
                </div>
            );

            // schedule loaded
        } else {
            displayScheduleFilter = true;

            var tableRows = [];
            var visibleTableRows = 0;

            this.state.drivers.forEach((driver) => {
                let driverHasReserveDays = false;
                let driverQuerySearchIndex = driver.nickname + "-" + driver.firstName + "-" + driver.lastName;
                let scheduleEmpty = this.state.tourDatesForPeriod.size === 0;

                let rowCells = [];
                for (const weekDay of week.by("days")) {
                    let key = driver.id + "-" + weekDay.format("YYYY-MM-DD");
                    let tourDate = this.state.tourDatesForPeriod.get(key);
                    let driverAbsence = this.state.driverAbsenceForPeriod.get(key);

                    // add hash key to list of reserve days if
                    // schedule IS NOT empty, driver IS driving on date, IS NOT absent and IS unassigned
                    if (!scheduleEmpty && driver.weekDays[weekDay.isoWeekday()] && !driverAbsence && !tourDate) {
                        reserveDays.push(key);
                        driverHasReserveDays = true;
                    }

                    if (tourDate != null) {
                        driverQuerySearchIndex += "-" + tourDate.tour.tourName;
                    }

                    rowCells.push(
                        <TourDateDriverCell
                            key={key}
                            driver={driver}
                            week={week}
                            weekDay={weekDay}
                            tourDatesForPeriod={this.state.tourDatesForPeriod}
                            tourDatesForPeriodCoDrivers={this.state.tourDatesForPeriodCoDrivers}
                            driverAbsenceForPeriod={this.state.driverAbsenceForPeriod}
                            calendarEventsForPeriod={this.state.calendarEventsForPeriod}
                            toggleDriverAbsenceModal={this._toggleDriverAbsenceModal}
                        />
                    );
                }

                // display row if reserve filter IS NOT on
                // display tour row if filterQueryString IS NOT set or active
                let filterInactive = !this.state.filterReserveDays && this.state.filterQueryString === "";

                // display row if reserve days filter IS on and reserve days  EXIST
                let filterReserveDaysMatched = !filterInactive && this.state.filterReserveDays && driverHasReserveDays;

                // display tour row if filterQueryString IS set and active and driver data matches it
                let filterQueryStringMatched =
                    !filterInactive &&
                    !this.state.filterReserveDays &&
                    this.state.filterQueryString !== "" &&
                    driverQuerySearchIndex.toLowerCase().indexOf(this.state.filterQueryString.toLowerCase()) !== -1;

                if (filterInactive || filterReserveDaysMatched || filterQueryStringMatched) {
                    visibleTableRows++;
                }

                tableRows.push(
                    <tr
                        key={driver.id}
                        style={
                            filterInactive || filterReserveDaysMatched || filterQueryStringMatched
                                ? null
                                : { display: "none" }
                        }
                    >
                        <td className="driver-name">
                            <span onClick={this._toggleDriverModal.bind(this, driver)}>{driver.nickname}</span>
                            <br />
                            {!driver.driverGroup ? null : (
                                <span
                                    onClick={this._toggleDriverGroupOverviewModal.bind(this, driver.driverGroup)}
                                    className="group"
                                >
                                    {driver.driverGroup.groupName}&nbsp;
                                    {driver.driverGroup.isRemoved ? "(gelöscht)" : null}
                                </span>
                            )}
                        </td>
                        <td />
                        <td />
                        {rowCells}
                    </tr>
                );
            });

            if (visibleTableRows === 0) {
                tableRows.push(
                    <tr key="empty-list">
                        <td className="empty-list" colSpan="10">
                            Liste ist leer.
                        </td>
                    </tr>
                );
            }

            tabularContent = (
                <ScheduleTable startDate={this.state.startDate} endDate={this.state.endDate}>
                    {tableRows}
                </ScheduleTable>
            );
        }

        return (
            <div className="drivers-page">
                <ActionBar>
                    <ActionBar.Actions type="secondary">
                        <DateNavigation
                            navigateBy="week"
                            startDate={this.state.startDate}
                            weekFormatTo="[bis]"
                            onPrevious={this._onDateChange}
                            onNext={this._onDateChange}
                        />
                    </ActionBar.Actions>
                    <ActionBar.Actions type="primary">
                        <Button
                            size="large"
                            disabled={
                                this.state.drivers === null ||
                                this.state.tourDatesForPeriod === null ||
                                this.state.driverAbsenceForPeriod === null
                            }
                            onClick={this._exportSchedule}
                        >
                            Export
                        </Button>
                        <Button
                            size="large"
                            disabled={
                                this.state.drivers === null ||
                                this.state.tourDatesForPeriod === null ||
                                this.state.driverAbsenceForPeriod === null
                            }
                            onClick={this._toggleDriverModal.bind(this, null)}
                        >
                            Neuer Fahrer
                        </Button>
                    </ActionBar.Actions>
                </ActionBar>

                <FlashBar isVisible={reserveDays.length > 0} type="warning">
                    <span>
                        <em>{reserveDays.length} Fahrertage</em> sind in dieser Woche in Reserve.
                    </span>
                </FlashBar>

                {!displayScheduleFilter ? null : (
                    <ActionBar>
                        <ActionBar.Actions type="secondary">
                            <div className="query-string-filter">
                                <label>Filter</label>
                                <Text
                                    placeholder="Touren oder Fahrer.."
                                    value={this.state.filterReserveDays ? "" : this.state.filterQueryString}
                                    disabled={this.state.filterReserveDays}
                                    onChange={this._setFilterQueryString}
                                    width="3"
                                />
                            </div>
                            <Checkbox
                                label="Fahrer ohne Touren"
                                value={this.state.filterReserveDays}
                                onChange={this._toggleFilterReserveDays}
                            />
                        </ActionBar.Actions>
                    </ActionBar>
                )}

                {tabularContent}

                {this.props.children}

                <DriverModal
                    isVisible={this.state.isDriverModalVisible}
                    driver={this.state.driverToUpdate}
                    toggleDriverModal={this._toggleDriverModal}
                />

                <DriverAbsenceModal
                    isVisible={this.state.isDriverAbsenceModalVisible}
                    driver={this.state.driverToUpdate}
                    driverAbsence={this.state.driverAbsenceToUpdate}
                    weekDay={this.state.weekDayToUpdate}
                    toggleDriverAbsenceModal={this._toggleDriverAbsenceModal}
                />

                <DriverGroupOverviewModal
                    isVisible={this.state.isDriverGroupOverviewModalVisible}
                    driverGroup={this.state.driverGroupToView}
                    toggleDriverGroupOverviewModal={this._toggleDriverGroupOverviewModal}
                />
            </div>
        );
    }

    /**
     * Get drivers
     */
    _getDrivers = () => {
        DriverActions.getDriverCollection({}, { maxResults: 1000 });
    };

    /**
     * Set tours
     */
    _setDrivers = () => {
        this.setState({
            drivers: DriverStore.driverCollection.filter((driver) => {
                // filter out drivers that are not employed
                return driver.employmentEnd ? !driver.employmentEnd.isBefore(this.state.startDate) : true;
            }),
        });
    };

    /**
     * Get schedule (tourDates) for time period
     */
    _getSchedule = () => {
        TourDateActions.getSchedule({
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD"),
        });
    };

    /**
     * Load tourDates from store for time period (schedule)
     */
    _setSchedule = () => {
        var tourDatesForPeriod = TourDateStore.getTourDatesForPeriod(
            this.state.startDate,
            this.state.endDate,
            "_tourDatesByDriver"
        );
        var tourDatesForPeriodCoDrivers = TourDateStore.getTourDatesForPeriod(
            this.state.startDate,
            this.state.endDate,
            "_tourDatesByCoDriver"
        );

        this.setState({
            tourDatesForPeriod: tourDatesForPeriod,
            tourDatesForPeriodCoDrivers: tourDatesForPeriodCoDrivers,
        });
    };

    /**
     * Get driverAbsence for time period
     */
    _getDriverAbsenceForPeriod = () => {
        DriverAbsenceActions.getDriverAbsenceCollection(
            {},
            {
                startDate: this.state.startDate.format("YYYY-MM-DD"),
                endDate: this.state.endDate.format("YYYY-MM-DD"),
            }
        );
    };

    /**
     * Load driverAbsence from store for time period
     */
    _setDriverAbsenceForPeriod = () => {
        var driverAbsenceForPeriod = DriverAbsenceStore.getDriverAbsenceForPeriod(
            this.state.startDate,
            this.state.endDate,
            "_driverAbsenceByDriver"
        );

        this.setState({
            driverAbsenceForPeriod: driverAbsenceForPeriod,
        });
    };

    /**
     * Get calendar (calendarEvents) for time period
     */
    _getCalendarEvents = () => {
        CalendarEventActions.getCalendarEventCollection(
            {},
            {
                startDate: this.state.startDate.format("YYYY-MM-DD"),
                endDate: this.state.endDate.format("YYYY-MM-DD"),
            }
        );
    };

    /**
     * Load calendarEvents from store for time period (calendar)
     */
    _setCalendarEvents = () => {
        var calendarEventsForPeriod = CalendarEventStore.getCalendarEventsForPeriod(
            this.state.startDate,
            this.state.endDate,
            "_calendarEventsByDate"
        );

        this.setState({
            calendarEventsForPeriod: calendarEventsForPeriod,
        });
    };

    /**
     * Change time period
     *
     * @param {Object} startDate
     * @param {Object} endDate
     */
    _onDateChange = (startDate, endDate) => {
        AppActions.storeScheduleDateSelection({
            startDate: startDate,
            endDate: endDate,
        });

        var DriversPage = this;
        this.setState(
            {
                startDate: startDate,
                endDate: endDate,
                drivers: null,
                tourDatesForPeriod: null,
                tourDatesForPeriodCoDrivers: null,
                driverAbsenceForPeriod: null,
                calendarEventsForPeriod: null,
            },
            () => {
                DriversPage._getDrivers();
                DriversPage._getSchedule();
                DriversPage._getDriverAbsenceForPeriod();
                DriversPage._getCalendarEvents();
            }
        );
    };

    /**
     * Set filter query
     * @param {string} queryString
     */
    _setFilterQueryString = (queryString) => {
        this.setState({
            filterQueryString: queryString,
        });
    };

    /**
     * Toggle filter for reserve driver days
     */
    _toggleFilterReserveDays = (checked) => {
        this.setState({
            filterReserveDays: checked,
        });
    };

    /**
     * Toggle driver update modal
     *
     * @params {Driver} driver
     */
    _toggleDriverModal = (driver) => {
        this.setState({
            isDriverModalVisible: !this.state.isDriverModalVisible,
            driverToUpdate: driver,
        });
    };

    /**
     * Toggle driverAbsence modal
     *
     * @params {Driver} driver
     * @params {DriverAbsence} driverAbsence
     * @params {Object} weekDay
     */
    _toggleDriverAbsenceModal = (driver, driverAbsence, weekDay) => {
        this.setState({
            driverToUpdate: driver,
            driverAbsenceToUpdate: driverAbsence,
            weekDayToUpdate: weekDay,
            isDriverAbsenceModalVisible: !this.state.isDriverAbsenceModalVisible,
        });
    };

    /**
     * Toggle driverGroup overview modal
     *
     * @params {DriverGroup} driverGroup
     */
    _toggleDriverGroupOverviewModal = (driverGroup) => {
        this.setState({
            isDriverGroupOverviewModalVisible: !this.state.isDriverGroupOverviewModalVisible,
            driverGroupToView: driverGroup,
        });
    };

    /**
     * Export schedule
     */
    _exportSchedule = () => {
        var exportWindow = window.open(
            ApiEndpoints.EXPORT_SCHEDULE(
                this.state.startDate.format("YYYY-MM-DD"),
                this.state.endDate.format("YYYY-MM-DD"),
                AuthStore.jwt
            ),
            "_blank"
        );
        exportWindow.focus();
    };
}
