"use strict";

import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import { createRoot } from "react-dom/client";
import { SecuredRouter } from "./routes/SecuredRouter";
import ApiEndpoints from "./constants/ApiEndpoints";
import AppConstants from "./constants/AppConstants";
import * as Sentry from "@sentry/browser";

fetch(ApiEndpoints.SENTRY_CONFIG)
    .then((response) => response.json())
    .then((sentry_data) => {
        if (sentry_data.dsn) {
            Sentry.init({
                dsn: sentry_data.dsn,
                release: "tour-planner@" + AppConstants.BUILD_FULL,
                environment: sentry_data.env,
            });
        }
    })
    .catch(() => {
        /* ignore issues getting sentry config */
    });

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<SecuredRouter />);
