"use strict";

import * as Request from "./Request";
import ApiEndpoints from "../constants/ApiEndpoints";

export default {
    /**
     * Get driverAbsence collection
     * @param {Object} params
     * @param {Object} queryParams
     * @return {*}
     */
    getDriverAbsenceCollection: (params = {}, queryParams = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.SEARCH_DRIVER_ABSENCE,
            method: "GET",
            qs: queryParams,
        });
    },

    /**
     * Create driverAbsence
     * @param {Object} params
     * @param {DriverAbsence} params.driverAbsence
     * @return {*}
     */
    createDriverAbsence: (params = {}) => {
        var driverAbsence = params.driverAbsence;

        return Request.getAuthRequestPromise({
            url: ApiEndpoints.CREATE_DRIVER_ABSENCE,
            method: "POST",
            body: {
                driverAbsence: {
                    driver: driverAbsence.driver.id,
                    startDate: driverAbsence.startDate ? driverAbsence.startDate.format("YYYY-MM-DD") : null,
                    endDate: driverAbsence.endDate
                        ? driverAbsence.endDate.format("YYYY-MM-DD")
                        : driverAbsence.startDate
                        ? driverAbsence.startDate.format("YYYY-MM-DD")
                        : null,
                    absenceType: driverAbsence.absenceType,
                },
            },
        });
    },

    /**
     * Get driverAbsence
     * @param {number} params.driverAbsenceId
     * @return {*}
     */
    getDriverAbsence: (params) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.GET_DRIVER_ABSENCE(params.driverAbsenceId),
            method: "GET",
        });
    },

    /**
     * Update driverAbsence
     * @param {Object} params
     * @param {DriverAbsence} params.driverAbsence
     * @return {*}
     */
    updateDriverAbsence: (params = {}) => {
        var driverAbsence = params.driverAbsence;

        return Request.getAuthRequestPromise({
            url: ApiEndpoints.UPDATE_DRIVER_ABSENCE(driverAbsence.id),
            method: "PUT",
            body: {
                driverAbsence: {
                    driver: driverAbsence.driver.id,
                    startDate: driverAbsence.startDate ? driverAbsence.startDate.format("YYYY-MM-DD") : null,
                    endDate: driverAbsence.endDate
                        ? driverAbsence.endDate.format("YYYY-MM-DD")
                        : driverAbsence.startDate
                        ? driverAbsence.startDate.format("YYYY-MM-DD")
                        : null,
                    absenceType: driverAbsence.absenceType,
                },
            },
        });
    },

    /**
     * Remove driverAbsence
     * @param {Object} params
     * @param {number} params.driverAbsenceId
     * @return {*}
     */
    removeDriverAbsence: (params = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.REMOVE_DRIVER_ABSENCE(params.driverAbsenceId),
            method: "DELETE",
        });
    },
};
