"use strict";

import CalendarEvent from "../types/CalendarEvent";
import CalendarEventActions from "../actions/CalendarEventActions";
import CalendarEventStore from "../stores/CalendarEventStore";
import Driver from "../types/Driver";
import DriverActions from "../actions/DriverActions";
import DriverStore from "../stores/DriverStore";
import DriverAbsence from "../types/DriverAbsence";
import DriverAbsenceActions from "../actions/DriverAbsenceActions";
import DriverAbsenceStore from "../stores/DriverAbsenceStore";
import DriverGroup from "../types/DriverGroup";
import DriverGroupActions from "../actions/DriverGroupActions";
import DriverGroupStore from "../stores/DriverGroupStore";
import Tour from "../types/Tour";
import TourActions from "../actions/TourActions";
import TourStore from "../stores/TourStore";
import TourDate from "../types/TourDate";
import TourDateActions from "../actions/TourDateActions";
import TourDateStore from "../stores/TourDateStore";

var LoaderTable = {
    CalendarEvent: {
        recordClass: CalendarEvent,
        actions: CalendarEventActions,
        store: CalendarEventStore,
    },
    Driver: {
        recordClass: Driver,
        actions: DriverActions,
        store: DriverStore,
    },
    DriverAbsence: {
        recordClass: DriverAbsence,
        actions: DriverAbsenceActions,
        store: DriverAbsenceStore,
    },
    DriverGroup: {
        recordClass: DriverGroup,
        actions: DriverGroupActions,
        store: DriverGroupStore,
    },
    Tour: {
        recordClass: Tour,
        actions: TourActions,
        store: TourStore,
    },
    TourDate: {
        recordClass: TourDate,
        actions: TourDateActions,
        store: TourDateStore,
    },
};

var FluxLoader = {
    /**
     * Get recordClass from className
     */
    getRecordClass(className) {
        return LoaderTable[className].recordClass;
    },

    /**
     * Get actions from className
     */
    getActions(className) {
        return LoaderTable[className].actions;
    },

    /**
     * Get store from className
     */
    getStore(className) {
        return LoaderTable[className].store;
    },
};

export default FluxLoader;
