"use strict";

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Driver from "../types/Driver";
import DriverActions from "../actions/DriverActions";
import DriverStore from "../stores/DriverStore";
import DriverForm from "../forms/DriverForm";
import Form from "../ui/elements/Form.react";
import Modal from "../ui/elements/Modal.react";
import LoadingAnimation from "../ui/elements/LoadingAnimation.react";

/**
 * DriverModal component
 */
export default class DriverModal extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        isVisible: PropTypes.bool,
        driver: PropTypes.instanceOf(Driver),
        toggleDriverModal: PropTypes.func.isRequired,
    };

    /**
     * React: state
     */
    state = {
        driver: null,
        updatePending: false,
        removePending: false,
    };

    /**
     * Form ref
     * @type {Object|null}
     * @private
     */
    _form = null;

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        // modal will be visible..
        if (nextProps.isVisible && this.state.driver === null) {
            // ..and showing existing driver
            if (nextProps.driver != null) {
                DriverStore.addListener(DriverStore.ENTITY_UPDATED(nextProps.driver.id), this._onDriverLoad);
                this._getDriver(nextProps.driver.id);

                // ..and showing new driver
            } else {
                this.setState({ driver: new Driver({ employmentStart: moment() }) });
            }
        }

        // modal will be hidden..
        if (!nextProps.isVisible) {
            // ..for existing driver
            if (this.props.driver !== null) {
                DriverStore.removeListener(DriverStore.ENTITY_UPDATED(this.props.driver.id), this._onDriverLoad);
                DriverStore.removeListener(DriverStore.ENTITY_UPDATED(this.props.driver.id), this._onDriverUpdate);
                DriverStore.removeListener(DriverStore.ENTITY_UPDATED(this.props.driver.id), this._onDriverRemove);
            }
            DriverStore.removeListener(DriverStore.ENTITY_COLLECTION_UPDATED, this._onDriverCreate);
            DriverStore.removeListener(DriverStore.ENTITY_COLLECTION_UPDATED, this._onDriverRemove);

            // reset state
            this.setState({ driver: null, updatePending: false, removePending: false });
            this._form = null;
        }
    }

    /**
     * React: render
     */
    render() {
        return (
            <Modal
                isVisible={this.props.isVisible}
                title={this.props.driver ? this.props.driver.nickname : "Neuer Fahrer"}
                subtitle={this.props.driver ? "Fahrerdetails" : ""}
            >
                <Modal.Content width="9">
                    {this.state.driver === null ? (
                        <div className="content-loading">
                            <LoadingAnimation />
                        </div>
                    ) : (
                        <Form
                            type={new DriverForm()}
                            data={this.state.driver}
                            formRef={(form) => {
                                this._form = form;
                            }}
                            action={this._saveDriver}
                        />
                    )}
                </Modal.Content>
                <Modal.Actions type="secondary">
                    {this.props.driver === null ? null : (
                        <Modal.ActionButton
                            actionType="negative"
                            askForConfirmation={true}
                            confirmationMessage="Wirklich löschen?"
                            onClick={this._removeDriver}
                            disabled={
                                this.state.driver === null || this.state.updatePending || this.state.removePending
                            }
                            updatePending={this.state.removePending}
                        >
                            Löschen
                        </Modal.ActionButton>
                    )}
                </Modal.Actions>
                <Modal.Actions>
                    <Modal.ActionButton
                        isSecondary={true}
                        disabled={this.state.updatePending || this.state.removePending}
                        onClick={this.props.toggleDriverModal.bind(this, null)}
                    >
                        Abbrechen
                    </Modal.ActionButton>
                    <Modal.ActionButton
                        actionType="positive"
                        onClick={() => {
                            this._form.submit();
                        }}
                        disabled={this.state.driver === null || this.state.updatePending || this.state.removePending}
                        updatePending={this.state.updatePending}
                    >
                        Speichern
                    </Modal.ActionButton>
                </Modal.Actions>
            </Modal>
        );
    }

    /**
     * Get driver
     */
    _getDriver = (driverId) => {
        DriverActions.getDriver({
            driverId: driverId,
        });
    };

    /**
     * On driver load
     * @private
     */
    _onDriverLoad = () => {
        this.setState(
            {
                driver: this.props.driver ? DriverStore.driverCollection.get(this.props.driver.id) : null,
            },
            () => {
                if (this.state.driver) {
                    DriverStore.removeListener(DriverStore.ENTITY_UPDATED(this.props.driver.id), this._onDriverLoad);
                }
            }
        );
    };

    /**
     * Save driver
     * @param {Driver} driver
     * @private
     */
    _saveDriver = (driver) => {
        this._form.setDisabled(true);
        this.setState(
            {
                driver: driver,
                updatePending: true,
            },
            () => {
                // call the action
                if (this.props.driver === null) {
                    DriverStore.addListener(DriverStore.ENTITY_COLLECTION_UPDATED, this._onDriverCreate);
                    DriverActions.createDriver({ driver: driver });
                } else {
                    DriverStore.addListener(DriverStore.ENTITY_UPDATED(this.props.driver.id), this._onDriverUpdate);
                    DriverActions.updateDriver({ driver: driver });
                }
            }
        );
    };

    /**
     * On driver create
     * @private
     */
    _onDriverCreate = () => {
        // create completed..
        if (this.state.updatePending) {
            // remove listener
            DriverStore.removeListener(DriverStore.ENTITY_COLLECTION_UPDATED, this._onDriverCreate);

            // ..with error
            if (DriverStore.error !== null) {
                this._form.setDisabled(false);
                this._form.setError(DriverStore.error);
                this.setState({
                    updatePending: false,
                });

                // ..successfully
            } else {
                this.props.toggleDriverModal(null);
            }
        }
    };

    /**
     * On driver update
     * @private
     */
    _onDriverUpdate = () => {
        var driver = DriverStore.driverCollection.get(this.state.driver.id);

        // update completed..
        if (this.state.updatePending && !driver.updatePending) {
            // remove listener
            DriverStore.removeListener(DriverStore.ENTITY_UPDATED(driver.id), this._onDriverUpdate);

            // ..with error
            if (DriverStore.error !== null) {
                this._form.setDisabled(false);
                this._form.setError(DriverStore.error);
                this.setState({
                    updatePending: false,
                });

                // ..successfully
            } else {
                this.props.toggleDriverModal(null);
            }
        }
    };

    /**
     * Remove driver
     * @private
     */
    _removeDriver = () => {
        this._form.setDisabled(true);
        this.setState(
            {
                removePending: true,
                driver: this._form.getData(),
            },
            () => {
                DriverStore.addListener(DriverStore.ENTITY_UPDATED(this.state.driver.id), this._onDriverRemove);
                DriverStore.addListener(DriverStore.ENTITY_COLLECTION_UPDATED, this._onDriverRemove);
                DriverActions.removeDriver({ driverId: this.state.driver.id });
            }
        );
    };

    /**
     * On driver remove
     * @private
     */
    _onDriverRemove = () => {
        var driver = DriverStore.driverCollection.get(this.state.driver.id) || this.state.driver;

        // remove completed..
        if (this.state.removePending && !driver.updatePending) {
            // remove listener
            DriverStore.removeListener(DriverStore.ENTITY_UPDATED(driver.id), this._onDriverRemove);
            DriverStore.removeListener(DriverStore.ENTITY_COLLECTION_UPDATED, this._onDriverRemove);

            // ..with error
            if (DriverStore.error !== null) {
                this._form.setDisabled(false);
                this._form.setError(DriverStore.error);
                this.setState({
                    removePending: false,
                });

                // ..successfully
            } else {
                this.props.toggleDriverModal(null);
            }
        }
    };
}
