"use strict";

import Immutable from "immutable";

/**
 * TourDate type
 *
 * @typedef {(Immutable.Record|Object)} TourDate
 * @property {number} id
 * @property {Object} calendarDate
 * @property {Tour} tour
 * @property {?Driver} driver
 * @property {?Driver} coDriver
 * @property {?Driver} packer
 * @property {string} status
 * @property {bool} updatePending
 */

/**
 * TourDate record
 *
 * @type {TourDate}
 */
var TourDate = Immutable.Record({
    id: null,
    calendarDate: null,
    tour: null,
    driver: null,
    coDriver: null,
    packer: null,
    status: "inactive",
    updatePending: false,
});

export default TourDate;
