"use strict";

import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

/**
 * DropDown component
 */
export default class DropDown extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        isVisible: PropTypes.bool,
        closeOnMouseLeave: PropTypes.bool,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        isVisible: false,
        closeOnMouseLeave: false,
    };

    /**
     * React: state
     */
    state = {
        isVisible: this.props.isVisible,
    };

    constructor(props) {
        super(props);
        this._dropdownRef = React.createRef();
    }

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        this.setState({
            isVisible: nextProps.isVisible,
        });
    }

    /**
     * React: componentWillUpdate
     */
    componentWillUpdate(nextProps, nextState) {
        // on component visibility change
        if (!nextState.isVisible && this.state.isVisible) {
            // remove mouseleave event on parent
            if (this.props.closeOnMouseLeave) {
                var dropDownElement = ReactDOM.findDOMNode(this);
                dropDownElement.parentNode.removeEventListener("mouseleave", this._closeOnMouseEvent, false);
            }

            // remove click event on document
            document.removeEventListener("click", this._closeOnMouseEvent, false);
        }
    }

    /**
     * React: componentDidUpdate
     */
    componentDidUpdate() {
        // on component visibility change
        if (this.state.isVisible) {
            // add mouseleave event on parent
            if (this.props.closeOnMouseLeave) {
                var dropDownElement = ReactDOM.findDOMNode(this);
                dropDownElement.parentNode.addEventListener("mouseleave", this._closeOnMouseEvent, false);
            }

            // add click event on document
            setTimeout(() => document.addEventListener("click", this._closeOnMouseEvent, false), 0);
        }
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        // component is visible therefor it has related listeners
        if (this.state.isVisible) {
            // remove mouseleave event on parent
            if (this.props.closeOnMouseLeave) {
                var dropDownElement = ReactDOM.findDOMNode(this);
                dropDownElement.parentNode.removeEventListener("mouseleave", this._closeOnMouseEvent, false);
            }

            // remove click event on document
            document.removeEventListener("click", this._closeOnMouseEvent, false);
        }
    }

    /**
     * React: render
     */
    render() {
        if (this.state.isVisible) {
            // inject closeDropDown method into children
            var Component = this;
            var componentChildren = React.Children.map(this.props.children, (child) => {
                if (child && child.type === React.createElement(Option).type) {
                    return React.cloneElement(child, {
                        closeDropDown: Component._close,
                    });
                } else {
                    return child;
                }
            });

            return (
                <div className="ui-drop-down" ref={this._dropdownRef}>
                    <ul>{componentChildren}</ul>
                </div>
            );
        } else {
            return null;
        }
    }

    /**
     * Close drop-down on mouse event
     */
    _closeOnMouseEvent = (event) => {
        var dropDown = this._dropdownRef.current;
        if (dropDown && !dropDown.contains(event.target)) {
            this.setState({
                isVisible: false,
            });

            if (typeof this.props.onClose === "function") {
                this.props.onClose();
            }
        }
    };

    /**
     * Close drop-down
     */
    _close = () => {
        this.setState({
            isVisible: false,
        });

        if (typeof this.props.onClose === "function") {
            this.props.onClose();
        }
    };
}

/**
 * DropDown.Option component
 */
class Option extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        closeDropDown: PropTypes.func,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        disabled: false,
    };

    /**
     * React: render
     */
    render() {
        var className = "" + (this.props.disabled ? " disabled" : "");

        return (
            <li className={className} onClick={this._onClick.bind(this)}>
                {this.props.children}
            </li>
        );
    }

    /**
     * On click
     */
    _onClick = () => {
        if (this.props.disabled) return;

        if (typeof this.props.onClick === "function") {
            this.props.onClick();
        }

        // close drop-down on option click
        this.props.closeDropDown();
    };
}

/**
 * DropDown.Break component
 */
class Break extends React.Component {
    /**
     * React: render
     */
    render() {
        return <li className="break"></li>;
    }
}

Object.assign(DropDown, {
    Option: Option,
    Break: Break,
});
