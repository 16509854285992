"use strict";

import React from "react";
import PropTypes from "prop-types";
import * as InternalPropTypes from "../types/PropTypes";
import Driver from "../types/Driver";
import DriverAbsence from "../types/DriverAbsence";
import DriverAbsenceActions from "../actions/DriverAbsenceActions";
import DriverAbsenceStore from "../stores/DriverAbsenceStore";
import DriverAbsenceForm from "../forms/DriverAbsenceForm";
import { translate } from "../i18n/Translate";
import Form from "../ui/elements/Form.react";
import Field from "../ui/elements/Field.react";
import Modal from "../ui/elements/Modal.react";
import Button from "../ui/elements/Button.react";
import ElementUpdatePending from "../ui/elements/ElementUpdatePending.react";
import LoadingAnimation from "../ui/elements/LoadingAnimation.react";

/**
 * DriverAbsenceModal component
 */
export default class DriverAbsenceModal extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        isVisible: PropTypes.bool,
        driver: PropTypes.instanceOf(Driver),
        driverAbsence: PropTypes.instanceOf(DriverAbsence),
        weekDay: PropTypes.oneOfType([PropTypes.object, InternalPropTypes.moment]),
        toggleDriverAbsenceModal: PropTypes.func.isRequired,
    };

    /**
     * React: state
     */
    state = {
        driverAbsence: null,
        driverAbsenceForDriver: null,
        updatePending: false,
        removePending: false,
    };

    /**
     * Form ref
     * @type {Object|null}
     * @private
     */
    _form = null;

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        // modal will be visible..
        if (nextProps.isVisible && nextProps.driver != null && this.props.driver === null) {
            // add store listeners
            DriverAbsenceStore.addListener(
                DriverAbsenceStore.ENTITY_COLLECTION_UPDATED,
                this._onDriverAbsenceCollectionLoad
            );

            // set driverAbsence from initial prop if there, otherwise create new driverAbsence
            this.setState({
                driverAbsence:
                    nextProps.driverAbsence != null
                        ? nextProps.driverAbsence
                        : new DriverAbsence({
                              driver: nextProps.driver,
                              startDate: nextProps.weekDay,
                          }),
            });

            // get all driver absence
            this._getDriverAbsenceForDriver(nextProps.driver);
        }

        // modal will be hidden..
        if (!nextProps.isVisible) {
            // remove store listeners
            if (this.state.driverAbsence !== null) {
                DriverAbsenceStore.addListener(
                    DriverAbsenceStore.ENTITY_UPDATED(this.state.driverAbsence.id),
                    this._onDriverAbsenceUpdate
                );
                DriverAbsenceStore.addListener(
                    DriverAbsenceStore.ENTITY_UPDATED(this.state.driverAbsence.id),
                    this._onDriverAbsenceRemove
                );
            }
            DriverAbsenceStore.removeListener(
                DriverAbsenceStore.ENTITY_COLLECTION_UPDATED,
                this._onDriverAbsenceCollectionLoad
            );
            DriverAbsenceStore.removeListener(
                DriverAbsenceStore.ENTITY_COLLECTION_UPDATED,
                this._onDriverAbsenceCreate
            );
            DriverAbsenceStore.removeListener(
                DriverAbsenceStore.ENTITY_COLLECTION_UPDATED,
                this._onDriverAbsenceRemove
            );

            // reset state
            this.setState({
                driverAbsence: null,
                driverAbsenceForDriver: null,
                updatePending: false,
                removePending: false,
            });
            this._form = null;
        }
    }

    /**
     * React: render
     */
    render() {
        var tabularContent = null;

        if (this.state.driverAbsenceForDriver !== null) {
            let tableRows = [];
            this.state.driverAbsenceForDriver.forEach((driverAbsence) => {
                let absencePeriod =
                    driverAbsence.startDate.format("DD.MM.YYYY") +
                    (!driverAbsence.startDate.isSame(driverAbsence.endDate, "day")
                        ? " - " + driverAbsence.endDate.format("DD.MM.YYYY")
                        : "");
                tableRows.push(
                    <tr key={driverAbsence.id}>
                        <td onClick={this._setFormDriverAbsence.bind(null, driverAbsence)}>{absencePeriod}</td>
                        <td onClick={this._setFormDriverAbsence.bind(null, driverAbsence)}>
                            {translate("driver.absence." + driverAbsence.absenceType)}
                        </td>
                        <td className="actions">
                            {driverAbsence.updatePending ? (
                                <ElementUpdatePending isVisible={true} type="bars-3" />
                            ) : (
                                <i
                                    className="ui-icon icon-cancel"
                                    onClick={this._removeDriverAbsence.bind(null, driverAbsence)}
                                />
                            )}
                        </td>
                    </tr>
                );
            });

            tabularContent = (
                <div className="ui-collection-output ui-col-10">
                    <table className="ui-row-4">
                        <thead>
                            <tr>
                                <th>Datum</th>
                                <th>Absenztyp</th>
                                <th className="actions" />
                            </tr>
                        </thead>
                        <tbody>{tableRows}</tbody>
                    </table>
                </div>
            );
        }

        return (
            <Modal
                isVisible={this.props.isVisible}
                title={this.props.driver ? this.props.driver.nickname : ""}
                subtitle="Absenz hinzufügen"
            >
                <Modal.Content width="12" height="6">
                    {this.state.driverAbsenceForDriver === null ? (
                        <div className="content-loading">
                            <LoadingAnimation />
                        </div>
                    ) : (
                        <div className="collection-editor">
                            <Form
                                type={new DriverAbsenceForm()}
                                data={this.state.driverAbsence}
                                formRef={(form) => {
                                    this._form = form;
                                }}
                                action={this._saveDriverAbsence}
                                showLabels={false}
                                inline={true}
                            >
                                <label className="ui-col-2">Absenz</label>
                                <Field name="startDate" />
                                <em className="date-separator">-</em>
                                <Field name="endDate" />
                                <Field name="absenceType" />
                                <Button
                                    actionType="positive"
                                    onClick={() => {
                                        this._form.submit();
                                    }}
                                    disabled={this.state.updatePending || this.state.removePending}
                                    updatePending={this.state.updatePending}
                                >
                                    Speichern
                                </Button>
                            </Form>
                            <div
                                className={
                                    "collection-table" +
                                    (this.state.updatePending || this.state.removePending ? " disabled" : "")
                                }
                            >
                                <label className="ui-col-2">Absenzliste</label>
                                {tabularContent}
                            </div>
                        </div>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Modal.ActionButton onClick={this.props.toggleDriverAbsenceModal.bind(this, null, null)}>
                        Schliessen
                    </Modal.ActionButton>
                </Modal.Actions>
            </Modal>
        );
    }

    /**
     * Get driverAbsence for time period
     * @param {Driver} driver
     * @private
     */
    _getDriverAbsenceForDriver = (driver) => {
        DriverAbsenceActions.getDriverAbsenceCollection(
            {},
            {
                driver: driver.id,
            }
        );
    };

    /**
     * On driverAbsence collection load
     * @private
     */
    _onDriverAbsenceCollectionLoad = () => {
        this.setState(
            {
                driverAbsenceForDriver: this.props.driver
                    ? DriverAbsenceStore.getDriverAbsenceForDriver(this.props.driver, "_driverAbsenceCollection")
                    : null,
            },
            () => {
                if (this.state.driverAbsenceForDriver) {
                    DriverAbsenceStore.removeListener(
                        DriverAbsenceStore.ENTITY_COLLECTION_UPDATED,
                        this._onDriverAbsenceCollectionLoad
                    );
                }
            }
        );
    };

    /**
     * Set form driverAbsence
     * @param {DriverAbsence} driverAbsence
     * @private
     */
    _setFormDriverAbsence = (driverAbsence) => {
        if (this.state.updatePending || this.state.removePending) return;

        this.setState({
            driverAbsence: driverAbsence || new DriverAbsence({ driver: this.props.driver }),
        });
    };

    /**
     * Save driverAbsence
     * @param {DriverAbsence} driverAbsence
     * @private
     */
    _saveDriverAbsence = (driverAbsence) => {
        this._form.setDisabled(true);
        this.setState(
            {
                driverAbsence: driverAbsence,
                updatePending: true,
            },
            () => {
                // call the action
                if (!driverAbsence.id) {
                    DriverAbsenceStore.addListener(
                        DriverAbsenceStore.ENTITY_COLLECTION_UPDATED,
                        this._onDriverAbsenceCreate
                    );
                    DriverAbsenceActions.createDriverAbsence({ driverAbsence: driverAbsence });
                } else {
                    DriverAbsenceStore.addListener(
                        DriverAbsenceStore.ENTITY_UPDATED(this.state.driverAbsence.id),
                        this._onDriverAbsenceUpdate
                    );
                    DriverAbsenceActions.updateDriverAbsence({ driverAbsence: driverAbsence });
                }
            }
        );
    };

    /**
     * On driverAbsence create
     * @private
     */
    _onDriverAbsenceCreate = () => {
        // create completed..
        if (this.state.updatePending) {
            // remove listener
            DriverAbsenceStore.removeListener(
                DriverAbsenceStore.ENTITY_COLLECTION_UPDATED,
                this._onDriverAbsenceCreate
            );

            // ..with error
            if (DriverAbsenceStore.error !== null) {
                this._form.setError(DriverAbsenceStore.error);

                // ..successfully
            } else {
                // no op
            }

            this._form.setDisabled(false);
            this.setState({
                driverAbsence:
                    DriverAbsenceStore.error !== null
                        ? this.state.driverAbsence
                        : new DriverAbsence({ driver: this.props.driver }),
                driverAbsenceForDriver: DriverAbsenceStore.getDriverAbsenceForDriver(
                    this.props.driver,
                    "_driverAbsenceCollection"
                ),
                updatePending: false,
            });
        }
    };

    /**
     * On driverAbsence update
     * @private
     */
    _onDriverAbsenceUpdate = () => {
        var driverAbsence = DriverAbsenceStore.driverAbsenceCollection.get(this.state.driverAbsence.id);

        // update completed..
        if (this.state.updatePending && !driverAbsence.updatePending) {
            // remove listener
            DriverAbsenceStore.removeListener(
                DriverAbsenceStore.ENTITY_UPDATED(driverAbsence.id),
                this._onDriverAbsenceUpdate
            );

            // ..with error
            if (DriverAbsenceStore.error !== null) {
                this._form.setError(DriverAbsenceStore.error);

                // ..successfully
            } else {
                // no op
            }

            this._form.setDisabled(false);
            this.setState({
                driverAbsence:
                    DriverAbsenceStore.error !== null
                        ? this.state.driverAbsence
                        : new DriverAbsence({ driver: this.props.driver }),
                driverAbsenceForDriver: DriverAbsenceStore.getDriverAbsenceForDriver(
                    this.props.driver,
                    "_driverAbsenceCollection"
                ),
                updatePending: false,
            });

            // update running..
        } else if (this.state.updatePending && driverAbsence.updatePending) {
            // ..refresh list for indication
            this.setState({
                driverAbsenceForDriver: DriverAbsenceStore.getDriverAbsenceForDriver(
                    this.props.driver,
                    "_driverAbsenceCollection"
                ),
            });
        }
    };

    /**
     * Remove driverAbsence
     * @param {DriverAbsence} driverAbsence
     * @private
     */
    _removeDriverAbsence = (driverAbsence) => {
        if (this.state.updatePending || this.state.removePending) return;

        this._form.setDisabled(true);
        this.setState(
            {
                driverAbsence: driverAbsence, // new DriverAbsence({driver: this.props.driver}),
                removePending: true,
            },
            () => {
                DriverAbsenceStore.addListener(
                    DriverAbsenceStore.ENTITY_UPDATED(driverAbsence.id),
                    this._onDriverAbsenceRemove
                );
                DriverAbsenceStore.addListener(
                    DriverAbsenceStore.ENTITY_COLLECTION_UPDATED,
                    this._onDriverAbsenceRemove
                );
                DriverAbsenceActions.removeDriverAbsence({ driverAbsenceId: driverAbsence.id });
            }
        );
    };

    /**
     * On driverAbsence remove
     * @private
     */
    _onDriverAbsenceRemove = () => {
        var driverAbsence =
            DriverAbsenceStore.driverAbsenceCollection.get(this.state.driverAbsence.id) || this.state.driverAbsence;

        // remove completed..
        if (this.state.removePending && !driverAbsence.updatePending) {
            // remove listener
            DriverAbsenceStore.removeListener(
                DriverAbsenceStore.ENTITY_UPDATED(driverAbsence.id),
                this._onDriverAbsenceRemove
            );
            DriverAbsenceStore.removeListener(
                DriverAbsenceStore.ENTITY_COLLECTION_UPDATED,
                this._onDriverAbsenceRemove
            );

            // ..with error
            if (DriverAbsenceStore.error !== null) {
                this._form.setError(DriverAbsenceStore.error);

                // ..successfully
            } else {
                // no op
            }

            this._form.setDisabled(false);
            this.setState({
                driverAbsence:
                    DriverAbsenceStore.error !== null
                        ? this.state.driverAbsence
                        : new DriverAbsence({ driver: this.props.driver }),
                driverAbsenceForDriver: DriverAbsenceStore.getDriverAbsenceForDriver(
                    this.props.driver,
                    "_driverAbsenceCollection"
                ),
                removePending: false,
            });

            // update running..
        } else if (this.state.removePending && driverAbsence.updatePending) {
            // ..refresh list for indication
            this.setState({
                driverAbsenceForDriver: DriverAbsenceStore.getDriverAbsenceForDriver(
                    this.props.driver,
                    "_driverAbsenceCollection"
                ),
            });
        }
    };
}
