"use strict";

import * as Request from "./Request";
import ApiEndpoints from "../constants/ApiEndpoints";

export default {
    /**
     * Get driverGroup collection
     * @param {Object} params
     * @param {Object} queryParams
     * @return {*}
     */
    getDriverGroupCollection: (params = {}, queryParams = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.SEARCH_DRIVER_GROUPS,
            method: "GET",
            qs: queryParams,
        });
    },

    /**
     * Create driverGroup
     * @param {Object} params
     * @param {DriverGroup} params.driverGroup
     * @return {*}
     */
    createDriverGroup: (params = {}) => {
        var driverGroup = params.driverGroup;

        return Request.getAuthRequestPromise({
            url: ApiEndpoints.CREATE_DRIVER_GROUP,
            method: "POST",
            body: {
                driverGroup: {
                    groupName: driverGroup.groupName,
                },
            },
        });
    },

    /**
     * Get driverGroup
     * @param {number} params.driverGroupId
     * @return {*}
     */
    getDriverGroup: (params) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.GET_DRIVER_GROUP(params.driverGroupId),
            method: "GET",
        });
    },

    /**
     * Update driverGroup
     * @param {Object} params
     * @param {DriverGroup} params.driverGroup
     * @return {*}
     */
    updateDriverGroup: (params = {}) => {
        var driverGroup = params.driverGroup;

        return Request.getAuthRequestPromise({
            url: ApiEndpoints.UPDATE_DRIVER_GROUP(driverGroup.id),
            method: "PUT",
            body: {
                driverGroup: {
                    groupName: driverGroup.groupName,
                },
            },
        });
    },

    /**
     * Remove driverGroup
     * @param {Object} params
     * @param {number} params.driverGroupId
     * @return {*}
     */
    removeDriverGroup: (params = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.REMOVE_DRIVER_GROUP(params.driverGroupId),
            method: "DELETE",
        });
    },
};
