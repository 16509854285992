"use strict";

import React from "react";
import Text from "./Text.react";

/**
 * Password component
 */
export default class Password extends React.Component {
    /**
     * React: render
     */
    render() {
        return <Text {...this.props} type="password" />;
    }
}
