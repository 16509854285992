"use strict";

import Immutable from "immutable";

/**
 * DriverAbsence type
 *
 * @typedef {(Immutable.Record|Object)} DriverAbsence
 * @property {number} id
 * @property {?Driver} driver
 * @property {Object} startDate
 * @property {Object} endDate
 * @property {?string} absenceType
 * @property {bool} updatePending
 */

/**
 * DriverAbsence record
 *
 * @type {DriverAbsence}
 */
var DriverAbsence = Immutable.Record({
    id: null,
    driver: null,
    startDate: null,
    endDate: null,
    absenceType: null,
    updatePending: false,
});

export default DriverAbsence;
