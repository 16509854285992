"use strict";

import moment from "moment";
import * as TypeChecker from "./TypeChecker";
import BaseForm from "../ui/BaseForm";

/**
 * PropTypes.moment
 *
 * @type {function(this:null)}
 */
var momentType = TypeChecker.createChainable(momentObjectChecker);
function momentObjectChecker(props, propName, componentName) {
    componentName = componentName || "ANONYMOUS";
    var submittedPropType = typeof props[propName];
    if (!moment.isMoment(props[propName])) {
        return new Error(
            `Invalid prop \`${propName}\` of type \`${submittedPropType}\` supplied to \`${componentName}\`, expected \`Moment\`.`
        );
    }
    return null;
}

export { momentType as moment };

/**
 * PropTypes.time
 *
 * @type {function(this:null)}
 */
var timeType = TypeChecker.createChainable(timeChecker);
function timeChecker(props, propName, componentName) {
    componentName = componentName || "ANONYMOUS";
    if (typeof props[propName] === "string" && !moment(new Date(props[propName])).isValid()) {
        return new Error(
            `Invalid prop \`${propName}\` supplied to \`${componentName}\`, that can not be parsed into moment (http://momentjs.com/).`
        );
    }
    if (typeof props[propName] === "number" && !moment.unix(props[propName]).isValid()) {
        return new Error(
            `Invalid prop \`${propName}\` supplied to \`${componentName}\`, that can not be parsed into moment (http://momentjs.com/).`
        );
    }
    return null;
}

export { timeType as time };

/**
 * PropTypes.formType
 *
 * @type {function(this:null)}
 */
var formType = TypeChecker.createChainable(formChecker);
function formChecker(props, propName, componentName) {
    componentName = componentName || "ANONYMOUS";
    var submittedPropType = typeof props[propName];
    if (typeof props[propName] === "function" && !(new props[propName]() instanceof BaseForm)) {
        return new Error(
            `Invalid prop \`${propName}\` of type \`${submittedPropType}\` supplied to \`${componentName}\`, expected subclass of \`BaseForm\`.`
        );
    }
    return null;
}

export { formType as formType };
