"use strict";

import React from "react";
import PropTypes from "prop-types";
import Tour from "../types/Tour";
import TourActions from "../actions/TourActions";
import TourStore from "../stores/TourStore";
import TourForm from "../forms/TourForm";
import Form from "../ui/elements/Form.react";
import Button from "../ui/elements/Button.react";
import LoadingAnimation from "../ui/elements/LoadingAnimation.react";

/**
 * FormPage component
 */
export default class FormPage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        tourId: PropTypes.number,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        tourId: 1,
    };

    /**
     * React: state
     */
    state = {
        tour: null,
        updatePending: false,
        removePending: false,
    };

    /**
     * Form ref
     * @type {Object|null}
     * @private
     */
    _form = null;

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        // get tours
        if (this.props.tourId) {
            // add store listeners
            TourStore.addListener(TourStore.ENTITY_UPDATED(this.props.tourId), this._onTourLoad);
            this._getTour(this.props.tourId);

            // ..and showing new tour
        } else {
            this.setState({ tour: new Tour({}) });
        }
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        if (this.props.tourId) {
            TourStore.removeListener(TourStore.ENTITY_UPDATED(this.props.tourId), this._onTourLoad);
            TourStore.removeListener(TourStore.ENTITY_UPDATED(this.props.tourId), this._onTourUpdate);
            TourStore.removeListener(TourStore.ENTITY_UPDATED(this.props.tourId), this._onTourRemove);
        }
        TourStore.removeListener(TourStore.ENTITY_COLLECTION_UPDATED, this._onTourCreate);
        TourStore.removeListener(TourStore.ENTITY_COLLECTION_UPDATED, this._onTourRemove);

        // reset state
        this.setState({ tour: null, updatePending: false, removePending: false });
        this._form = null;
    }

    /**
     * React: render
     */
    render() {
        return (
            <div className="form-page">
                {!this.state.tour ? (
                    <div className="content-loading">
                        <LoadingAnimation />
                    </div>
                ) : (
                    <div>
                        <Form
                            type={new TourForm()}
                            data={this.state.tour}
                            formRef={(form) => {
                                this._form = form;
                            }}
                            action={this._saveTour}
                        />
                        <Button
                            actionType="negative"
                            isSecondary={true}
                            onClick={this._removeTour}
                            disabled={this.state.tour === null || this.state.updatePending || this.state.removePending}
                            updatePending={this.state.removePending}
                        >
                            <i className="ui-icon icon-cancel" />
                            Löschen
                        </Button>
                        <Button
                            isSecondary={true}
                            onClick={() => {
                                this._form.reset();
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            actionType="positive"
                            onClick={() => {
                                this._form.submit();
                            }}
                            disabled={this.state.tour === null || this.state.updatePending || this.state.removePending}
                            updatePending={this.state.updatePending}
                        >
                            Speichern
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    /**
     * Get tour
     */
    _getTour = (tourId) => {
        TourActions.getTour({
            tourId: tourId,
        });
    };

    /**
     * On tour load
     * @private
     */
    _onTourLoad = () => {
        this.setState(
            {
                tour: this.props.tourId ? TourStore.tourCollection.get(this.props.tourId) : null,
            },
            () => {
                if (this.state.tour) {
                    TourStore.removeListener(TourStore.ENTITY_UPDATED(this.props.tourId), this._onTourLoad);
                }
            }
        );
    };

    /**
     * Save tour
     * @param {Tour} tour
     * @private
     */
    _saveTour = (tour) => {
        this._form.setDisabled(true);
        this.setState(
            {
                tour: tour,
                updatePending: true,
            },
            () => {
                // call the action
                if (this.props.tourId === null) {
                    TourActions.createTour({ tour: tour });
                    TourStore.addListener(TourStore.ENTITY_COLLECTION_UPDATED, this._onTourCreate);
                } else {
                    TourActions.updateTour({ tour: tour });
                    TourStore.addListener(TourStore.ENTITY_UPDATED(this.props.tourId), this._onTourUpdate);
                }
            }
        );
    };

    /**
     * On tour create
     * @private
     */
    _onTourCreate = () => {
        // create completed..
        if (this.state.updatePending) {
            // create completed..
            TourStore.removeListener(TourStore.ENTITY_COLLECTION_UPDATED, this._onTourCreate);

            // ..with error
            if (TourStore.error !== null) {
                this._form.setError(TourStore.error);
            }

            // reset form state
            this._form.setDisabled(false);
            this.setState({
                updatePending: false,
            });
        }
    };

    /**
     * On tour update
     * @private
     */
    _onTourUpdate = () => {
        var tour = this.state.tour;

        // update completed..
        if (this.state.updatePending && !tour.updatePending) {
            // remove listener
            TourStore.removeListener(TourStore.ENTITY_UPDATED(this.props.tourId), this._onTourUpdate);

            // ..with error
            if (TourStore.error !== null) {
                this._form.setError(TourStore.error);
            }

            // reset form state
            this._form.setDisabled(false);
            this.setState({
                tour: TourStore.error === null ? tour : this.state.tour,
                updatePending: false,
            });
        }
    };

    /**
     * Remove tour
     * @private
     */
    _removeTour = () => {
        this._form.setDisabled(true);
        this.setState(
            {
                removePending: true,
                tour: this._form.getData(),
            },
            () => {
                TourActions.removeTour({ tourId: this.state.tour.id });
                TourStore.addListener(TourStore.ENTITY_UPDATED(this.props.tourId), this._onTourRemove);
                TourStore.addListener(TourStore.ENTITY_COLLECTION_UPDATED, this._onTourRemove);
            }
        );
    };

    /**
     * On tour remove
     * @private
     */
    _onTourRemove = () => {
        var tour = this.state.tour;

        // remove completed..
        if (this.state.removePending && !tour.updatePending) {
            // remove listener
            TourStore.removeListener(TourStore.ENTITY_UPDATED(this.props.tourId), this._onTourRemove);
            TourStore.removeListener(TourStore.ENTITY_COLLECTION_UPDATED, this._onTourRemove);

            // ..with error
            if (TourStore.error !== null) {
                this._form.setError(TourStore.error);
            }

            this._form.setDisabled(false);
            this.setState({
                removePending: false,
            });
        }
    };
}
