"use strict";

import React from "react";
import PropTypes from "prop-types";

/**
 * Checkbox component
 */
export default class Checkbox extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        value: PropTypes.bool,
        label: PropTypes.node,
        disabled: PropTypes.bool,
        inline: PropTypes.bool,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        value: false,
        disabled: false,
        inline: true,
    };

    /**
     * React: state
     */
    state = {
        checked: this.props.value,
    };

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        this.setState({
            checked: nextProps.value,
        });
    }

    /**
     * React: render
     */
    render() {
        var className = "ui-checkbox" + (this.props.inline ? " inline" : "");
        return (
            <label className={className}>
                <input
                    type="checkbox"
                    value="1"
                    checked={this.state.checked}
                    onChange={this._onChange}
                    onClick={this._onClick}
                    disabled={this.props.disabled}
                />
                {this.props.label}
            </label>
        );
    }

    /**
     * On change
     */
    _onChange = (event) => {
        this.setState(
            {
                checked: event.target.checked,
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.checked, event);
                }
            }
        );
    };

    /**
     * On click
     */
    _onClick = (event) => {
        if (typeof this.props.onClick === "function") {
            this.props.onClick(event.target.checked, event);
        }
    };
}
