"use strict";

import { EventEmitter } from "events";
import { register } from "../dispatchers/AppDispatcher";

/**
 * BaseStore
 */
export default class BaseStore extends EventEmitter {
    /**
     * Constructor
     */
    constructor() {
        super();
    }

    /**
     * Subscribe store to actions
     *
     * @param actionSubscribe
     */
    subscribe(actionSubscribe) {
        this._dispatchToken = register(actionSubscribe());
    }

    /**
     * Return dispatch token
     *
     * @return {*}
     */
    get dispatchToken() {
        return this._dispatchToken;
    }

    /**
     * EventEmitter.on() wrapper
     *
     * @param storeEvent
     * @param callback
     */
    addListener(storeEvent, callback) {
        this.on(storeEvent, callback);
    }
}
