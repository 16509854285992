"use strict";

import React from "react";
import PropTypes from "prop-types";

/**
 * Choice component
 */
export default class Collection extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])),
        outputType: PropTypes.oneOf(["list", "table"]),
        listLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        tableColumns: PropTypes.arrayOf(
            PropTypes.shape({
                headerLabel: PropTypes.string,
                cellLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
            })
        ),
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        value: [],
        outputType: "list",
    };

    /**
     * React: state
     */
    state = {
        value: this.props.value,
    };

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
        });
    }

    /**
     * React: render
     */
    render() {
        var collectionContent = null;

        // table output type
        if (this.props.outputType === "table") {
            let tableHeader = [];
            let tableRows = [];

            this.props.tableColumns.forEach((column, index) => {
                tableHeader.push(<th key={index}>{column.headerLabel}</th>);
            });

            this.state.value.forEach((entryData, entryIndex) => {
                var columns = [];
                this.props.tableColumns.forEach((column, index) => {
                    columns.push(<td key={index}>{this._getLabel(entryData, column.cellLabel)}</td>);
                });
                tableRows.push(<tr key={entryIndex}>{columns}</tr>);
            });

            let tableClassName = "" + (typeof this.props.height !== "undefined" ? " ui-row-" + this.props.height : "");
            collectionContent = (
                <table className={tableClassName}>
                    <thead>
                        <tr>{tableHeader}</tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </table>
            );

            // list output type
        } else if (this.props.outputType === "list") {
            var items = [];
            this.state.value.forEach((entryData) => {
                items.push(this._getLabel(entryData, this.props.listLabel));
            });
            collectionContent = items.join(", ");
        }

        var className = "ui-collection-output" + (this.props.width ? " ui-col-" + this.props.width : "");
        return <div className={className}>{collectionContent || "-"}</div>;
    }

    /**
     * Get label
     * @param {*} entryData
     * @param {*} label
     */
    _getLabel = (entryData, label) => {
        if (typeof label === "function") {
            return label(entryData);
        } else if (typeof label === "string" && typeof entryData[label] !== "undefined") {
            return entryData[label];
        } else {
            return entryData;
        }
    };
}
