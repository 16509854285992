"use strict";

import jwt_decode from "jwt-decode";
import store from "store";
import moment from "moment";
import ActionTypes from "../constants/ActionTypes";
import BaseStore from "./BaseStore";

/**
 * Flux: AuthStore
 */
class AuthStore extends BaseStore {
    /**
     * Store events
     */
    STORE_UPDATED = "store_updated";
    JWT_UPDATED = "jwt_updated";

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));
        this._user = null;
        this._error = null;
        this._jwt = null;
        this._updatePending = false;

        //attempt auto-login
        console.log("** [auth] attempting auto-login");
        this._autoLogin();
    }

    /**
     * Flux: register store to actions
     *
     * @param {Object} action
     * @private
     */
    _registerToActions(action) {
        let token;
        let user;
        switch (action.type) {
            case ActionTypes.REQUEST_LOGIN_USER:
                this._updatePending = true;
                this._error = null;
                this.emit(this.STORE_UPDATED);
                break;

            case ActionTypes.REQUEST_LOGIN_USER_SUCCESS:
                token = action.body.token;
                this._updatePending = false;

                if (!token) {
                    this._error = { message: "login server error" };
                    this._jwt = null;
                    this._user = null;
                    this.emit(this.STORE_UPDATED);
                    break;
                }
                user = null;
                try {
                    user = jwt_decode(token);
                    this._jwt = token;
                    this._user = user;
                    store.set("tp_jwt", this._jwt);
                    this._error = null;
                    this.emit(this.JWT_UPDATED);
                } catch (e) {
                    this._error = { message: e.toString() };
                    this._user = null;
                    this.emit(this.STORE_UPDATED);
                }
                break;

            case ActionTypes.REQUEST_LOGIN_USER_ERROR:
                this._updatePending = false;
                this._error = action.error.message ? action.error : { message: "login server error" };
                this.emit(this.STORE_UPDATED);
                break;

            case ActionTypes.LOGOUT_USER:
                this._user = null;
                this._jwt = null;
                store.set("tp_jwt", "");
                this._error = null;
                this.emit(this.JWT_UPDATED);
                break;

            default:
                break;
        }
    }

    /**
     * Auto-login user
     *
     * @private
     */
    _autoLogin() {
        let jwt = store.get("tp_jwt");
        if (jwt) {
            this._jwt = jwt;
            this._user = jwt_decode(this._jwt);
            console.log("** [auth] auto-login success");
        }
    }

    /**
     * Get user
     *
     * @returns {null|*}
     */
    get user() {
        return this._user;
    }

    /**
     * Get error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }

    /**
     * Get JWT
     *
     * @returns {null|*|jwt}
     */
    get jwt() {
        return this._jwt;
    }

    /**
     * Get _updatePending
     *
     * @returns {boolean}
     */
    get updatePending() {
        return this._updatePending;
    }

    /**
     * Check if user is logged in
     *
     * @returns {boolean}
     */
    isLoggedIn() {
        return !!this._user && this._user.exp > moment().unix();
    }

    validateToken() {
        if (!this._user || this._user.exp < moment().unix()) {
            this._user = null;
            this._jwt = null;
            store.set("tp_jwt", "");
            this._error = null;
            this.emit(this.JWT_UPDATED);
        }
    }
}

export default new AuthStore();
