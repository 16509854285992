"use strict";

import request from "request";
import AuthStore from "../stores/AuthStore";

/**
 * Returns promise for base request
 *
 * @param {Object} requestParams
 */
export function getRequestPromise(requestParams) {
    return new Promise((resolve, reject) => {
        baseRequest(requestParams, (err, response, body) => {
            if (err) {
                return reject(err);
            }
            if (response.statusCode >= 400) {
                return reject(body);
            }
            return resolve(body);
        });
    });
}

/**
 * Returns promise for base request with authentication
 *
 * @param {Object} requestParams
 */
export function getAuthRequestPromise(requestParams) {
    AuthStore.validateToken();

    requestParams = {
        ...requestParams,
        headers: {
            Authorization: "Bearer " + AuthStore.jwt,
        },
    };

    return new Promise((resolve, reject) => {
        baseRequest(requestParams, (err, response, body) => {
            if (err) {
                return reject(err);
            }
            if (response.statusCode >= 400) {
                return reject(body);
            }
            return resolve(body);
        });
    });
}

/**
 * Base request
 */
var baseRequest = request.defaults({
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "de;q=0.8,en-us;q=0.5,en;q=0.3",
    },
    json: true,
});
