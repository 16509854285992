"use strict";

import React from "react";
import PropTypes from "prop-types";
import LoadingAnimation from "./LoadingAnimation.react";

/**
 * ElementUpdatePending component
 */
export default class ElementUpdatePending extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        isVisible: PropTypes.bool,
        type: PropTypes.string,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        isVisible: false,
        type: "bars-3",
    };

    /**
     * React: render
     */
    render() {
        if (this.props.isVisible) {
            return (
                <span className="ui-element-update-pending">
                    <LoadingAnimation type={this.props.type} size="small" />
                </span>
            );
        } else {
            return null;
        }
    }
}
