"use strict";

import * as AppDispatcher from "../dispatchers/AppDispatcher";
import ActionTypes from "../constants/ActionTypes";
import ApiService from "../server/ApiService";

export default {
    getCalendarEventCollection: (params = {}, queryParams = {}) => {
        let promise = ApiService.getCalendarEventCollection(params, queryParams);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_GET_CALENDAR_EVENT_COLLECTION,
                success: ActionTypes.REQUEST_GET_CALENDAR_EVENT_COLLECTION_SUCCESS,
                failure: ActionTypes.REQUEST_GET_CALENDAR_EVENT_COLLECTION_ERROR,
            },
            { ...params, ...queryParams }
        );
    },

    createCalendarEvent: (params = {}) => {
        let promise = ApiService.createCalendarEvent(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_CREATE_CALENDAR_EVENT,
                success: ActionTypes.REQUEST_CREATE_CALENDAR_EVENT_SUCCESS,
                failure: ActionTypes.REQUEST_CREATE_CALENDAR_EVENT_ERROR,
            },
            params
        );
    },

    getCalendarEvent: (params = {}) => {
        let promise = ApiService.getCalendarEvent(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_GET_CALENDAR_EVENT,
                success: ActionTypes.REQUEST_GET_CALENDAR_EVENT_SUCCESS,
                failure: ActionTypes.REQUEST_GET_CALENDAR_EVENT_ERROR,
            },
            params
        );
    },

    updateCalendarEvent: (params = {}) => {
        let promise = ApiService.updateCalendarEvent(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_UPDATE_CALENDAR_EVENT,
                success: ActionTypes.REQUEST_UPDATE_CALENDAR_EVENT_SUCCESS,
                failure: ActionTypes.REQUEST_UPDATE_CALENDAR_EVENT_ERROR,
            },
            params
        );
    },

    removeCalendarEvent: (params = {}) => {
        let promise = ApiService.removeCalendarEvent(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_REMOVE_CALENDAR_EVENT,
                success: ActionTypes.REQUEST_REMOVE_CALENDAR_EVENT_SUCCESS,
                failure: ActionTypes.REQUEST_REMOVE_CALENDAR_EVENT_ERROR,
            },
            params
        );
    },
};
