"use strict";

import React from "react";
import { withRouter } from "./WithRouter.react";
import { Outlet } from "react-router-dom";
import AppConstants from "../constants/AppConstants";
import RouterStore from "../stores/RouterStore";
import AuthStore from "../stores/AuthStore";
import Header from "../components/Header.react";
import Menu from "../components/Menu.react";

/**
 * PageContainer component
 */
class PageContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AuthStore.addListener(AuthStore.JWT_UPDATED, this._onJwtChange.bind(this));
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AuthStore.removeListener(AuthStore.JWT_UPDATED, this._onJwtChange.bind(this));
    }

    /**
     * React: render
     */
    render() {
        return (
            <div className="container">
                <Header />
                {AuthStore.isLoggedIn() ? <Menu /> : null}
                <Outlet />
                <div className="footer">
                    Version: {AppConstants.VERSION} build: {AppConstants.BUILD}
                </div>
            </div>
        );
    }

    /**
     * On login change
     */
    _onJwtChange() {
        var transitionPath = RouterStore.nextTransitionPath || "/schedule";

        // trigger router change
        console.log("** [router] loggedIn:", AuthStore.isLoggedIn(), ", nextTransitionPath:", transitionPath);
        console.log(this.props, RouterStore, transitionPath);
        this.props.onUserChange(AuthStore.user);
        this.props.navigate(AuthStore.isLoggedIn() ? transitionPath : "/login");
    }
}

export default withRouter(PageContainer);
