"use strict";

import * as AppDispatcher from "../dispatchers/AppDispatcher";
import ActionTypes from "../constants/ActionTypes";
import ApiService from "../server/ApiService";

export default {
    getDriverCollection: (params = {}, queryParams = {}) => {
        let promise = ApiService.getDriverCollection(params, queryParams);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_GET_DRIVER_COLLECTION,
                success: ActionTypes.REQUEST_GET_DRIVER_COLLECTION_SUCCESS,
                failure: ActionTypes.REQUEST_GET_DRIVER_COLLECTION_ERROR,
            },
            { ...params, ...queryParams }
        );
    },

    createDriver: (params = {}) => {
        let promise = ApiService.createDriver(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_CREATE_DRIVER,
                success: ActionTypes.REQUEST_CREATE_DRIVER_SUCCESS,
                failure: ActionTypes.REQUEST_CREATE_DRIVER_ERROR,
            },
            params
        );
    },

    getDriver: (params = {}) => {
        let promise = ApiService.getDriver(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_GET_DRIVER,
                success: ActionTypes.REQUEST_GET_DRIVER_SUCCESS,
                failure: ActionTypes.REQUEST_GET_DRIVER_ERROR,
            },
            params
        );
    },

    updateDriver: (params = {}) => {
        let promise = ApiService.updateDriver(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_UPDATE_DRIVER,
                success: ActionTypes.REQUEST_UPDATE_DRIVER_SUCCESS,
                failure: ActionTypes.REQUEST_UPDATE_DRIVER_ERROR,
            },
            params
        );
    },

    removeDriver: (params = {}) => {
        let promise = ApiService.removeDriver(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_REMOVE_DRIVER,
                success: ActionTypes.REQUEST_REMOVE_DRIVER_SUCCESS,
                failure: ActionTypes.REQUEST_REMOVE_DRIVER_ERROR,
            },
            params
        );
    },
};
