import React from "react";
import { useNavigate } from "react-router";

// eslint-disable-next-line react/display-name
export const withRouter = (WrappedComponent) => (props) => {
    const navigate = useNavigate();
    return (
        <WrappedComponent
            {...props}
            navigate={navigate}
            // etc...
        />
    );
};
