"use strict";

var BASE_URL = window.location.origin;
export default {
    VERSION: `${GIT_TAG}`,
    BUILD: `${GIT_COMMIT.substr(0, 7)}`,
    BUILD_FULL: `${GIT_COMMIT}`,
    BASE_URL: BASE_URL,
    LOCALE: "de",
};
