"use strict";

import BaseForm from "../ui/BaseForm";
import * as DriverAbsenceTypes from "../constants/DriverAbsenceTypes";
import { translate } from "../i18n/Translate";

/**
 * DriverAbsenceForm class
 */
export default class DriverAbsenceForm extends BaseForm {
    /**
     * @inheritdoc
     */
    buildForm(builder) {
        builder.add("driver", "hidden", {});
        builder.add("startDate", "dateTime", {
            dateFormat: "DD.MM.YYYY",
        });
        builder.add("endDate", "dateTime", {
            dateFormat: "DD.MM.YYYY",
        });
        builder.add("absenceType", "choice", {
            choices: DriverAbsenceTypes.getOptions(),
            choiceLabel: (choices, key) => {
                return translate("driver.absence." + choices[key]);
            },
            placeholder: "Typ wählen...",
            width: 3,
        });
    }
}
