"use strict";

import React from "react";
import PropTypes from "prop-types";

/**
 * LoadingAnimation component
 */
export default class LoadingAnimation extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        type: PropTypes.oneOf(["bars-5", "bars-3"]),
        size: PropTypes.oneOf(["small", "regular"]),
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        type: "bars-5",
    };

    /**
     * React: render
     */
    render() {
        var animation = null;
        var className = "ui-animation" + (this.props.size ? " animation-" + this.props.size : "");

        switch (this.props.type) {
            case "bars-5":
                className += " loading-bars bars-5";
                animation = (
                    <div className={className}>
                        <div className="vertical-bar-1"></div>
                        <div className="vertical-bar-2"></div>
                        <div className="vertical-bar-3"></div>
                        <div className="vertical-bar-4"></div>
                        <div className="vertical-bar-5"></div>
                    </div>
                );
                break;

            case "bars-3":
                className += " loading-bars bars-3";
                animation = (
                    <div className={className}>
                        <div className="vertical-bar-1"></div>
                        <div className="vertical-bar-2"></div>
                        <div className="vertical-bar-3"></div>
                    </div>
                );
                break;
        }

        return animation;
    }
}
