"use strict";

import * as AppDispatcher from "../dispatchers/AppDispatcher";
import ActionTypes from "../constants/ActionTypes";
import ApiService from "../server/ApiService";

export default {
    getDriverAbsenceCollection: (params = {}, queryParams = {}) => {
        let promise = ApiService.getDriverAbsenceCollection(params, queryParams);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_GET_DRIVER_ABSENCE_COLLECTION,
                success: ActionTypes.REQUEST_GET_DRIVER_ABSENCE_COLLECTION_SUCCESS,
                failure: ActionTypes.REQUEST_GET_DRIVER_ABSENCE_COLLECTION_ERROR,
            },
            { ...params, ...queryParams }
        );
    },

    createDriverAbsence: (params = {}) => {
        let promise = ApiService.createDriverAbsence(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_CREATE_DRIVER_ABSENCE,
                success: ActionTypes.REQUEST_CREATE_DRIVER_ABSENCE_SUCCESS,
                failure: ActionTypes.REQUEST_CREATE_DRIVER_ABSENCE_ERROR,
            },
            params
        );
    },

    getDriverAbsence: (params = {}) => {
        let promise = ApiService.getDriverAbsence(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_GET_DRIVER_ABSENCE,
                success: ActionTypes.REQUEST_GET_DRIVER_ABSENCE_SUCCESS,
                failure: ActionTypes.REQUEST_GET_DRIVER_ABSENCE_ERROR,
            },
            params
        );
    },

    updateDriverAbsence: (params = {}) => {
        let promise = ApiService.updateDriverAbsence(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_UPDATE_DRIVER_ABSENCE,
                success: ActionTypes.REQUEST_UPDATE_DRIVER_ABSENCE_SUCCESS,
                failure: ActionTypes.REQUEST_UPDATE_DRIVER_ABSENCE_ERROR,
            },
            params
        );
    },

    removeDriverAbsence: (params = {}) => {
        let promise = ApiService.removeDriverAbsence(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_REMOVE_DRIVER_ABSENCE,
                success: ActionTypes.REQUEST_REMOVE_DRIVER_ABSENCE_SUCCESS,
                failure: ActionTypes.REQUEST_REMOVE_DRIVER_ABSENCE_ERROR,
            },
            params
        );
    },
};
