"use strict";

import { Dispatcher } from "flux";
import ActionTypes from "../constants/ActionTypes";

const flux = new Dispatcher();

export function register(callback) {
    return flux.register(callback);
}

export function waitFor(ids) {
    return flux.waitFor(ids);
}

/**
 * Dispatches a single action.
 */
export function dispatch(type, action = {}) {
    if (!type) {
        throw new Error("You forgot to specify type.");
    }

    const log_action =
        type === ActionTypes.REQUEST_LOGIN_USER || type === ActionTypes.REQUEST_LOGIN_USER_SUCCESS ? {} : action;
    console.log("** [dispatcher]", type, log_action);
    flux.dispatch({ type, ...action });
}

/**
 * Dispatches three actions for an async operation represented by promise.
 */
export function dispatchAsync(promise, types, action = {}) {
    const { request, success, failure } = types;

    dispatch(request, action);
    promise.then(
        (body) => dispatch(success, { ...action, body }),
        (error) => dispatch(failure, { ...action, error })
    );
}
