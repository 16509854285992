"use strict";

import React from "react";

/**
 * AccountsPage component
 */
export default class AccountsPage extends React.Component {
    /**
     * React: render
     */
    render() {
        return <div className="accounts-page">{this.props.children}</div>;
    }
}
