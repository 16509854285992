"use strict";

import React from "react";
import moment from "moment";
import TourActions from "../actions/TourActions";
import TourStore from "../stores/TourStore";
import ActionBar from "../ui/elements/ActionBar.react";
import Button from "../ui/elements/Button.react";
import LoadingAnimation from "../ui/elements/LoadingAnimation.react";
import ScheduleTable from "./ScheduleTable.react";
import TourModal from "./TourModal.react";
import DriverGroupsModal from "./DriverGroupsModal.react";
import DriverGroupOverviewModal from "./DriverGroupOverviewModal.react";

/**
 * ToursPage component
 */
export default class ToursPage extends React.Component {
    /**
     * React: state
     */
    state = {
        startDate: moment().weekday(0),
        endDate: moment().weekday(6),
        tours: null,
        isDriverGroupsModalVisible: false,
        tourToUpdate: null,
        isTourModalVisible: false,
        driverGroupToView: null,
        isDriverGroupOverviewModalVisible: false,
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        // add store listeners
        TourStore.addListener(TourStore.ENTITY_COLLECTION_UPDATED, this._setTours);

        // get tours
        this._getTours();
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        TourStore.removeListener(TourStore.ENTITY_COLLECTION_UPDATED, this._setTours);
    }

    /**
     * React: render
     */
    render() {
        var tabularContent = null;

        if (this.state.tours === null) {
            tabularContent = (
                <div className="content-loading">
                    <LoadingAnimation />
                </div>
            );

            // tours loaded but there are no tours
        } else if (this.state.tours.size === 0) {
            tabularContent = (
                <div className="content-loading">
                    <p>Es sind keine Touren in Datenbank. Klicken Sie hier um eine hinzuzufügen</p>
                    <Button onClick={null} size="large" disabled={true}>
                        Neue Tour
                    </Button>
                </div>
            );

            // tours loaded
        } else {
            var tableRows = [];
            this.state.tours.forEach((tour) => {
                let rowCells = [];
                Object.keys(tour.weekDays).forEach((key) => {
                    rowCells.push(<td key={key}>{!tour.weekDays[key] ? null : <i className="ui-icon icon-yes" />}</td>);
                });

                tableRows.push(
                    <tr key={tour.id}>
                        <td className="tour-name">
                            <span onClick={this._toggleTourModal.bind(this, tour)}>{tour.tourName}</span>
                            <br />
                            {!tour.driverGroup ? null : (
                                <span
                                    onClick={this._toggleDriverGroupOverviewModal.bind(this, tour.driverGroup)}
                                    className="group"
                                >
                                    {tour.driverGroup.groupName}&nbsp;{tour.driverGroup.isRemoved ? "(gelöscht)" : null}
                                </span>
                            )}
                        </td>
                        <td>
                            <strong>{tour.hasShifts ? "am/pm" : null}</strong>
                        </td>
                        <td>{!tour.isMountainTour ? null : <i className="ui-icon icon-yes" />}</td>
                        {rowCells}
                    </tr>
                );
            });

            tabularContent = (
                <ScheduleTable
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    additionalHeading2="Bergtour"
                    displayDate={false}
                >
                    {tableRows}
                </ScheduleTable>
            );
        }

        return (
            <div className="tours-page">
                <ActionBar>
                    {/* <ActionBar.Actions type="secondary">
                     <DateNavigation navigateBy="week" startDate={this.state.startDate} weekFormatTo="[bis]"
                     onPrevious={this._onDateChange} onNext={this._onDateChange}/>
                     </ActionBar.Actions> */}
                    <ActionBar.Actions type="primary">
                        <Button
                            size="large"
                            onClick={this._toggleTourModal.bind(this, null)}
                            disabled={this.state.tours === null}
                        >
                            Neue Tour
                        </Button>
                        <Button
                            size="large"
                            onClick={this._toggleDriverGroupsModal}
                            disabled={this.state.tours === null}
                        >
                            Gruppen Editieren
                        </Button>
                    </ActionBar.Actions>
                </ActionBar>

                {tabularContent}

                {this.props.children}

                <TourModal
                    isVisible={this.state.isTourModalVisible}
                    tour={this.state.tourToUpdate}
                    toggleTourModal={this._toggleTourModal}
                />

                <DriverGroupsModal
                    isVisible={this.state.isDriverGroupsModalVisible}
                    toggleDriverGroupsModal={this._toggleDriverGroupsModal}
                />

                <DriverGroupOverviewModal
                    isVisible={this.state.isDriverGroupOverviewModalVisible}
                    driverGroup={this.state.driverGroupToView}
                    toggleDriverGroupOverviewModal={this._toggleDriverGroupOverviewModal}
                />
            </div>
        );
    }

    /**
     * Get tours
     */
    _getTours = () => {
        TourActions.getTourCollection();
    };

    /**
     * Set tours
     */
    _setTours = () => {
        this.setState({
            tours: TourStore.tourCollection,
        });
    };

    /**
     * Toggle tour modal
     *
     * @params {Tour} tour
     */
    _toggleTourModal = (tour) => {
        this.setState({
            isTourModalVisible: !this.state.isTourModalVisible,
            tourToUpdate: tour,
        });
    };

    /**
     * Toggle driverGroups modal
     *
     */
    _toggleDriverGroupsModal = () => {
        this.setState({
            isDriverGroupsModalVisible: !this.state.isDriverGroupsModalVisible,
        });
    };

    /**
     * Toggle driverGroup modal
     *
     * @params {DriverGroup} driverGroup
     */
    _toggleDriverGroupOverviewModal = (driverGroup) => {
        this.setState({
            isDriverGroupOverviewModalVisible: !this.state.isDriverGroupOverviewModalVisible,
            driverGroupToView: driverGroup,
        });
    };
}
