"use strict";

import AppConstants from "./AppConstants";

/**
 * Base API URL
 *
 * @type {string}
 */
var BASE_API_URL = AppConstants.BASE_URL + "/api";

/**
 * API endpoints
 * documented at: http://tour-planner.test/api/app_dev.php/doc
 */
export default {
    BASE_API_URL: BASE_API_URL,

    // Auth
    LOGIN_USER: BASE_API_URL + "/login_check",

    // Calendar
    SEARCH_CALENDAR_EVENTS: BASE_API_URL + "/calendar",
    CREATE_CALENDAR_EVENT: BASE_API_URL + "/calendar",
    GET_CALENDAR_EVENT: (calendarEventId) => BASE_API_URL + `/calendar/${calendarEventId}`,
    UPDATE_CALENDAR_EVENT: (calendarEventId) => BASE_API_URL + `/calendar/${calendarEventId}`,
    REMOVE_CALENDAR_EVENT: (calendarEventId) => BASE_API_URL + `/calendar/${calendarEventId}`,

    // Driver
    SEARCH_DRIVERS: BASE_API_URL + "/drivers",
    CREATE_DRIVER: BASE_API_URL + "/drivers",
    GET_DRIVER: (driverId) => BASE_API_URL + `/drivers/${driverId}`,
    UPDATE_DRIVER: (driverId) => BASE_API_URL + `/drivers/${driverId}`,
    REMOVE_DRIVER: (driverId) => BASE_API_URL + `/drivers/${driverId}`,

    // Driver absence
    SEARCH_DRIVER_ABSENCE: BASE_API_URL + "/absence",
    CREATE_DRIVER_ABSENCE: BASE_API_URL + "/absence",
    GET_DRIVER_ABSENCE: (driverAbsenceId) => BASE_API_URL + `/absence/${driverAbsenceId}`,
    UPDATE_DRIVER_ABSENCE: (driverAbsenceId) => BASE_API_URL + `/absence/${driverAbsenceId}`,
    REMOVE_DRIVER_ABSENCE: (driverAbsenceId) => BASE_API_URL + `/absence/${driverAbsenceId}`,

    // Driver group
    SEARCH_DRIVER_GROUPS: BASE_API_URL + "/groups",
    CREATE_DRIVER_GROUP: BASE_API_URL + "/groups",
    GET_DRIVER_GROUP: (driverGroupId) => BASE_API_URL + `/groups/${driverGroupId}`,
    UPDATE_DRIVER_GROUP: (driverGroupId) => BASE_API_URL + `/groups/${driverGroupId}`,
    REMOVE_DRIVER_GROUP: (driverGroupId) => BASE_API_URL + `/groups/${driverGroupId}`,

    // Tour
    SEARCH_TOURS: BASE_API_URL + "/tours",
    CREATE_TOUR: BASE_API_URL + "/tours",
    GET_TOUR: (tourId) => BASE_API_URL + `/tours/${tourId}`,
    UPDATE_TOUR: (tourId) => BASE_API_URL + `/tours/${tourId}`,
    REMOVE_TOUR: (tourId) => BASE_API_URL + `/tours/${tourId}`,

    // Tour date
    CREATE_SCHEDULE: BASE_API_URL + "/schedule",
    GET_SCHEDULE: (startDate, endDate) => BASE_API_URL + `/schedule/${startDate}/${endDate}`,
    EXPORT_SCHEDULE: (startDate, endDate, token) =>
        BASE_API_URL + `/tools/schedule_export/${startDate}/${endDate}?bearer=${token}`,
    REMOVE_SCHEDULE: (startDate, endDate) => BASE_API_URL + `/schedule/${startDate}/${endDate}`,
    CREATE_TOUR_DATE: BASE_API_URL + "/dates",
    GET_TOUR_DATE: (tourDateId) => BASE_API_URL + `/dates/${tourDateId}`,
    UPDATE_TOUR_DATE: (tourDateId) => BASE_API_URL + `/dates/${tourDateId}`,
    UPDATE_TOUR_DATE_DRIVER: (tourDateId) => BASE_API_URL + `/dates/${tourDateId}/driver`,

    SENTRY_CONFIG: BASE_API_URL + "/sentry/config",
};
