"use strict";

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "./Button.react";

/**
 * DateNavigation component
 *
 * Uses Moment.js for date formatting and manipulation http://momentjs.com
 */
export default class DateNavigation extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        startDate: PropTypes.object,
        navigateBy: PropTypes.oneOf(["day", "week", "month"]),
        dayFormat: PropTypes.string,
        weekFormat: PropTypes.string,
        monthFormat: PropTypes.string,
        onPrevious: PropTypes.func,
        onNext: PropTypes.func,
        disabled: PropTypes.bool,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        startDate: moment(),
        navigateBy: "week",
        dayFormat: "dddd, D. MMMM YYYY",
        weekFormat: "D. [to] D. MMMM, [KW] W",
        weekFormatTo: "[to]",
        monthFormat: "MMMM YYYY",
        disabled: false,
    };

    /**
     * React: state
     */
    state = {
        startDate: null,
        endDate: null,
    };

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        switch (this.props.navigateBy) {
            case "day":
                this.state.startDate = moment(this.props.startDate).startOf("day");
                break;

            case "week":
                this.state.startDate = moment(this.props.startDate).weekday(0).startOf("day");
                this.state.endDate = moment(this.props.startDate).weekday(6).endOf("day");
                break;

            case "month":
                this.state.startDate = moment(this.props.startDate).startOf("month");
                this.state.endDate = moment(this.props.startDate).endOf("month");
                break;
        }
    }

    /**
     * React: render
     */
    render() {
        var dateLabel = "";
        switch (this.props.navigateBy) {
            case "day":
                dateLabel = this.state.startDate.format(this.props.dayFormat);
                break;

            case "week":
                dateLabel = this.props.weekFormat
                    .split("[to]")
                    .map((dateLabelPart, index) => {
                        return !index
                            ? this.state.startDate.format(dateLabelPart + this.props.weekFormatTo)
                            : this.state.endDate.format(dateLabelPart);
                    })
                    .join("");
                break;

            case "month":
                dateLabel = this.state.startDate.format(this.props.monthFormat);
                break;
        }

        return (
            <div className="ui-date-navigation">
                <Button size="icon-large" onClick={this._onPreviousClick}>
                    <i className="ui-icon icon-previous" />
                </Button>
                <Button size="icon-large" onClick={this._onCurrentClick}>
                    <i className="ui-icon icon-calendar" />
                </Button>
                <Button size="icon-large" onClick={this._onNextClick}>
                    <i className="ui-icon icon-next" />
                </Button>
                <span className="label">{dateLabel}</span>
            </div>
        );
    }

    /**
     * Change time period on previous click
     */
    _onPreviousClick = () => {
        var startDate = moment(this.state.startDate);
        var endDate = moment(this.state.endDate);
        switch (this.props.navigateBy) {
            case "day":
                startDate.subtract(1, "days");
                break;

            case "week":
                startDate.subtract(7, "days");
                endDate.subtract(7, "days");
                break;

            case "month":
                startDate.subtract(1, "months");
                endDate.subtract(1, "months");
                break;
        }

        this.setState(
            {
                startDate: startDate,
                endDate: endDate,
            },
            () => {
                if (typeof this.props.onPrevious === "function") {
                    this.props.onPrevious(this.state.startDate, this.state.endDate);
                }
            }
        );
    };

    /**
     * Change time period on next click
     */
    _onNextClick = () => {
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        switch (this.props.navigateBy) {
            case "day":
                startDate = this.state.startDate.add(1, "days");
                break;

            case "week":
                startDate = this.state.startDate.add(7, "days");
                endDate = this.state.endDate.add(7, "days");
                break;

            case "month":
                startDate = this.state.startDate.add(1, "months");
                endDate = this.state.endDate.add(1, "months");
                break;
        }

        this.setState(
            {
                startDate: startDate,
                endDate: endDate,
            },
            () => {
                if (typeof this.props.onNext === "function") {
                    this.props.onNext(this.state.startDate, this.state.endDate);
                }
            }
        );
    };

    /**
     * Change time period on current click
     */
    _onCurrentClick = () => {
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        switch (this.props.navigateBy) {
            case "day":
                startDate = moment();
                break;

            case "week":
                startDate = moment().weekday(0);
                endDate = moment().weekday(6);
                break;

            case "month":
                startDate = moment().startOf("month");
                endDate = moment(startDate).endOf("month");
                break;
        }

        this.setState(
            {
                startDate: startDate,
                endDate: endDate,
            },
            () => {
                if (typeof this.props.onNext === "function") {
                    this.props.onNext(this.state.startDate, this.state.endDate);
                }
            }
        );
    };
}
