"use strict";

import * as AppDispatcher from "../dispatchers/AppDispatcher";
import ActionTypes from "../constants/ActionTypes";

export default {
    storeScheduleDateSelection: (dates) => {
        AppDispatcher.dispatch(ActionTypes.STORE_SCHEDULE_DATE_SELECTION, { dates });
    },
};
