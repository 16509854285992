"use strict";

import * as AppDispatcher from "../dispatchers/AppDispatcher";
import ActionTypes from "../constants/ActionTypes";
import ApiService from "../server/ApiService";

export default {
    getDriverGroupCollection: (params = {}, queryParams = {}) => {
        let promise = ApiService.getDriverGroupCollection(params, queryParams);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_GET_DRIVER_GROUP_COLLECTION,
                success: ActionTypes.REQUEST_GET_DRIVER_GROUP_COLLECTION_SUCCESS,
                failure: ActionTypes.REQUEST_GET_DRIVER_GROUP_COLLECTION_ERROR,
            },
            { ...params, ...queryParams }
        );
    },

    createDriverGroup: (params = {}) => {
        let promise = ApiService.createDriverGroup(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_CREATE_DRIVER_GROUP,
                success: ActionTypes.REQUEST_CREATE_DRIVER_GROUP_SUCCESS,
                failure: ActionTypes.REQUEST_CREATE_DRIVER_GROUP_ERROR,
            },
            params
        );
    },

    getDriverGroup: (params = {}) => {
        let promise = ApiService.getDriverGroup(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_GET_DRIVER_GROUP,
                success: ActionTypes.REQUEST_GET_DRIVER_GROUP_SUCCESS,
                failure: ActionTypes.REQUEST_GET_DRIVER_GROUP_ERROR,
            },
            params
        );
    },

    updateDriverGroup: (params = {}) => {
        let promise = ApiService.updateDriverGroup(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_UPDATE_DRIVER_GROUP,
                success: ActionTypes.REQUEST_UPDATE_DRIVER_GROUP_SUCCESS,
                failure: ActionTypes.REQUEST_UPDATE_DRIVER_GROUP_ERROR,
            },
            params
        );
    },

    removeDriverGroup: (params = {}) => {
        let promise = ApiService.removeDriverGroup(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_REMOVE_DRIVER_GROUP,
                success: ActionTypes.REQUEST_REMOVE_DRIVER_GROUP_SUCCESS,
                failure: ActionTypes.REQUEST_REMOVE_DRIVER_GROUP_ERROR,
            },
            params
        );
    },
};
