"use strict";

/**
 * BaseForm class
 */
export default class BaseForm {
    /**
     * Data class
     * @type {*}
     */
    _dataClass = () => {};

    /**
     * Build form by adding fields
     * @param {FormBuilder} builder
     */
    buildForm(builder) {}

    /**
     * Set data class
     * @param {*} dataClass
     */
    setDataClass(dataClass) {
        this._dataClass = dataClass;
    }

    /**
     * Get form data class
     * @returns {*}
     */
    getDataClass = () => {
        return this._dataClass;
    };
}
