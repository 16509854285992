"use strict";

import BaseStore from "./BaseStore";

/**
 * EntityStore
 */
export default class EntityStore extends BaseStore {
    /**
     * Store events
     */
    ENTITY_COLLECTION_UPDATED = "entity_collection_updated";
    ENTITY_UPDATED = (recordId) => `entity_${recordId}_updated`;
}
