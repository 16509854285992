"use strict";

import * as Request from "./Request";
import ApiEndpoints from "../constants/ApiEndpoints";

export default {
    /**
     * Get calendarEvent collection
     * @param {Object} params
     * @param {Object} queryParams
     * @return {*}
     */
    getCalendarEventCollection: (params = {}, queryParams = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.SEARCH_CALENDAR_EVENTS,
            method: "GET",
            qs: queryParams,
        });
    },

    /**
     * Create calendarEvent
     * @param {Object} params
     * @param {CalendarEvent} params.calendarEvent
     * @return {*}
     */
    createCalendarEvent: (params = {}) => {
        var calendarEvent = params.calendarEvent;

        return Request.getAuthRequestPromise({
            url: ApiEndpoints.CREATE_CALENDAR_EVENT,
            method: "POST",
            body: {
                calendarEvent: {
                    eventName: calendarEvent.eventName,
                    eventDate: calendarEvent.eventDate.format("YYYY-MM-DD"),
                    canceledTours: calendarEvent.canceledTours.map((tour) => {
                        return tour.id;
                    }),
                },
            },
        });
    },

    /**
     * Get calendarEvent
     * @param {Object} params
     * @param {number} params.calendarEventId
     * @return {*}
     */
    getCalendarEvent: (params = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.GET_CALENDAR_EVENT(params.calendarEventId),
            method: "GET",
        });
    },

    /**
     * Update calendarEvent
     * @param {Object} params
     * @param {CalendarEvent} params.calendarEvent
     * @return {*}
     */
    updateCalendarEvent: (params = {}) => {
        var calendarEvent = params.calendarEvent;

        return Request.getAuthRequestPromise({
            url: ApiEndpoints.UPDATE_CALENDAR_EVENT(calendarEvent.id),
            method: "PUT",
            body: {
                calendarEvent: {
                    eventName: calendarEvent.eventName,
                    eventDate: calendarEvent.eventDate.format("YYYY-MM-DD"),
                    canceledTours: calendarEvent.canceledTours.map((tour) => {
                        return tour.id;
                    }),
                },
            },
        });
    },

    /**
     * Remove calendarEvent
     * @param {Object} params
     * @param {number} params.calendarEventId
     * @return {*}
     */
    removeCalendarEvent: (params = {}) => {
        return Request.getAuthRequestPromise({
            url: ApiEndpoints.REMOVE_CALENDAR_EVENT(params.calendarEventId),
            method: "DELETE",
        });
    },
};
