"use strict";

import * as AppDispatcher from "../dispatchers/AppDispatcher";
import ActionTypes from "../constants/ActionTypes";
import ApiService from "../server/ApiService";

export default {
    loginUser: (username, password) => {
        let promise = ApiService.loginUser(username, password);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_LOGIN_USER,
                success: ActionTypes.REQUEST_LOGIN_USER_SUCCESS,
                failure: ActionTypes.REQUEST_LOGIN_USER_ERROR,
            },
            { username, password }
        );
    },

    logoutUser: () => {
        AppDispatcher.dispatch(ActionTypes.LOGOUT_USER);
    },
};
