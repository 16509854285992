"use strict";

import React from "react";
import PropTypes from "prop-types";

/**
 * ActionBar component
 */
export default class ActionBar extends React.Component {
    /**
     * React: render
     */
    render() {
        return <div className="ui-action-bar">{this.props.children}</div>;
    }
}

/**
 * ActionBar.PrimaryActions component
 */
class Actions extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        type: PropTypes.oneOf(["primary", "secondary"]),
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        type: "primary",
    };

    /**
     * React: render
     */
    render() {
        var className = this.props.type + "-actions";

        return <div className={className}>{this.props.children}</div>;
    }
}

Object.assign(ActionBar, {
    Actions: Actions,
});
