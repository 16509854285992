"use strict";

import Immutable from "immutable";

/**
 * DriverGroup type
 *
 * @typedef {(Immutable.Record|Object)} DriverGroup
 * @property {number} id
 * @property {string} groupName
 * @property {Array.<Driver>} drivers
 * @property {Array.<Tour>} tours
 * @property {bool} isRemoved
 * @property {bool} updatePending
 */

/**
 * DriverGroup record
 *
 * @type {DriverGroup}
 */
var DriverGroup = Immutable.Record({
    id: null,
    groupName: "",
    drivers: [],
    tours: [],
    isRemoved: false,
    updatePending: false,
});

export default DriverGroup;
