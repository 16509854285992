"use strict";

import React from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import * as InternalPropTypes from "../../types/PropTypes";
import FieldRecord from "../FieldRecord";
import Form from "./Form.react";
import Field from "./Field.react";

/**
 * Collection component
 * iTODO Collection field: fix focus loosing on form collection & cover other use cases
 */
export default class Collection extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        entryType: PropTypes.oneOfType([PropTypes.string, InternalPropTypes.formType]).isRequired,
        entryOptions: PropTypes.object,
        value: PropTypes.array,
        valueReference: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        dataClass: PropTypes.func,
        allowAdd: PropTypes.bool,
        allowDelete: PropTypes.bool,
        deleteEmpty: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool,
        updatePending: PropTypes.bool,
        onChange: PropTypes.func,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        entryOptions: {},
        value: [],
        allowAdd: false,
        allowDelete: false,
        deleteEmpty: true,
        disabled: false,
        updatePending: false,
    };

    /**
     * React: state
     */
    state = {
        value: [],
        entryValues: {},
    };

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this.state.value = this.props.value;
        this.state.entryValues = this._parseEntryValues(this.props.value);
    }

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
            entryValues: this._parseEntryValues(nextProps.value),
        });
    }

    /**
     * Entry Forms refs
     * @type {array<Object>}
     * @private
     */
    _entryForms = {};

    /**
     * React: render
     */
    render() {
        var collectionRows = {};

        // collection of forms
        if (typeof this.props.entryType === "function") {
            // for each entry value create element
            Object.keys(this.state.entryValues).forEach((key) => {
                let entryValue = this.state.entryValues[key];
                collectionRows[key] = this._getForm(entryValue, key);
            });

            // collection of fields
        } else if (typeof this.props.entryType === "string") {
            // for each entry value create element
            Object.keys(this.state.entryValues).forEach((key) => {
                let entryValue = this.state.entryValues[key];
                collectionRows[key] = this._getField(entryValue, key);
            });
        }

        // map entry rows and controls
        var collectionContent = [];
        Object.keys(collectionRows).forEach((key, index) => {
            let collectionRow = collectionRows[key];
            collectionContent.push(
                <div className="entry-row" key={"row_" + key}>
                    {collectionRow}
                    <div className="ui-field actions">
                        {this.props.allowDelete ? (
                            <i className="ui-icon icon-minus" onClick={this._removeEntry.bind(null, key)} />
                        ) : null}
                        {this.props.allowAdd && Object.keys(collectionRows).length === index + 1 ? (
                            <i className="ui-icon icon-plus" onClick={this._addEntry} />
                        ) : null}
                    </div>
                </div>
            );
        });

        return <div className="ui-collection">{collectionContent}</div>;
    }

    /**
     * Parse entry values from element value
     * @param value
     * @private
     */
    _parseEntryValues = (value) => {
        var entryValues = {};
        var valueReference = this.props.valueReference;

        value.forEach((entryData) => {
            let key = uuid();
            let entryValue = null;

            if (typeof valueReference === "function") {
                entryValue = valueReference(entryData);
            } else if (typeof valueReference === "string") {
                entryValue = entryData[valueReference];
            } else {
                entryValue = entryData;
            }

            entryValues[key] = entryValue;
        });

        // if no value, create at least one entry
        if (value.length === 0) {
            let key = uuid();
            entryValues[key] = null;
        }

        return entryValues;
    };

    /**
     * Get form component
     * @param {Object} entryValue
     * @param {string} key
     * @private
     */
    _getForm = (entryValue, key) => {
        var formType = new this.props.entryType();

        // initialize data if empty
        if (!entryValue) {
            entryValue = new formType.getDataClass()();
        }

        return (
            <Form
                {...this.props.entryOptions}
                type={formType}
                data={entryValue}
                formRef={(form) => {
                    this._entryForms[key] = form;
                }}
                inCollection={true}
                onChange={this._onEntryChange.bind(null, key)}
                disabled={this.props.disabled}
                inline={true}
                showLabels={false}
            />
        );
    };

    /**
     * Get field component
     * @param {Object} entryValue
     * @param {string} key
     * @private
     */
    _getField = (entryValue, key) => {
        var entryOptions = this.props.entryOptions;
        var field = new FieldRecord({
            name: key,
            type: this.props.entryType || "text",
            options: {
                ...entryOptions,
                value: entryValue,
            },
        });

        return (
            <Field
                type={field.type}
                field={field}
                disabled={this.props.disabled}
                onChange={this._onEntryChange.bind(null, key)}
                showLabel={false}
                widgetWidth={this.props.width}
            />
        );
    };

    /**
     * Map value array from entryValues
     * @param {Object} entryValues
     * @private
     */
    _mapValue = (entryValues) => {
        var collectionValue = Object.assign({}, entryValues);
        return Object.keys(collectionValue).map((valueKey) => {
            return collectionValue[valueKey];
        });
    };

    /**
     * On entry field change
     * @param {string} key
     * @param {FieldRecord} field
     * @param {(number|string|bool|Object)} value
     * @private
     */
    _onEntryChange = (key, field, value) => {
        var entryValues = Object.assign({}, this.state.entryValues);

        // form
        if (typeof this.props.entryType === "function") {
            entryValues[key] = this._entryForms[key].getData();

            // field
        } else if (typeof this.props.entryType === "string") {
            entryValues[key] = value;
        }

        this.setState(
            {
                value: this._mapValue(entryValues),
                entryValues: entryValues,
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            }
        );
    };

    /**
     * Add entry
     * @private
     */
    _addEntry = () => {
        var entryValues = Object.assign({}, this.state.entryValues);
        var key = uuid();

        // collection of forms
        if (typeof this.props.entryType === "function") {
            var formType = new this.props.entryType();
            entryValues[key] = new formType.getDataClass()();

            // collection of fields
        } else if (typeof this.props.entryType === "string") {
            entryValues[key] = null;
        }

        this.setState(
            {
                value: this._mapValue(entryValues),
                entryValues: entryValues,
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            }
        );
    };

    /**
     * Remove entry
     * @param {string} key
     * @private
     */
    _removeEntry = (key) => {
        var entryValues = Object.assign({}, this.state.entryValues);
        delete entryValues[key];

        if (typeof this.props.entryType === "function") {
            delete this._entryForms[key];
        }

        // if no more entryValues, create new one
        if (Object.keys(entryValues).length === 0) {
            let newKey = uuid();
            entryValues[newKey] = null;
        }

        this.setState(
            {
                value: this._mapValue(entryValues),
                entryValues: entryValues,
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            }
        );
    };
}
