"use strict";

import moment from "moment";
import Immutable from "immutable";
import CalendarEvent from "../types/CalendarEvent";
import ActionTypes from "../constants/ActionTypes";
import EntityStore from "./EntityStore";

/**
 * Flux: CalendarEventStore
 */
class CalendarEventStore extends EntityStore {
    /**
     * calendarEventCollection hash
     *
     * @type {Immutable.OrderedMap<string, CalendarEvent>}
     * @private
     */
    _calendarEventCollection = Immutable.OrderedMap();

    /**
     * calendarEventsByTour hash
     *
     * @type {Immutable.Map<string, CalendarEvent>}
     * @private
     */
    _calendarEventsByTour = Immutable.Map();

    /**
     * calendarEventsByDate hash
     *
     * @type {Immutable.Map<string, CalendarEvent>}
     * @private
     */
    _calendarEventsByDate = Immutable.Map();

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));

        this._error = null;
    }

    /**
     * Flux: register store to actions
     *
     * @param {*} action
     * @private
     */
    _registerToActions(action) {
        switch (action.type) {
            // get calendarEventCollection
            case ActionTypes.REQUEST_GET_CALENDAR_EVENT_COLLECTION:
                this._error = null;
                break;

            case ActionTypes.REQUEST_GET_CALENDAR_EVENT_COLLECTION_SUCCESS:
                this._setCalendarEventCollection(action.body.calendarEvents);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_GET_CALENDAR_EVENT_COLLECTION_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            // create calendarEvent
            case ActionTypes.REQUEST_CREATE_CALENDAR_EVENT:
                this._error = null;
                break;

            case ActionTypes.REQUEST_CREATE_CALENDAR_EVENT_SUCCESS:
                this._setCalendarEvent(action.body.calendarEvent);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_CREATE_CALENDAR_EVENT_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            // get calendarEvent
            case ActionTypes.REQUEST_GET_CALENDAR_EVENT:
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.calendarEventId));
                break;

            case ActionTypes.REQUEST_GET_CALENDAR_EVENT_SUCCESS:
                this._setCalendarEvent(action.body.calendarEvent);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.body.calendarEvent.id));
                break;

            case ActionTypes.REQUEST_GET_CALENDAR_EVENT_ERROR:
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.calendarEventId));
                break;

            // update calendarEvent
            case ActionTypes.REQUEST_UPDATE_CALENDAR_EVENT:
                this._toggleUpdatePending(action.calendarEvent.id);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.calendarEvent.id));
                break;

            case ActionTypes.REQUEST_UPDATE_CALENDAR_EVENT_SUCCESS:
                this._setCalendarEvent(action.body.calendarEvent);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.body.calendarEvent.id));
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_UPDATE_CALENDAR_EVENT_ERROR:
                this._toggleUpdatePending(action.calendarEvent.id);
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.calendarEvent.id));
                break;

            // remove calendarEvent
            case ActionTypes.REQUEST_REMOVE_CALENDAR_EVENT:
                this._toggleUpdatePending(action.calendarEventId);
                this._error = null;
                this.emit(this.ENTITY_UPDATED(action.calendarEventId));
                break;

            case ActionTypes.REQUEST_REMOVE_CALENDAR_EVENT_SUCCESS:
                this._toggleUpdatePending(action.calendarEventId);
                this._removeCalendarEvent(action.calendarEventId);
                this._error = null;
                this.emit(this.ENTITY_COLLECTION_UPDATED);
                break;

            case ActionTypes.REQUEST_REMOVE_CALENDAR_EVENT_ERROR:
                this._toggleUpdatePending(action.calendarEventId);
                this._error = action.error;
                this.emit(this.ENTITY_UPDATED(action.calendarEventId));
                break;

            default:
                break;
        }
    }

    /**
     * Set _calendarEventCollection
     *
     * @param {Array.<CalendarEvent>} responseCalendarEvents
     * @private
     */
    _setCalendarEventCollection(responseCalendarEvents) {
        responseCalendarEvents.map((responseCalendarEvent) => {
            this._setCalendarEvent(responseCalendarEvent, false);
        });

        this._rebuildCalendarEventCollectionHash();
    }

    /**
     * Set _calendarEventCollection[key]
     *
     * @params {CalendarEvent} responseCalendarEvent
     * @params {bool} rebuildHash
     * @private
     */
    _setCalendarEvent(responseCalendarEvent, rebuildHash = true) {
        var oldCalendarEvent = this._calendarEventCollection.get(responseCalendarEvent.id);
        var calendarEvent = new CalendarEvent({
            ...responseCalendarEvent,
            eventDate: moment(responseCalendarEvent.eventDate),
        });

        this._calendarEventCollection = this._calendarEventCollection.set(responseCalendarEvent.id, calendarEvent);
        if (rebuildHash) {
            this._rebuildCalendarEventHash(calendarEvent, oldCalendarEvent);
        }
    }

    /**
     * Remove _calendarEventCollection[key]
     *
     * @params {number} calendarEventId
     * @private
     */
    _removeCalendarEvent(calendarEventId) {
        // clean up hashes
        var calendarEvent = this._calendarEventCollection.get(calendarEventId);
        var dateHash = calendarEvent.eventDate.format("YYYY-MM-DD");
        this._calendarEventsByDate = this._calendarEventsByDate.remove(dateHash);
        calendarEvent.canceledTours.forEach((tour) => {
            this._calendarEventsByTour = this._calendarEventsByTour.remove(tour.id + "-" + dateHash);
        });

        this._calendarEventCollection = this._calendarEventCollection.remove(calendarEventId);
    }

    /**
     * Rebuilds calendarEvent collection hashes
     *
     * @private
     */
    _rebuildCalendarEventCollectionHash() {
        this._calendarEventsByDate = Immutable.Map();
        this._calendarEventsByTour = Immutable.Map();

        this._calendarEventCollection.forEach((calendarEvent) => {
            let dateHash = calendarEvent.eventDate.format("YYYY-MM-DD");
            this._calendarEventsByDate = this._calendarEventsByDate.set(dateHash, calendarEvent);
            calendarEvent.canceledTours.forEach((tour) => {
                this._calendarEventsByTour = this._calendarEventsByTour.set(tour.id + "-" + dateHash, calendarEvent);
            });
        });
    }

    /**
     * Rebuilds calendarEvent hash
     *
     * @param {CalendarEvent} calendarEvent
     * @param {CalendarEvent} oldCalendarEvent
     * @private
     */
    _rebuildCalendarEventHash(calendarEvent, oldCalendarEvent) {
        // clean up old hashes
        if (oldCalendarEvent) {
            var dateHash = oldCalendarEvent.eventDate.format("YYYY-MM-DD");
            oldCalendarEvent.canceledTours.forEach((tour) => {
                this._calendarEventsByTour = this._calendarEventsByTour.remove(tour.id + "-" + dateHash);
            });
        }

        // set new hashes
        dateHash = calendarEvent.eventDate.format("YYYY-MM-DD");
        this._calendarEventsByDate = this._calendarEventsByDate.set(dateHash, calendarEvent);
        calendarEvent.canceledTours.forEach((tour) => {
            this._calendarEventsByTour = this._calendarEventsByTour.set(tour.id + "-" + dateHash, calendarEvent);
        });
    }

    /**
     * Set updatePending flag on calendarEvent
     *
     * @param {number} calendarEventId
     * @private
     */
    _toggleUpdatePending(calendarEventId) {
        var calendarEvent = this._calendarEventCollection.get(calendarEventId);
        calendarEvent = calendarEvent.set("updatePending", !calendarEvent.get("updatePending"));
        this._calendarEventCollection = this._calendarEventCollection.set(calendarEventId, calendarEvent);
    }

    /**
     * Get calendarEvents for period
     *
     * @param {Object} startDate
     * @param {Object} endDate
     * @param {string} hashName
     * @return {Immutable.Map<string, DriverAbsence>}
     */
    getCalendarEventsForPeriod(startDate, endDate, hashName) {
        // filter calendarEvents for period
        return this[hashName].filter((calendarEvent) => {
            return (
                (calendarEvent.eventDate.isAfter(startDate, "day") ||
                    calendarEvent.eventDate.isSame(startDate, "day")) &&
                (calendarEvent.eventDate.isBefore(endDate, "day") || calendarEvent.eventDate.isSame(endDate, "day"))
            );
        });
    }

    /**
     * Get _calendarEventCollection
     *
     * @returns {Immutable.OrderedMap<string, CalendarEvent>}
     */
    get calendarEventCollection() {
        return this._calendarEventCollection;
    }

    /**
     * Get _calendarEventsByTour
     *
     * @returns {Immutable.Map<string, CalendarEvent>}
     */
    get calendarEventsByTour() {
        return this._calendarEventsByTour;
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new CalendarEventStore();
