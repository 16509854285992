"use strict";

import Immutable from "immutable";
import FieldRecord from "./FieldRecord";

/**
 * FormBuilder class
 * iTODO Add field type guessing to FormBuilder
 */
export default class FormBuilder {
    /**
     * Form data object
     * @type {Object}
     * @private
     */
    _data = {};

    /**
     * Form error object
     * @type {Object}
     * @private
     */
    _error = null;

    /**
     * Initial form data
     * @type {Object}
     * @private
     */
    _initialData = {};

    /**
     * Form fields
     * @type {Immutable.OrderedMap}
     * @private
     */
    _fields = Immutable.OrderedMap();

    /**
     * Constructor
     * @param {Object} formType
     * @param {Object|Immutable.Record} data
     * @param {?Object} error
     */
    constructor(formType, data = {}, error = null) {
        this._initialData = data instanceof Immutable.Record ? data : Object.assign({}, data);
        this._data = data;
        this._error = error;
        formType.buildForm(this);
    }

    /**
     * Add form field
     * @param name
     * @param type
     * @param options
     */
    add(name, type, options = {}) {
        options = {
            ...options,
            label: typeof options.label !== "undefined" ? options.label : this._parseNameToLabel(name),
            value: typeof this._data[name] !== "undefined" ? this._data[name] : options.value,
        };

        this._fields = this._fields.set(
            name,
            new FieldRecord({
                name: name,
                type: type,
                options: options,
                errors:
                    this._error &&
                    typeof this._error.errors !== "undefined" &&
                    typeof this._error.errors.children[name] !== "undefined"
                        ? this._error.errors.children[name].errors
                        : null,
            })
        );
    }

    /**
     * Parse field name to label
     */
    _parseNameToLabel = (name) => {
        return (
            name
                // insert a space between lower & upper
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                // insert a space between letter & number
                .replace(/([A-z])([0-9])/g, "$1 $2")
                // space before last upper in a sequence followed by lower
                .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
                // uppercase the first character
                .replace(/^./, (str) => {
                    return str.toUpperCase();
                })
        );
    };

    /**
     * Set field value
     * @param {FieldRecord} field
     * @param {(number|string|bool|Object)} value
     * @private
     */
    setFieldValue = (field, value) => {
        this._fields = this._fields.set(
            field.name,
            new FieldRecord({
                name: field.name,
                type: field.type,
                options: Object.assign(field.options, {
                    value: value,
                }),
                errors: null, // reset error on value change
            })
        );

        return this._fields;
    };

    /**
     * Reset form
     */
    resetForm = () => {
        this._fields.forEach((field) => {
            this.setFieldValue(field, this._initialData[field.name]);
        });

        return this._fields;
    };

    /**
     * Get form data
     */
    getData = () => {
        // data is immutable, use sets on _data
        if (this._data instanceof Immutable.Record) {
            this._fields.forEach((field) => {
                if (typeof this._data[field.name] !== "undefined") {
                    this._data = this._data.set(field.name, field.options.value);
                }
            });
            return this._data;

            // data is mutable, use cloned object
        } else {
            let data = Object.assign({}, this._data);
            this._fields.forEach((field) => {
                data[field.name] = field.options.value;
            });
            return data;
        }
    };

    /**
     * Return _fields
     */
    get fields() {
        return this._fields;
    }
}
