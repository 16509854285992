"use strict";

import React from "react";
import moment from "moment";
import { extendMoment } from "moment-range";
import { debounce } from "../lib/Utils";
import AppActions from "../actions/AppActions";
import TourActions from "../actions/TourActions";
import TourDateActions from "../actions/TourDateActions";
import CalendarEventActions from "../actions/CalendarEventActions";
import AppStore from "../stores/AppStore";
import TourStore from "../stores/TourStore";
import TourDateStore from "../stores/TourDateStore";
import CalendarEventStore from "../stores/CalendarEventStore";
import TourDateStatus from "../constants/TourDateStatus";
import ScheduleForm from "../forms/ScheduleForm";
import ActionBar from "../ui/elements/ActionBar.react";
import DateNavigation from "../ui/elements/DateNavigation.react";
import Button from "../ui/elements/Button.react";
import Modal from "../ui/elements/Modal.react";
import Form from "../ui/elements/Form.react";
import LoadingAnimation from "../ui/elements/LoadingAnimation.react";
import FlashBar from "../ui/elements/FlashBar.react";
import Text from "../ui/elements/Text.react";
import Checkbox from "../ui/elements/Checkbox.react";
import ScheduleTable from "./ScheduleTable.react";
import TourDateTourCell from "./TourDateTourCell.react";
import TourDateModal from "./TourDateModal.react";
import DriverGroupOverviewModal from "./DriverGroupOverviewModal.react";

/**
 * Schedule component
 */
export default class Schedule extends React.Component {
    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this._setFilterQueryString = debounce(this._setFilterQueryString, 350);
    }

    /**
     * React: state
     */
    state = {
        startDate: AppStore.scheduleDates.startDate,
        endDate: AppStore.scheduleDates.endDate,
        tours: null,
        tourDatesForPeriod: null,
        calendarEventsForPeriod: null,
        isScheduleRemoveButtonDisabled: true,
        isScheduleRemoveModalVisible: false,
        isScheduleCreateModalVisible: false,
        filterOpenTourDates: false,
        filterQueryString: "",
        tourDateToUpdate: null,
        isTourDateModalVisible: false,
        driverGroupToView: null,
        isDriverGroupOverviewModalVisible: false,
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        // add store listeners
        TourStore.addListener(TourStore.ENTITY_COLLECTION_UPDATED, this._setTours);
        TourDateStore.addListener(TourDateStore.ENTITY_COLLECTION_UPDATED, this._setSchedule);
        CalendarEventStore.addListener(CalendarEventStore.ENTITY_COLLECTION_UPDATED, this._setCalendarEvents);

        // get tours and tourDates for period
        this._getTours();
        this._getSchedule();
        this._getCalendarEvents();
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        // remove store listeners
        TourStore.removeListener(TourStore.ENTITY_COLLECTION_UPDATED, this._setTours);
        TourDateStore.removeListener(TourDateStore.ENTITY_COLLECTION_UPDATED, this._setSchedule);
        CalendarEventStore.removeListener(CalendarEventStore.ENTITY_COLLECTION_UPDATED, this._setCalendarEvents);
    }

    /**
     * Form ref
     * @type {Object|null}
     * @private
     */
    _form = null;

    /**
     * React: render
     */
    render() {
        var tabularContent = null;
        var displayScheduleFilter = false;
        var week = extendMoment(moment).range(this.state.startDate, this.state.endDate);
        var openTourDates = [];

        // schedule loading
        if (
            this.state.tours === null ||
            this.state.tourDatesForPeriod === null ||
            this.state.calendarEventsForPeriod === null
        ) {
            tabularContent = (
                <div className="content-loading">
                    <LoadingAnimation />
                </div>
            );

            // schedule loaded without tourDates
        } else if (this.state.tourDatesForPeriod.size === 0) {
            tabularContent = (
                <div className="content-centered">
                    <p>Momentan sind für diese Woche keine Touren geplant worden</p>
                    <Button size="large" onClick={this._createSchedule}>
                        Planung starten
                    </Button>
                </div>
            );

            // schedule loaded
        } else {
            displayScheduleFilter = true;

            var tableRows = [];
            var visibleTableRows = 0;

            this.state.tours.forEach((tour) => {
                let tourHasOpenTourDates = false;
                let tourQuerySearchIndex = tour.tourName;

                let rowCells = [];
                for (const weekDay of week.by("days")) {
                    let key = tour.id + "-" + weekDay.format("YYYY-MM-DD");
                    let tourDate = this.state.tourDatesForPeriod.get(key);

                    // add tourDate to list of open tourDates
                    if (tourDate != null && tourDate.status === TourDateStatus.OPEN) {
                        openTourDates.push(tourDate);
                        tourHasOpenTourDates = true;
                    }

                    if (tourDate != null && tourDate.driver != null) {
                        tourQuerySearchIndex += "-" + tourDate.driver.nickname;
                    }

                    rowCells.push(
                        <TourDateTourCell
                            key={key}
                            tour={tour}
                            tourDate={tourDate}
                            weekDay={weekDay}
                            calendarEventsForPeriod={this.state.calendarEventsForPeriod}
                            toggleTourDateModal={this._toggleTourDateModal}
                        />
                    );
                }

                // display row if open tourDate filter IS NOT on
                // display tour row if filterQueryString IS NOT set or active
                let filterInactive = !this.state.filterOpenTourDates && this.state.filterQueryString === "";

                // display row if open tourDate filter IS on and open tourDates EXIST
                let filterOpenTourDatesMatched =
                    !filterInactive && this.state.filterOpenTourDates && tourHasOpenTourDates;

                // display tour row if filterQueryString IS set and active and tourDate data matches it
                let filterQueryStringMatched =
                    !filterInactive &&
                    !this.state.filterOpenTourDates &&
                    this.state.filterQueryString !== "" &&
                    tourQuerySearchIndex.toLowerCase().indexOf(this.state.filterQueryString.toLowerCase()) !== -1;

                if (filterInactive || filterOpenTourDatesMatched || filterQueryStringMatched) {
                    visibleTableRows++;
                }

                tableRows.push(
                    <tr
                        key={tour.id}
                        style={
                            filterInactive || filterOpenTourDatesMatched || filterQueryStringMatched
                                ? null
                                : { display: "none" }
                        }
                    >
                        <td className="driver-name">
                            <span>{tour.tourName}</span>
                            <br />
                            {!tour.driverGroup ? null : (
                                <span
                                    onClick={this._toggleDriverGroupOverviewModal.bind(this, tour.driverGroup)}
                                    className="group"
                                >
                                    {tour.driverGroup.groupName}&nbsp;{tour.driverGroup.isRemoved ? "(gelöscht)" : null}
                                </span>
                            )}
                        </td>
                        <td>
                            <strong>{tour.hasShifts ? "am/pm" : null}</strong>
                        </td>
                        <td />
                        {rowCells}
                    </tr>
                );
            });

            if (visibleTableRows === 0) {
                tableRows.push(
                    <tr key="empty-list">
                        <td className="empty-list" colSpan="10">
                            Liste ist leer.
                        </td>
                    </tr>
                );
            }

            tabularContent = (
                <ScheduleTable startDate={this.state.startDate} endDate={this.state.endDate}>
                    {tableRows}
                </ScheduleTable>
            );
        }

        var schedule = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        };

        return (
            <div className="schedule-page">
                <ActionBar>
                    <ActionBar.Actions type="secondary">
                        <DateNavigation
                            navigateBy="week"
                            startDate={this.state.startDate}
                            weekFormatTo="[bis]"
                            onPrevious={this._onDateChange}
                            onNext={this._onDateChange}
                        />
                    </ActionBar.Actions>
                    <ActionBar.Actions type="primary">
                        {/*<Button onClick={this._toggleScheduleCreateModal} size="large"
                                disabled={this.state.tours === null || this.state.tourDatesForPeriod === null}>Zeitplan erstellen</Button>*/}
                        <Button
                            onClick={this._toggleScheduleRemoveModal}
                            size="large"
                            disabled={this.state.isScheduleRemoveButtonDisabled}
                        >
                            Planung zurücksetzen
                        </Button>
                    </ActionBar.Actions>
                </ActionBar>

                <FlashBar isVisible={openTourDates.length > 0} type="warning">
                    <span>
                        <em>{openTourDates.length} Tourentage</em> sind in dieser Woche nicht besetzt.
                    </span>
                </FlashBar>

                {!displayScheduleFilter ? null : (
                    <ActionBar>
                        <ActionBar.Actions type="secondary">
                            <div className="query-string-filter">
                                <label>Filter</label>
                                <Text
                                    placeholder="Touren oder Fahrer.."
                                    value={this.state.filterOpenTourDates ? "" : this.state.filterQueryString}
                                    disabled={this.state.filterOpenTourDates}
                                    onChange={this._setFilterQueryString}
                                    width="3"
                                />
                            </div>
                            <Checkbox
                                label="Nur offene Touren"
                                value={this.state.filterOpenTourDates}
                                onChange={this._toggleFilterOpenTourDates}
                            />
                        </ActionBar.Actions>
                    </ActionBar>
                )}

                {tabularContent}

                {this.props.children}

                <Modal isVisible={this.state.isScheduleRemoveModalVisible} title={"Planung zurücksetzen"}>
                    <Modal.Content width="8">
                        <p>
                            Möchten Sie die Planung für die Woche{" "}
                            <strong>
                                {" "}
                                {this.state.startDate.format("D.")}&nbsp; bis{" "}
                                {this.state.endDate.format("D. MMMM, [KW] W")}
                            </strong>{" "}
                            wirklich löschen
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Modal.ActionButton isSecondary={true} onClick={this._toggleScheduleRemoveModal}>
                            Abbrechen
                        </Modal.ActionButton>
                        <Modal.ActionButton actionType="negative" onClick={this._removeSchedule}>
                            Zurücksetzen
                        </Modal.ActionButton>
                    </Modal.Actions>
                </Modal>

                <Modal isVisible={this.state.isScheduleCreateModalVisible} title={"Zeitplan erstellen"}>
                    <Modal.Content width="4" height="5">
                        <Form
                            type={new ScheduleForm()}
                            data={schedule}
                            formRef={(form) => {
                                this._form = form;
                            }}
                            action={this._populateSchedule}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Modal.ActionButton isSecondary={true} onClick={this._toggleScheduleCreateModal}>
                            Abbrechen
                        </Modal.ActionButton>
                        <Modal.ActionButton
                            actionType="positive"
                            onClick={() => {
                                this._form.submit();
                            }}
                        >
                            Erstellen
                        </Modal.ActionButton>
                    </Modal.Actions>
                </Modal>

                <TourDateModal
                    isVisible={this.state.isTourDateModalVisible}
                    tourDate={this.state.tourDateToUpdate}
                    toggleTourDateModal={this._toggleTourDateModal}
                />

                <DriverGroupOverviewModal
                    isVisible={this.state.isDriverGroupOverviewModalVisible}
                    driverGroup={this.state.driverGroupToView}
                    toggleDriverGroupOverviewModal={this._toggleDriverGroupOverviewModal}
                />
            </div>
        );
    }

    /**
     * Get tours
     */
    _getTours = () => {
        TourActions.getTourCollection();
    };

    /**
     * Set tours
     */
    _setTours = () => {
        this.setState({
            tours: TourStore.tourCollection,
        });
    };

    /**
     * Get schedule (tourDates) for time period
     */
    _getSchedule = () => {
        TourDateActions.getSchedule({
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD"),
        });
    };

    /**
     * Load tourDates from store for time period (schedule)
     */
    _setSchedule = () => {
        var tourDatesForPeriod = TourDateStore.getTourDatesForPeriod(
            this.state.startDate,
            this.state.endDate,
            "_tourDatesByTour"
        );

        this.setState({
            tourDatesForPeriod: tourDatesForPeriod,
            isScheduleRemoveButtonDisabled: tourDatesForPeriod.size === 0,
        });
    };

    /**
     * Get calendar (calendarEvents) for time period
     */
    _getCalendarEvents = () => {
        CalendarEventActions.getCalendarEventCollection(
            {},
            {
                startDate: this.state.startDate.format("YYYY-MM-DD"),
                endDate: this.state.endDate.format("YYYY-MM-DD"),
            }
        );
    };

    /**
     * Load calendarEvents from store for time period (calendar)
     */
    _setCalendarEvents = () => {
        var calendarEventsForPeriod = CalendarEventStore.getCalendarEventsForPeriod(
            this.state.startDate,
            this.state.endDate,
            "_calendarEventsByDate"
        );

        this.setState({
            calendarEventsForPeriod: calendarEventsForPeriod,
        });
    };

    /**
     * Change time period
     *
     * @param {Object} startDate
     * @param {Object} endDate
     */
    _onDateChange = (startDate, endDate) => {
        AppActions.storeScheduleDateSelection({
            startDate: startDate,
            endDate: endDate,
        });

        var SchedulePage = this;
        this.setState(
            {
                startDate: startDate,
                endDate: endDate,
                tours: null,
                tourDatesForPeriod: null,
                calendarEventsForPeriod: null,
            },
            () => {
                SchedulePage._getTours();
                SchedulePage._getSchedule();
                SchedulePage._getCalendarEvents();
            }
        );
    };

    /**
     * Create schedule for time period
     */
    _createSchedule = () => {
        this.setState({
            tourDatesForPeriod: null,
        });

        TourDateActions.createSchedule({
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD"),
            useDriverGroup: false,
            useRotation: false,
            useReservations: true,
        });
    };

    /**
     * Toggle schedule remove modal
     */
    _toggleScheduleRemoveModal = () => {
        this.setState({
            isScheduleRemoveModalVisible: !this.state.isScheduleRemoveModalVisible,
        });
    };

    /**
     * Remove schedule for time period
     */
    _removeSchedule = () => {
        this.setState({
            tourDatesForPeriod: null,
            isScheduleRemoveModalVisible: false,
            isScheduleRemoveButtonDisabled: true,
        });

        TourDateActions.removeSchedule({
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD"),
        });
    };

    /**
     * Toggle schedule create modal
     */
    _toggleScheduleCreateModal = () => {
        this.setState({
            isScheduleCreateModalVisible: !this.state.isScheduleCreateModalVisible,
        });
    };

    /**
     * Populate schedule for time period
     */
    _populateSchedule = (schedule) => {
        this._toggleScheduleCreateModal();
        this.setState({
            tourDatesForPeriod: null,
        });

        TourDateActions.createSchedule(schedule);
    };

    /**
     * Set filter query
     * @param {string} queryString
     */
    _setFilterQueryString = (queryString) => {
        this.setState({
            filterQueryString: queryString,
        });
    };

    /**
     * Toggle filter for open tourDates
     */
    _toggleFilterOpenTourDates = (checked) => {
        this.setState({
            filterOpenTourDates: checked,
        });
    };

    /**
     * Toggle driverGroup overview modal
     *
     * @params {DriverGroup} driverGroup
     */
    _toggleDriverGroupOverviewModal = (driverGroup) => {
        this.setState({
            isDriverGroupOverviewModalVisible: !this.state.isDriverGroupOverviewModalVisible,
            driverGroupToView: driverGroup,
        });
    };

    /**
     * Toggle tourDate update modal
     *
     * @params {TourDate} tourDate
     */
    _toggleTourDateModal = (tourDate) => {
        this.setState({
            isTourDateModalVisible: !this.state.isTourDateModalVisible,
            tourDateToUpdate: tourDate,
        });
    };
}
