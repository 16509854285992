"use strict";

import * as AppDispatcher from "../dispatchers/AppDispatcher";
import ActionTypes from "../constants/ActionTypes";
import ApiService from "../server/ApiService";

export default {
    getTourCollection: (params = {}, queryParams = {}) => {
        let promise = ApiService.getTourCollection(params, queryParams);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_GET_TOUR_COLLECTION,
                success: ActionTypes.REQUEST_GET_TOUR_COLLECTION_SUCCESS,
                failure: ActionTypes.REQUEST_GET_TOUR_COLLECTION_ERROR,
            },
            { ...params, ...queryParams }
        );
    },

    createTour: (params = {}) => {
        let promise = ApiService.createTour(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_CREATE_TOUR,
                success: ActionTypes.REQUEST_CREATE_TOUR_SUCCESS,
                failure: ActionTypes.REQUEST_CREATE_TOUR_ERROR,
            },
            params
        );
    },

    getTour: (params = {}) => {
        let promise = ApiService.getTour(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_GET_TOUR,
                success: ActionTypes.REQUEST_GET_TOUR_SUCCESS,
                failure: ActionTypes.REQUEST_GET_TOUR_ERROR,
            },
            params
        );
    },

    updateTour: (params = {}) => {
        let promise = ApiService.updateTour(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_UPDATE_TOUR,
                success: ActionTypes.REQUEST_UPDATE_TOUR_SUCCESS,
                failure: ActionTypes.REQUEST_UPDATE_TOUR_ERROR,
            },
            params
        );
    },

    removeTour: (params = {}) => {
        let promise = ApiService.removeTour(params);
        AppDispatcher.dispatchAsync(
            promise,
            {
                request: ActionTypes.REQUEST_REMOVE_TOUR,
                success: ActionTypes.REQUEST_REMOVE_TOUR_SUCCESS,
                failure: ActionTypes.REQUEST_REMOVE_TOUR_ERROR,
            },
            params
        );
    },
};
