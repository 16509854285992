"use strict";

import * as Request from "./Request";
import ApiEndpoints from "../constants/ApiEndpoints";

export default {
    /**
     * Login user
     *
     * @return {*}
     */
    loginUser: (username, password) => {
        return Request.getRequestPromise({
            url: ApiEndpoints.LOGIN_USER,
            method: "POST",
            body: { username, password },
        });
    },
};
