"use strict";

import React from "react";
import PropTypes from "prop-types";

/**
 * Text component
 */
export default class Text extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
        type: PropTypes.oneOf(["text", "number", "password", "hidden"]),
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        autoFocus: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onChange: PropTypes.func,
        onInput: PropTypes.func,
        onKeyPress: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyUp: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        type: "text",
        value: "",
        isPassword: false,
        disabled: false,
        errors: null,
    };

    /**
     * React: state
     */
    state = {
        value: this.props.value,
    };

    /**
     * React: componentWillReceiveProps
     */
    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
        });
    }

    /**
     * React: render
     */
    render() {
        var className = "ui-text" + (this.props.width ? " ui-col-" + this.props.width : "");

        return (
            <input
                className={className}
                value={this.state.value || ""}
                type={this.props.type}
                autoFocus={this.props.autoFocus}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                onChange={this._onChange}
                onInput={this._onInput}
                onKeyPress={this._onKeyPress}
                onKeyDown={this._onKeyDown}
                onKeyUp={this._onKeyUp}
                onFocus={this._onFocus}
                onBlur={this._onBlur}
            />
        );
    }

    /**
     * On change
     */
    _onChange = (event) => {
        this.setState(
            {
                value: event.target.value,
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value, event);
                }
            }
        );
    };

    /**
     * On input
     */
    _onInput = (event) => {
        if (typeof this.props.onInput === "function") {
            this.props.onInput(event.target.value, event);
        }
    };

    /**
     * On key press
     */
    _onKeyPress = (event) => {
        if (typeof this.props.onKeyPress === "function") {
            this.props.onKeyPress(event.target.value, event);
        }
    };

    /**
     * On key down
     */
    _onKeyDown = (event) => {
        if (typeof this.props.onKeyDown === "function") {
            this.props.onKeyDown(event.target.value, event);
        }
    };

    /**
     * On key up
     */
    _onKeyUp = (event) => {
        if (typeof this.props.onKeyUp === "function") {
            this.props.onKeyUp(event.target.value, event);
        }
    };

    /**
     * On focus
     */
    _onFocus = (event) => {
        if (typeof this.props.onFocus === "function") {
            this.props.onFocus(event.target.value, event);
        }
    };

    /**
     * On blur
     */
    _onBlur = (event) => {
        if (typeof this.props.onBlur === "function") {
            this.props.onBlur(event.target.value, event);
        }
    };
}
